import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import { saveTrustedContact, useAppDispatch } from '../../../state';
import { Button } from 'primereact/button';
import { OCard, TextInputWithLabel } from '../../molecules';
import { IdentificationCard } from '../../atoms';
import { InfoCard } from '../../molecules/InfoCard';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';
import { TrustedContact } from '../../../state/slices/model/TrustedContact';

export const TrustedContactCreate: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    const [isLoading, setIsLoading] = useState<boolean>();

    const [trustedContact, setTrustedContact] = useState<TrustedContact>({did: '', name: ''});
    let navigate = useNavigate();

    function handleFormUpdate(attributeName: string, value: string | undefined) {
        setTrustedContact(Object.assign({}, trustedContact, {[attributeName]: value}));
    }

    function handleCreateTrustedContact() {
        setIsLoading(true)
        dispatch(saveTrustedContact({jwtToken: keycloak.token!, trustedContact: trustedContact!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                navigate(`/trustedContacts`)
            }
        });
    }

    const isValidForm = useMemo(() => {
        if (trustedContact.did === undefined || trustedContact.did.length === 0) {
            return false;
        }
        return true;
    }, [trustedContact]);

    return (
        <>
            {isLoading ? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar> : <></>}
            <div>
                <InfoCard className="mb-4"
                          title={t('screens.trustedRelation.title')}
                          description={t('screens.trustedRelation.description')}
                          icon={<IdentificationCard width="40" height="40"/>} />

                <OCard className="mt-5 mb-4">
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.trustedRelation.attributes.did.label')}
                                        placeHolder={t('screens.trustedRelation.attributes.did.placeHolder')}
                                        value={trustedContact.did}
                                        onChangeValue={(value) => handleFormUpdate('did', value)}/>
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.trustedRelation.attributes.name.label')}
                                        placeHolder={t('screens.trustedRelation.attributes.name.placeHolder')}
                                        value={trustedContact.name}
                                        onChangeValue={(value) => handleFormUpdate('name', value)}/>

                </OCard>
            </div>
            <Button className="p-2 m-2" onClick={handleCreateTrustedContact} disabled={!isValidForm}>{t('screens.trustedRelation.create')}</Button>
        </>
    );
};
