import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { DidCredentialView, DidServiceView, OCard, TextToHtml, TextWithExternalLink } from '../../molecules';
import { EmptyProfile, Logo } from '../../atoms';
import { useTranslation } from 'react-i18next';
import { Credential, deleteServiceFromWalletProfile, DidService, getWalletProfile, setToastMessage, useAppDispatch, walletProfileSelector } from '../../../state';
import { Image } from 'primereact/image';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ClipboardCopyElement } from '../../molecules/ClipboardCopyElement';

interface ServiceCredential {
    service: DidService,
    credential: Credential
}

export const PublicProfileDetails: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();

    let walletProfile = useSelector(walletProfileSelector);

    useEffect(() => {
        dispatch(getWalletProfile({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    function handleAddCredential() {
        navigate(`/profile/selectCredentialToAdd`)
    }


    function editProfile() {
        navigate(`/profile/edit`)
    }

    function hasCredentials() : boolean{
        let findIndex = walletProfile.singleItem?.services?.findIndex(value => value.credentials !== undefined && value.credentials.length > 0);
        if (findIndex === undefined) {
            return false;
        }
        return (findIndex > -1);
    }

    function hasServices() {
        const numberOfServices = (walletProfile.singleItem?.services === undefined) ? 0 : walletProfile.singleItem?.services?.length;
        return numberOfServices > 0;

    }

    function handleAddService() {
        navigate('/did/' + walletProfile.singleItem?.defaultDidId)
    }

    function confirmDeleteService(service: DidService) {

        confirmDialog({
            header: t('screens.publicProfileDetails.deleteCredentialConfirm.title'),
            message: <div         dangerouslySetInnerHTML={{
                __html: t('screens.publicProfileDetails.deleteCredentialConfirm.message'),
            }}></div>,
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleDeleteService(service),
            reject: () => {
            }
        });
    }

    function handleDeleteService(service: DidService) {
        dispatch(deleteServiceFromWalletProfile({jwtToken: keycloak.token!, credentialId: service.id})).then((response) => {
            if (response.type.includes('fulfilled')) {
                dispatch(setToastMessage({message: "Saved"}));
            }
        });
    }

    return (
        <>
            <ConfirmDialog/>
            <OCard className="mb-4">
                    <div className="grid">
                    <div className="col-12 md:col-3">
                        <div className="flex align-items-center justify-content-center mr-2 p-8"
                             style={{borderColor: "#CBD5E0", maxHeight: "161px", maxWidth: "193px", backgroundColor: "#FFFFFF", borderWidth: 1, borderStyle: "solid"}}>
                            {(walletProfile.singleItem?.base64EncodedLogo === undefined) && (
                                <Logo className="flex align-self-start"/>
                            )}
                            {(walletProfile.singleItem?.base64EncodedLogo !== undefined) && (
                                <Image src={walletProfile.singleItem?.base64EncodedLogo} alt="Logo" width="120"/>
                            )}
                        </div>
                    </div>
                    <div className="col-12 md:col-9">
                        <div className="font-semibold text-2xl pt-2">{walletProfile.singleItem?.organizationName}</div>
                        <div className="text-sm pt-2 flex align-items-center" style={{color: "#757575"}}>
                            <div className="mr-1">{t('fields.did.single.typeDescription')}:</div>
                            <ClipboardCopyElement copyValue={walletProfile.singleItem?.defaultDid}><TextToHtml value={walletProfile.singleItem?.defaultDid}/></ClipboardCopyElement>
                        </div>
                        <div className="pt-2">{walletProfile.singleItem?.addressStreet}</div>
                        <div className="pt-2">{walletProfile.singleItem?.addressPostalCode} {walletProfile.singleItem?.addressCity} </div>
                        <div className="pt-2">{walletProfile.singleItem?.addressCountry}</div>
                        <div className="pt-2">{walletProfile.singleItem?.phoneNumber}</div>
                        <div className="pt-2">{walletProfile.singleItem?.email}</div>
                        <div className="pt-2"><TextWithExternalLink link={walletProfile.singleItem?.website}/></div>
                        <Button className="mt-4" onClick={editProfile}>{t('screens.publicProfileDetails.editContactInformation')}</Button>
                    </div>
                </div>
            </OCard>


            <div className="m-4 text-center" style={{color: "#757575"}}>
                {t('screens.publicProfileDetails.description1')}<br/>
                {t('screens.publicProfileDetails.description2')}
            </div>
            <div className="font-semibold text-xl mb-2">{t('fields.credential.plural.typeDescription')}</div>
            {(!hasCredentials()) && (
                <OCard className="mb-4">
                    <div className="flex align-items-center flex-wrap flex-column md:mt-2 pt-6">
                        <EmptyProfile/>
                        <div className="m-4 text-center max-w-25rem">{t('screens.publicProfileDetails.emptyCredentialListMessage')}</div>
                        <Button className="mt-4 mb-4" onClick={handleAddCredential}>{t('screens.publicProfileDetails.addNewCredential')}</Button>
                    </div>
                </OCard>
            )}
            {(hasCredentials()) && (
                <>

                    {walletProfile.singleItem?.services?.filter(service => service.credentials !== undefined && service.credentials.length > 0)
                        .flatMap((service: DidService) => service.credentials.map(credential => {
                            return {service, credential} as ServiceCredential;
                        }))
                        .map((serviceCredential: ServiceCredential, index: number) => (
                            <DidCredentialView key={index} className="mb-4" credential={serviceCredential.credential} onDeletedClicked={() => confirmDeleteService(serviceCredential.service)}/>
                        ))
                    }
                    <Button className="mb-6" onClick={handleAddCredential}>{t('screens.publicProfileDetails.addNewCredential')}</Button>
                </>
            )}


            <div className="font-semibold text-xl mb-2">{t('fields.didService.plural.typeDescription')}</div>
            {(!hasServices()) && (
                <OCard className="mb-4">
                    <div className="flex align-items-center flex-wrap flex-column md:mt-2 pt-4">
                        <div className="m-4 text-center max-w-25rem">{t('screens.publicProfileDetails.emptyServiceListMessage')}</div>
                        <Button className="mt-4 mb-4" onClick={handleAddService}>{t('screens.publicProfileDetails.addNewService')}</Button>
                    </div>
                </OCard>
            )}

            {(hasServices()) && (
                <>
                    {walletProfile.singleItem?.services?.filter(service => service.credentials === undefined || service.credentials.length === 0)
                        .map((service, index) => (
                            <DidServiceView key={index} className="mb-4" didService={service}/>
                        ))
                    }
                    <Button className="mb-6" onClick={handleAddService}>{t('screens.publicProfileDetails.addNewService')}</Button>
                </>
            )}


        </>
    );
};
