import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericStateSingleBuilders, addGenericStateListBuilders } from '../slice';
import { deleteTrustedContact, getTrustedContact, getTrustedContacts, saveTrustedContact, searchOrganizations } from './TrustedContactApi';
import { TrustedContact } from '../model/TrustedContact';


export interface TrustedContactState extends GenericPageableState<TrustedContact> {
}

export const trustedContactSlice = createSlice({
    name: 'trustedContact',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getTrustedContacts);
        addGenericStateSingleBuilders(builder, getTrustedContact);
        addGenericStateSingleBuilders(builder, saveTrustedContact);
        addGenericStateSingleBuilders(builder, deleteTrustedContact);
        addGenericStateListBuilders(builder, searchOrganizations);
    }
});
