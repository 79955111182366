import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import {
    getInboxItem,
    getTransportTypes,
    getTrustedContacts,
    inboxSelector,
    invoiceSelector,
    prepareInvoice,
    trustedContactSelector,
    useAppDispatch
} from '../../../state';
import { Button } from 'primereact/button';
import { OCard, TextInputWithLabel, TextToHtml } from '../../molecules';
import { IdentificationCard, InvoiceIcon, OFieldLabel, OFieldValue, OH3 } from '../../atoms';
import { InfoCard } from '../../molecules/InfoCard';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';
import { sendInvoice } from '../../../state/slices/invoice';
import { Invoice } from '../../../state/slices/model/Invoice';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { TrustedContact } from '../../../state/slices/model/TrustedContact';
import { NumberInputWithLabel } from '../../molecules/NumberInputWithLabel';
import { FileUploadWithLabel } from '../../molecules/FileUploadWithLabel';
import { Message } from 'primereact/message';
import { Column } from 'primereact/column';
import { didSelector, getDid } from '../../../state/slices/did';
import moment from 'moment-timezone';

export const InvoiceDetails: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    const {inboxId} = useParams();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [selectedRelation, setSelectedRelation] = useState<TrustedContact>();
    const [isErrorContact, setIsErrorContact] = useState(false)


    let inbox = useSelector(inboxSelector);
    useEffect(() => {
        if (inboxId) {
            dispatch(getInboxItem({jwtToken: keycloak.token!, inboxId: inboxId}));
        }
    }, [keycloak.token, inboxId]);

    const formatDate = (date: Date): string => {
        return moment(date).format("DD/MM/yyyy");
    };

    function getContentType(dataUrl: string) {
        const metadata = dataUrl.split(',')[0].split(':');
        if (metadata.length > 1) {
            return metadata[1].split(';')[0];
        }
        return '';
    }

    function getData(dataUrl: string) {
        const data = dataUrl.split(',');
        if (data.length > 1) {
            return data[1];
        }
        return '';
    }

    function createUrl(dataUrl: string) {
        if (!dataUrl) {
            return '';
        }
        const contentType = getContentType(dataUrl);
        const base64Data = getData(dataUrl);

        // Decode base64 string to raw binary data
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Convert to byte array
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });

        return URL.createObjectURL(blob);
    }

    return (
        <>
            {isLoading ? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar> : <></>}
            <div>
                <InfoCard className="mb-4"
                          title={t('screens.invoiceDetails.title')}
                          description={t('screens.invoiceDetails.description')}
                          icon={<InvoiceIcon width="40" height="40"/>} />

                <OCard className="mt-5 mb-4">
                    <div className="col-12 lg:col-9 ml-0 lg:pl-4">
                        <div className="formgrid">
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.invoiceId.label')}</OFieldLabel>
                                <OFieldValue className="col-8">{inbox.singleItem?.credential?.credentialSubject.invoice_id}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.sender.label')}</OFieldLabel>
                                <OFieldValue className="col-8">{inbox.singleItem?.credential?.displayProperties?.issuerDisplay?.displayName}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.invoiceDate.label')}</OFieldLabel>
                                <OFieldValue
                                    className="col-8">{formatDate(new Date(inbox.singleItem?.credential?.credentialSubject.invoice_date))}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.dueDate.label')}</OFieldLabel>
                                <OFieldValue
                                    className="col-8">{formatDate(new Date(inbox.singleItem?.credential?.credentialSubject.due_date))}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.currencyCode.label')}</OFieldLabel>
                                <OFieldValue
                                    className="col-8">{inbox.singleItem?.credential?.credentialSubject.currency_code}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.taxExclusiveAmount.label')}</OFieldLabel>
                                <OFieldValue
                                    className="col-8">{inbox.singleItem?.credential?.credentialSubject.tax_exclusive_amount}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.taxAmount.label')}</OFieldLabel>
                                <OFieldValue
                                    className="col-8">{inbox.singleItem?.credential?.credentialSubject.tax_amount}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.invoice.attributes.taxInclusiveAmount.label')}</OFieldLabel>
                                <OFieldValue
                                    className="col-8">{inbox.singleItem?.credential?.credentialSubject.tax_inclusive_amount}</OFieldValue>
                            </div>
                            <div>
                                <a href={createUrl(inbox.singleItem?.credential?.credentialSubject.invoice_document)}
                                   download={inbox.singleItem?.credential?.credentialSubject.invoice_id + '.xml'}>Download invoice</a>
                            </div>
                        </div>
                    </div>
                </OCard>
            </div>
        </>
    );
};
