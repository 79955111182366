import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getTrustedIssuerList, getTrustedIssuerLists, saveTrustedIssuerList } from './TrustedIssuerListApi';
import { TrustedIssuerList } from '../model/TrustedIssuerList';


export interface TrustedIssuerListState extends GenericPageableState<TrustedIssuerList> {
}

export const trustedIssuerListSlice = createSlice({
    name: 'trustedIssuerList',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getTrustedIssuerLists);
        addGenericStateSingleBuilders(builder, getTrustedIssuerList);
        addGenericStateSingleBuilders(builder, saveTrustedIssuerList);
    }
});
