import React, { HTMLInputTypeAttribute } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';


export interface NumberInputWithLabelProps {
    label: string;
    value?: number | undefined;
    onChangeValue: (value: number | null) => void;
    placeHolder?: string | undefined;
    className?: string | undefined;
    inputType?: HTMLInputTypeAttribute | undefined;
    footer?: React.ReactNode | undefined;
    postElement?: React.ReactNode | undefined;
}

export const NumberInputWithLabel: React.FC<NumberInputWithLabelProps> = (props) => {
    return (
        <div className={props.className} style={{border: 'none', borderRadius: 16, paddingLeft: 20, paddingRight: 20, paddingTop: 18, paddingBottom: 14, backgroundColor: '#ffffff'}}>
            <div className="text-xs pb-1 pl-2" style={{color: 'rgba(28, 28, 28, 0.40)'}}>{props.label}</div>
            <div className="flex">
                <InputNumber className="w-full" value={props.value}
                             onChange={(e) => props.onChangeValue(e.value ? e.value.valueOf(): null)}
                             mode="currency"
                             currency="EUR"
                             locale="nl-NL"
                             placeholder={props.placeHolder} />
                {props.postElement && (
                    <>{props.postElement}</>
                )}
            </div>
            {props.footer && (
                <>{props.footer}</>
            )}
        </div>
    );
};

