import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getTransportTypes, prepareInvoice, sendInvoice } from './InvoiceApi';


export interface InvoiceState extends GenericState<string> {
}

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateListBuilders(builder, getTransportTypes);
        addGenericStateSingleBuilders(builder, prepareInvoice);
        addGenericStateSingleBuilders(builder, sendInvoice);
    }
});
