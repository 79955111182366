import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { Invoice } from '../model/Invoice';

export const getTransportTypes = createAsyncThunk(
    'invoice/getTransportTypes', ({jwtToken, trustedRelationId}: { jwtToken: string | undefined, trustedRelationId: number }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/invoice/transporttypes/' + trustedRelationId;
        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const prepareInvoice = createAsyncThunk(
    'invoice/prepareInvoice', ({jwtToken, invoice}: { jwtToken: string | undefined, invoice: Invoice }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/invoice/prepare';
        return axios.post(url, invoice, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const sendInvoice = createAsyncThunk(
    'invoice/sendInvoice', ({jwtToken, invoice}: { jwtToken: string | undefined, invoice: Invoice }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/invoice/send';
        return axios.post(url, invoice, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
