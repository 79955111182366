import React, { useMemo } from 'react';
// import * as DOMPurify from 'dompurify';
import './TextToHtml.css'
import DOMPurify from 'dompurify';

export interface TextToHtmlProps {
    className?: string | undefined;
    value?: string | undefined;
}

export const TextToHtml: React.FC<TextToHtmlProps> = (props) => {

        const htmlSanitized = useMemo(() => {
            if (props.value === undefined) {
                return '';
            }

            return DOMPurify.sanitize(props.value!);
        }, [props.value]);

        if (props.value === undefined) {
            return null;
        }

        function isUrl(value: string) {
            if (typeof value !== 'string') {
                return false;
            }
            return (value.startsWith('http://') || value.startsWith('https://'));
        }

    function getContentSpan() {
        return (
            <span className={"text-to-html " + props.className}
                  dangerouslySetInnerHTML={{
                      __html: htmlSanitized,
                  }}
                  style={{wordBreak: 'break-word'}}
            ></span>
        );
    }

    if (isUrl(props.value)) {
            return (
                <a href={DOMPurify.sanitize(props.value!)} target="_blank" rel="noreferrer">
                    {getContentSpan()}
                    <li className="ml-1 text-xs pi pi-external-link"></li></a>
            );
        } else {
            return getContentSpan()
        }
    }
;

