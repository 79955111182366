import React from 'react';
import { IconProps } from './IconProps';


export const IconConnectToNewOrganisation: React.FC<IconProps> = ({className}: IconProps) => {
    return (
        <svg viewBox="0 0 62 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M57.6381 40.5919C57.6348 39.9616 57.5937 39.332 57.5152 38.7066L61.3724 36.831L59.9182 32.3582L55.7039 33.1101C55.0876 32.0043 54.3386 30.9779 53.4735 30.0537L55.4943 26.2706L51.6985 23.5105L48.7162 26.6032C47.5701 26.0617 46.3617 25.6636 45.1182 25.418L44.5389 21.1837H39.832L39.2527 25.4224C38.0092 25.6681 36.8008 26.0661 35.6547 26.6076L32.6724 23.5149L28.8766 26.275L30.8975 30.0581C30.0326 30.981 29.2836 32.0059 28.667 33.1101L24.4527 32.3582L23 36.831L26.8521 38.7044C26.6878 39.9559 26.6878 41.2234 26.8521 42.4749L23 44.3483L24.4541 48.8211L28.6684 48.0692C29.2848 49.175 30.0338 50.2014 30.8989 51.1256L28.8781 54.9087L32.6738 57.6688L35.6562 54.5761C36.8022 55.1177 38.0107 55.5157 39.2542 55.7613L39.8335 60H44.5411L45.1204 55.7613C46.3639 55.5157 47.5724 55.1177 48.7184 54.5761L51.7008 57.6688L55.4965 54.9087L53.4735 51.1256C54.3386 50.2014 55.0876 49.175 55.7039 48.0692L59.9182 48.8211L61.3724 44.3483L57.5152 42.4749C57.5936 41.8502 57.6347 41.2214 57.6381 40.5919ZM42.1862 48.284C40.6642 48.2842 39.1764 47.833 37.9109 46.9875C36.6454 46.142 35.6591 44.9402 35.0767 43.5342C34.4943 42.1281 34.3419 40.5809 34.6389 39.0882C34.9359 37.5955 35.6689 36.2244 36.7452 35.1484C37.8214 34.0723 39.1927 33.3396 40.6854 33.0429C42.1782 32.7462 43.7254 32.8989 45.1314 33.4816C46.5374 34.0642 47.739 35.0508 48.5842 36.3164C49.4295 37.5821 49.8804 39.0699 49.8799 40.5919C49.88 41.6022 49.6811 42.6026 49.2945 43.536C48.9078 44.4694 48.3411 45.3175 47.6266 46.0318C46.9121 46.7461 46.0639 47.3127 45.1305 47.6991C44.197 48.0856 43.1965 48.2843 42.1862 48.284Z"
                fill="#A8B9C7"/>
            <path
                d="M28.9667 15.8769C28.9639 15.3612 28.9303 14.8462 28.8659 14.3346L32.0217 12.802L30.832 9.14257L27.3844 9.7574C26.88 8.85285 26.267 8.01327 25.5591 7.25731L27.2125 4.16238L24.1072 1.90452L21.6671 4.43498C20.7293 3.9927 19.7405 3.66777 18.7232 3.46754L18.2491 0H14.397L13.9229 3.46754C12.9056 3.66777 11.9169 3.9927 10.9791 4.43498L8.53969 1.90452L5.43434 4.16238L7.09073 7.25731C6.38306 8.01331 5.77034 8.85289 5.26619 9.7574L1.8186 9.14257L0.628906 12.802L3.78463 14.3346C3.65026 15.3584 3.65026 16.3954 3.78463 17.4192L0.628906 18.9518L1.8186 22.6112L5.26619 21.9964C5.77034 22.9009 6.38306 23.7405 7.09073 24.4965L5.43731 27.5914L8.54266 29.8507L10.982 27.3203C11.9198 27.7629 12.9085 28.0881 13.9259 28.2885L14.4 31.756H18.2521L18.7262 28.2885C19.7426 28.0874 20.7303 27.7617 21.6671 27.3188L24.1079 29.8507L27.2133 27.5929L25.5598 24.498C26.2675 23.7422 26.8802 22.9029 27.3844 21.9986L30.832 22.6134L32.0217 18.954L28.8659 17.4214C28.9304 16.909 28.964 16.3933 28.9667 15.8769ZM16.3253 22.106C15.0933 22.1057 13.8891 21.7401 12.8649 21.0555C11.8407 20.3709 11.0425 19.3979 10.5713 18.2596C10.1001 17.1214 9.9769 15.869 10.2174 14.6607C10.4579 13.4525 11.0513 12.3427 11.9226 11.4717C12.7938 10.6007 13.9037 10.0076 15.1121 9.76735C16.3204 9.52712 17.5728 9.65057 18.7109 10.1221C19.8491 10.5936 20.8219 11.392 21.5063 12.4163C22.1907 13.4407 22.556 14.645 22.556 15.8769C22.5563 16.6951 22.3953 17.5054 22.0823 18.2614C21.7692 19.0173 21.3103 19.7042 20.7316 20.2827C20.153 20.8612 19.466 21.32 18.7099 21.6329C17.9538 21.9457 17.1435 22.1065 16.3253 22.106Z"
                fill="#19339E"/>
        </svg>

    )
};
