import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useAppDispatch } from '../../state';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoCard } from '../molecules';
import { credentialStatusSelector, getCredentialStatusConfigurations } from '../../state/slices/credentialstatus';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { HandWithdraw } from '../atoms/icons/HandWithdraw';

export const CredentialStatusListList: FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();

    let credentialStatusConfigurations = useSelector(credentialStatusSelector);
    useEffect(() => {
        dispatch(getCredentialStatusConfigurations({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    function handleCreateConfiguration() {
        navigate('/credentialStatus/new');
    }

    return (
        <>
            <div className="card">
                <InfoCard className="mb-4"
                          title={t('screens.credentialStatusList.title')}
                          description={t('screens.credentialStatusList.description')}
                          icon={<HandWithdraw width="40" height="40"/>} />

                <DataTable value={credentialStatusConfigurations.list} rows={10} rowsPerPageOptions={[12, 24, 36]} tableStyle={{minWidth: '50rem'}}
                           selectionMode="single"
                           emptyMessage={t('screens.credentialStatusList.noCredentialStatusList')}
                           rowHover={true}>
                    <Column field="name" header={t('screens.credentialStatusList.attributes.name.label')} style={{width: '15%'}}></Column>
                    <Column field="type" header={t('screens.credentialStatusList.attributes.type.label')} style={{width: '15%'}}></Column>
                    <Column field="purpose" header={t('screens.credentialStatusList.attributes.purpose.label')} style={{width: '15%'}}></Column>
                </DataTable>

                <Button className="p-2 m-2" onClick={handleCreateConfiguration}>{t('screens.credentialStatusList.createCredentialStatusList')}</Button>
            </div>
        </>
    );
};
