import React, { FC, useMemo, useRef } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { useSelector } from 'react-redux';
import { userSelector } from '../../state';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Menu } from 'primereact/menu';

export interface ProfileMenuProps {
    className?: string | undefined;
    menuItems: MenuItem[]
}

export const ProfileMenu: FC<ProfileMenuProps> = (props) => {
    const {t} = useTranslation();
    const menuRef = useRef<Menu>(null);
    let user = useSelector(userSelector).singleItem;
    let {keycloak} = useKeycloak();

    const currentUser = useMemo(() => {
        if (user === undefined) {
            return {fullName: keycloak.tokenParsed.name
            , organization: undefined}
        } else {
            return user;
        }

    }, [user, keycloak]);

    return (
        <div className={"card " + props.className}>
            <a className="flex align-items-center p-menuitem-link" onClick={(event) => menuRef.current?.toggle(event)}>
                <div className="flex align-items-start justify-content-left gap-2 flex-wrap flex-column ml-3 mr-3" >
                    {(currentUser?.organization) && (<div className="font-semibold">{currentUser?.organization}</div>)}
                    <div className="text-sm" style={{color: 'var(--black-200)'}}>{currentUser?.fullName}</div>
                </div>
                <ChevronDownIcon color="#1C1C1C66"/>
            </a>

            <Menu model={props.menuItems} popup ref={menuRef} popupAlignment="right"/>
        </div>
    )
}
