import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import { useAppDispatch } from '../../state';
import { Button } from 'primereact/button';
import { OCard, TextInputWithLabel } from '../molecules';
import { IdentificationCard } from '../atoms';
import { InfoCard } from '../molecules/InfoCard';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';

import { DropdownWithLabel } from '../molecules/DropdownWithLabel';
import { useTranslation } from 'react-i18next';
import { CredentialStatusList, saveCredentialStatusList } from '../../state/slices/credentialstatus';

export const CredentialStatusListDetails: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    const [isLoading, setIsLoading] = useState<boolean>();
    const credentialStatusListTypes = [
        { label: 'Status List 2021', optionValue: 'STATUS_LIST_2021'}
    ];
    const purposes = [
        { label: 'Revocation', optionValue: 'REVOCATION' },
        { label: 'Suspension', optionValue: 'SUSPENSION' },
    ];
    const [selectedType, setSelectedType] = useState<any>(credentialStatusListTypes[0]);
    const [selectedPurpose, setSelectedPurpose] = useState<any>(purposes[0]);

    const [credentialStatusListForm, setCredentialStatusListForm] = useState<CredentialStatusList>({type: credentialStatusListTypes[0].optionValue, purpose: purposes[0].optionValue});
    let navigate = useNavigate();

    function handleFormUpdate(attributeName: string, value: string | undefined) {
        setCredentialStatusListForm(Object.assign({}, credentialStatusListForm, {[attributeName]: value}));
    }

    function onSelectType(type: any) {
        setSelectedType(type);
        handleFormUpdate('type', type.optionValue);
    }

    function onSelectPurpose(purpose: any) {
        setSelectedPurpose(purpose);
        handleFormUpdate('purpose', purpose.optionValue);
    }

    function handleCreateCredentialStatusList() {
        setIsLoading(true)
        dispatch(saveCredentialStatusList({jwtToken: keycloak.token!, credentialStatusList: credentialStatusListForm!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                navigate(`/credentialStatus`)
            }
        });
    }

    const isValidForm = useMemo(() => {
        if (credentialStatusListForm.name === undefined || credentialStatusListForm.name.length === 0) {
            return false;
        }
        return true;
    }, [credentialStatusListForm]);

    return (
        <>
            {isLoading ? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar> : <></>}
            <div>
                <InfoCard className="mb-4"
                          title={t('screens.credentialStatusList.title')}
                          description={t('screens.credentialStatusList.description')}
                          icon={<IdentificationCard width="40" height="40"/>} />

                <OCard className="mt-5 mb-4">
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.credentialStatusList.attributes.name.label')}
                                        placeHolder={t('screens.credentialStatusList.attributes.name.placeHolder')}
                                        value={credentialStatusListForm.name}
                                        onChangeValue={(value) => handleFormUpdate('name', value)}/>

                    <DropdownWithLabel label={t('screens.credentialStatusList.attributes.type.label')} className="mb-3"
                                       value={selectedType} onChangeValue={(e) => onSelectType(e)}
                                       options={credentialStatusListTypes} optionLabel='label'/>

                    <DropdownWithLabel label={t('screens.credentialStatusList.attributes.purpose.label')} className="mb-3"
                                       value={selectedPurpose} onChangeValue={(e) => onSelectPurpose(e)}
                                       options={purposes} optionLabel='label'/>

                </OCard>
            </div>
            <Button className="p-2 m-2" onClick={handleCreateCredentialStatusList} disabled={!isValidForm}>{t('screens.credentialStatusList.createCredentialStatusList')}</Button>
        </>
    );
};
