import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { addQueryParam } from '../slice';


export const getCredentialTypes = createAsyncThunk(
    'credentialType/getCredentialTypes', ({jwtToken, page, pageSize, locale, searchText, credentialKind}: {
        jwtToken: string | undefined,
        page: number,
        pageSize: number,
        locale: string | undefined,
        searchText?: string,
        credentialKind?: string
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };


        var url = addQueryParam('/credential/type', 'locale', locale);
        url = addQueryParam(url, 'page', page);
        url = addQueryParam(url, 'size', pageSize);
        url = addQueryParam(url, 'q', searchText);
        url = addQueryParam(url, 'credentialKind', credentialKind);

        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const issueCredentialType = createAsyncThunk(
    'credentialType/issueCredentialType', ({jwtToken, credentialTypeId, redirectUrl}: { jwtToken: string | undefined, credentialTypeId: number, redirectUrl?: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        const body = {} as any;
        if (redirectUrl !== undefined) {
            body.redirectUrl = redirectUrl;
        }

        return axios.post('/credential/type/' + credentialTypeId + '/issue', body, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
