import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { AuthenticationProvider, I18n } from './components';
import { configureAxiosDefaults } from './AxiosConfig';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './theme/themes/mytheme/theme.scss';
import 'primeflex/primeflex.scss';
import { AppRoutes } from './AppRoutes';

function App() {

    configureAxiosDefaults(store);

    return (
        <Provider store={store}>
            <I18n/>
            <PrimeReactProvider>
                <AuthenticationProvider>
                    <AppRoutes/>
                </AuthenticationProvider>
            </PrimeReactProvider>
        </Provider>
    );
}

export default App;
