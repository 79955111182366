import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IssueCredentialForm } from '../../organisms';
import { SignupExistingCompanyStepper } from '../../organisms/SignupExistingCompanyStepper';

export const SignupExistingCompanyAcceptLpid: FC = () => {
    const {t} = useTranslation();

    return (
        <main className="w-full">
            <h1 className="text-3xl font-bold mt-8">{t('screens.signupExistingCompany.title')}</h1>
            <p className="mb-8">{t('screens.signupExistingCompany.intro')}</p>
            <SignupExistingCompanyStepper selectedIndex={2} className="mb-6"/>
            <IssueCredentialForm onAcceptRoute="/signup/existingCompany/complete" onRejectRoute="/"/>
            {/*<a href="/signup/existingCompany/complete">Next</a>*/}
        </main>
    );
}
