export interface AppTexts {
    menu: {
        dashboard: string;
        wallet: string;
        walletOverview: string;
        settings: string;
        newCredential: string;
        receive: string;
        history: string;
        advanced: string;
        logoff: string;
        holder: string;
        issuer: string;
        issuerTemplates: string;
        issuerTrust: string;
        identifiers: string;
        generic: string;
        verifier: string;
        verifierTemplate: string;
        trustAchor: string;
        catalogConfig: string;
        apiDocs: string;
        apiKey: string;
        publicProfile: string;
        contacts: string;
        trustedContacts: string;
        applications: string;
        inbox: string;
        trustedIssuerList: string;
        miscellaneous: string;
        issuedCredentials: string;
        receivedCredentials: string;
    },
    generic: {
        yes: string;
        no: string;
        ok: string;
        cancel: string;
        add: string;
        save: string;
        delete: string;
        edit: string;
        back: string;
        loading: string;
        accept: string;
        reject: string;
        search: string;
        startSearching: string;
        removeCredential: string;
        revokeCredential: string;
        copiedToClipboard: string;
    },
    error: {
        retrievingData: string;
        errorCodes: {
            "ERR-1": string;
        };
        inputErrors: {
            requiredField: string;
            noSpacesAllowed: string;
            invalidEmail: string;
            invalidUrl: string;
        }
    },
    fields: {
        credentialIssuerCredentialAttributeType: {
            "OBJECT": string;
            "ARRAY": string;
            "STRING": string;
            "INTEGER": string;
            "NUMBER": string;
            "BOOLEAN": string;
        },
        credentialStatus: {
            "VALID": string;
            "INVALID": string;
            "EXPIRED": string;
            "UNCHECKED": string;
        },
        credential: {
            single: {
                typeDescription: string;
            },
            plural: {
                typeDescription: string;
            }
        },
        did: {
            single: {
                typeDescription: string;
            },
            plural: {
                typeDescription: string;
            }
        },
        didService: {
            single: {
                typeDescription: string;
                serviceId: string;
                serviceType: string;
                serviceEndpoint: string;
            }
            plural: {
                typeDescription: string;
            }
        },
        trustedIssuerList: {
            single: {
                typeDescription: string;
                credentialType: string;
                displayName: string;
            },
            plural: {
                typeDescription: string;
            }
        }
        trustedIssuerListDid: {
            single: {
                typeDescription: string;
                did: string;
                displayName: string;
            },
            plural: {
                typeDescription: string;
            }
        }
    }
    screens: {
        header: {
            welcomeTitle: string;
            welcomeSubTitle: string;
        }
        apiKey: {
            title: string;
            description: string;
            currentApiKey: string;
            showApiDocumentation: string;
            copiedToClipboard: string;
        },
        credentialCard: {
            requestCredential: string;
        }
        credentialTypeCard: {
            createNewTemplate: string;
        }
        credentialAttributeInput: {
            placeHolder: string;
        }
        credentialList: {
            title: string;
            emptyListMessage: string;
            searchNoCredentialsFound: string;
            requestNewCredential: string;
            searchTitle: string;
            myCredentials: string;
            issuanceDate: string;
            issuer: string;
            credentialType: string;
        }
        credentialDetail: {
            title: string;
            issuanceDate: string;
            issuer: string;
            credentialType: string;
            credentialTypeDescription: string;
            termsOfUse: {
                title: string,
                id: string;
                type: string;
            };
        }
        credentialTypeList: {
            title: string;
            intro: string;
            searchTitle: string;
            requestCredential: string;
        }
        credentialDetails: {
            deleteConfirmTitle: string;
            deleteConfirmMessage: string;
        }
        issueCredentials: {
            title: string;
            intro: string;
            issueTo: string;
        }
        verifyCredentials: {
            title: string;
            intro: string;
            noMatchingCredentials: string;
            successSummary: string;
            successDetail: string;
        }
        issuedCredentialList: {
            title: string;
            emptyListMessage: string;
            searchNoCredentialsFound: string;
            requestNewCredential: string;
            searchTitle: string;
            issuanceDate: string;
            issuer: string;
            credentialType: string;
            status: string;
        }
        issuedCredentialDetail: {
            title: string;
            issuanceDate: string;
            issuer: string;
            credentialType: string;
            credentialTypeDescription: string;
            termsOfUse: {
                title: string,
                id: string;
                type: string;
            };
            revokeConfirmTitle: string;
            revokeConfirmMessage: string;
        }

        presentationDefinitionList: {
            title: string;
        },
        presentationDefinitionFormSelectCredentials: {
            intro: {
                title: {
                    add: string;
                    update: string;
                };
                description: string;
            },
            searchTitle: string;
            searchPlaceHolder: string;
            selectedCredentialTypes: string;
            noCredentialTypesSelected: string;
            buttonStartConfiguration: string;
        },
        presentationDefinitionFormConfigureCredentials: {
            intro: {
                title: string;
                description: string;
            },
            saveConfigurationTitle: string;
            technicalConfigurationTitle: string;
            attributes: {
                externalKey: {
                    label: string;
                    placeHolder: string;
                }
                name: {
                    label: string;
                    placeHolder: string;
                }
                description: {
                    label: string;
                    placeHolder: string;
                }
                purpose: {
                    label: string;
                    placeHolder: string;
                }
                successRedirectUrl: {
                    label: string;
                    placeHolder: string;
                }
                errorRedirectUrl: {
                    label: string;
                    placeHolder: string;
                }
                clientUrl: {
                    label: string;
                    placeHolder: string;
                }
            }
            titleAttribute: string;
            titleAttributePlaceHolder: string;
            descriptionAttribute: string;
            descriptionAttributePlaceHolder: string;
            buttonSaveConfiguration: string;
        },
        presentationDefinitionFormTestConfig: {
            intro: {
                title: string;
                description: string;
            },
            testWithWallet: {
                title: string;
                description: string;
                walletUrl: {
                    label: string;
                    placeHolder: string;
                }
                buttonTest: string;
                successfullyReceived: string;
            }
        }
        credentialIssuerDefinitionList: {
            intro: {
                title: string;
                description: string;
            }
        },
        credentialIssuerDefinitionForm: {
            intro: {
                new: {
                    title: string;
                    description: string;
                }
                edit: {
                    title: string;
                    description: string;
                }
            },
            buttonSaveConfiguration: string;
            saveConfigurationTitle: string;
            saveChangesDialog: {
                title: string;
                message: string;
            }
            languageTitle: string;
            languagePlaceHolder: string;
            attributes: {
                externalKey: {
                    label: string;
                    placeHolder: string;
                }
                name: {
                    label: string;
                    placeHolder: string;
                }
                description: {
                    label: string;
                    placeHolder: string;
                }
                displayName: {
                    label: string;
                    placeHolder: string;
                }
                logo: {
                    label: string;
                    placeHolder: string;
                }
                logoAlt: {
                    label: string;
                    placeHolder: string;
                }
                issuerUrl: {
                    label: string;
                }
            },
            template: {
                title: string;
                selectDid: string;
            },
            trust: {
                title: string;
                registerIssuer: string;
            },
            issue: {
                title: string;
                placeHolder: string;
                walletUrl: {
                    label: string;
                    placeHolder: string;
                }
            },
            qrcode: {
                title: string;
            },
        },
        credentialIssuerCredentialDefinitionForm: {
            intro: {
                new: {
                    title: string;
                    description: string;
                }
                edit: {
                    title: string;
                    description: string;
                }
            },
            attributes: {
                credentialConfigurationId: {
                    label: string;
                    placeHolder: string;
                }
            },
            buttonSaveConfiguration: string;
            saveConfigurationTitle: string;
            saveChangesDialog: {
                title: string;
                message: string;
            }
            languageTitle: string;
            languagePlaceHolder: string;
        },
        credentialIssuerDisplay: {
            title: string;
            description: string;
            defaultLanguageLabel: string;
            attributes: {
                name: {
                    label: string;
                    placeHolder: string;
                }
                logoUri: {
                    label: string;
                    placeHolder: string;
                },
                logoImage: {
                    label: string;
                    placeHolder: string;
                },
                logoAlt: {
                    label: string;
                    placeHolder: string;
                }
            }
        },
        credentialDisplay: {
            title: string;
            description: string;
            defaultLanguageLabel: string;
            attributes: {
                name: {
                    label: string;
                    placeHolder: string;
                }
                backgroundImageUri: {
                    label: string;
                    placeHolder: string;
                },
                backgroundImageAltText: {
                    label: string;
                    placeHolder: string;
                }
                backgroundColor: {
                    label: string;
                }
                textColor: {
                    label: string;
                }
            }
        },
        credentialIssuerCredentialDefinitionDisplay: {
            title: string;
            defaultLanguageDescription: string;
            attributes: {
                name: {
                    label: string;
                    placeHolder: string;
                }
                backgroundUrl: {
                    label: string;
                    placeHolder: string;
                },
                backgroundAltText: {
                    label: string;
                    placeHolder: string;
                }
            }
        },
        historyList: {
            intro: {
                title: string,
                description: string
            },
            date: string;
            time: string;
            event: string;
            action: string;
            user: string;
            party: string;
            noHistory: string;
            eventType: {
                CREDENTIAL: string;
                PRESENTATION: string;
            },
            actionType: {
                OFFER_RECEIVED: string;
                OFFER_ACCEPTED: string;
                DELETED: string;
            }
        },
        settings: {
            dids: {
                title: string;
                description: string;
                didType: string,
                didId: string,
                createDid: string;
            },
            did: {
                create: {
                    title: string;
                    description: string;
                    header: string;
                },
                setDefault: {
                    buttonLabel: string;
                    confirmDialog: {
                        title: string;
                        body: string;
                    }
                    updatedMessage: string;
                },
                attributes: {
                    didId: {
                        label: string;
                    },
                    type: {
                        label: string;
                        placeHolder: string;
                    },
                    displayName: {
                        label: string;
                        placeHolder: string;
                    },
                    ebsiEnvironment: {
                        label: string;
                        placeHolder: string;
                    },
                    keyId: {
                        label: string;
                    },
                    keyAlias: {
                        label: string;
                        placeHolder: string;
                    },
                    keyPurposes: {
                        label: string;
                        placeHolder: string;
                    },
                    serviceId: {
                        placeHolder: string;
                    },
                    serviceType: {
                        placeHolder: string;
                    },
                    serviceEndpoint: {
                        placeHolder: string;
                    },
                    taoWalletAddress: {
                        label: string;
                        placeHolder: string;
                    },
                    domain: {
                        label: string;
                        placeHolder: string;
                    },
                    path: {
                        label: string;
                        placeHolder: string;
                    }
                },
                title: string;
                description: string;
                keys: string;
                services: string;
            },
        },
        credentialStatusList: {
            title: string;
            description: string;
            createCredentialStatusList: string;
            noCredentialStatusList: string;
            attributes: {
                name: {
                    label: string;
                    placeHolder: string;
                },
                type: {
                    label: string;
                },
                purpose: {
                    label: string;
                },
                externalUrl: {
                    label: string;
                    placeHolder: string;
                }
            }
        },
        pleaseCreateADidFirstView: {
            title: string;
            description: string;
            createLink: string
        }
        publicProfileDetails: {
            title: string;
            emptyCredentialListMessage: string;
            emptyServiceListMessage: string;
            editContactInformation: string;
            addNewCredential: string;
            addNewService: string;
            description1: string;
            description2: string;
            deleteCredentialConfirm: {
                title: string;
                message: string;
            }
        },
        selectCredentialToAdd: {
            title: string;
            description1: string;
            description2: string;
        },
        walletProfile: {
            organizationName: {
                label: string;
                placeHolder: string;
            }
            apiKey: {
                label: string;
                placeHolder: string;
            }
            base64EncodedLogo: {
                label: string;
                placeHolder: string;
            }
            website: {
                label: string;
                placeHolder: string;
            }
            phoneNumber: {
                label: string;
                placeHolder: string;
            }
            email: {
                label: string;
                placeHolder: string;
            }
            addressStreet: {
                label: string;
                placeHolder: string;
            }
            addressPostalCode: {
                label: string;
                placeHolder: string;
            }
            addressCity: {
                label: string;
                placeHolder: string;
            }
            addressCountry: {
                label: string;
                placeHolder: string;
            }
            geoLocationLatitude: {
                label: string;
                placeHolder: string;
            }
            geoLocationLongitude: {
                label: string;
                placeHolder: string;
            }
        },
        trustedContactList: {
            title: string;
            intro: string;
        },
        trustedContact: {
            title: string;
            description: string;
            attributes: {
                did: {
                    label: string;
                    placeHolder: string;
                },
                name: {
                    label: string;
                    placeHolder: string;
                },
            },
            create: string;
        },
        trustedContactDetails: {
            description: string;
            emptyCredentialListMessage: string;
            emptyServiceListMessage: string;
            deleteContactConfirm: {
                title: string;
                message: string;
            },
            delete: string;
        }
        inbox: {
            title: string;
            intro: string;
        },
        invoice: {
            title: string;
            description: string;
            create: string;
            attributes: {
                sender: {
                    label: string;
                    placeholder: string;
                },
                receiver: {
                    label: string;
                    placeholder: string;
                },
                transport: {
                    label: string;
                    placeholder: string;
                },
                invoiceId: {
                    label: string;
                },
                invoiceDate: {
                    label: string;
                },
                dueDate: {
                    label: string;
                },
                currencyCode: {
                    label: string;
                },
                taxExclusiveAmount: {
                    label: string;
                },
                taxAmount: {
                    label: string;
                },
                taxInclusiveAmount: {
                    label: string;
                },
                invoiceDocument: {
                    label: string;
                }
            }
        },
        invoiceDetails: {
            title: string;
            description: string;
        },
        searchOrganization: {
            title: string;
            intro: string;
            search: {
                title: string;
                placeholder: string;
            },
            organization: string;
            action: string;
            add: string;
            create: string;
        },
        trustedIssuerListList: {
            title: string;
            intro: string;
        },
        trustedIssuerListForm: {
            title: string;
            intro: string;
            didListIntro: string;
            addDidButtonLabel: string;
            fields: {
                credentialType: {
                    placeHolder: string;
                },
                displayName: {
                    placeHolder: string;
                },
                did: {
                    did: {
                        placeHolder: string;
                    },
                    displayName: {
                        placeHolder: string;
                    }
                    deleteIcon: {
                        tooltip: string;
                    }
                }
            }
        },
        signupStep1: {
            title: string;
            intro: string;
            connectExisting: {
                title: string;
                intro: string;
                actionButtonLabel: string;
            }
            connectNewCompany: {
                title: string;
                intro: string;
                actionButtonLabel: string;
            }
        },
        signupExistingCompany: {
            title: string;
            intro: string;
            step1: {
                title: string;
                intro: string;
                actionButtonLabel: string;
            };
            companyProfile: {
                title: string;
                intro: string;
                body: string;
                actionButtonLabel: string;
            }
            completeSignup: {
                title: string;
                intro: string;
                body: string;
                actionButtonLabel: string;
            }
        }
        dashboard: {
            title: string;
            subtitle: string;
            issuer: {
                title: string;
                description: string;
            },
            holder: {
                title: string;
                description: string;
            },
            verifier: {
                title: string;
                description: string;
            },
            messages: {
                title: string;
                description: string;
            },
            completeSignup: {
                title: string;
                description: string;
                actionLinkLabel: string;
            }
        }
    }
}

const getAppTextsEn = (): AppTexts => {
    return {
        menu: {
            dashboard: 'Overview',
            wallet: 'Wallet',
            walletOverview: 'Wallet',
            settings: 'Settings',
            newCredential: "Request credential",
            receive: "Verifier templates",
            history: 'Audit trail',
            advanced: 'Settings',
            holder: 'Holder',
            logoff: 'Logoff',
            issuer: 'Issuer',
            issuerTemplates: 'Templates',
            issuerTrust: 'Trust anchor',
            identifiers: 'Identifiers',
            generic: 'Generic',
            verifier: 'Verifier',
            verifierTemplate: 'Template',
            trustAchor: 'Trust anchor',
            catalogConfig: 'Catalog',
            apiDocs: 'API documentation',
            apiKey: 'API key',
            publicProfile: 'Public profile',
            contacts: 'Contact',
            trustedContacts: 'Contacts',
            applications: 'Applications',
            inbox: 'Inbox',
            trustedIssuerList: "Trusted issuers",
            miscellaneous: 'Miscellaneous',
            issuedCredentials: 'Issued',
            receivedCredentials: 'Received data'
        },
        generic: {
            yes: 'Ja',
            no: 'Nee',
            ok: 'OK',
            cancel: 'Cancel',
            add: 'Add',
            save: 'Save',
            edit: 'Edit',
            delete: 'Delete',
            back: 'Back',
            loading: 'Loading...',
            accept: 'Accept',
            reject: 'Reject',
            search: 'Search',
            startSearching: 'Start searching...',
            removeCredential: 'Remove credential',
            revokeCredential: 'Revoke credential',
            copiedToClipboard: 'Copied to clipboard',
        },
        error: {
            retrievingData: "Er is is mis gegaan bij het ophalen van de data. Probeer het later nog een keer.",
            errorCodes: {
                "ERR-1": 'E-mail adres is niet toegestaan. Gebruik uw zakelijke e-mail adres.'
            },
            inputErrors: {
                requiredField: 'Required field',
                noSpacesAllowed: 'No spaces allowed',
                invalidEmail: 'Invalid email',
                invalidUrl: 'Invalid URL'
            },
        },
        fields: {
            credentialIssuerCredentialAttributeType: {
                "OBJECT": "Complex type",
                "ARRAY": "Array",
                "STRING": "String",
                "INTEGER": "Integer",
                "NUMBER": "Number",
                "BOOLEAN": "Boolean"
            },
            credentialStatus: {
                VALID: 'Valid',
                INVALID: 'Invalid',
                EXPIRED: 'Expired',
                UNCHECKED: 'Unchecked'
            },
            credential: {
                single: {
                    typeDescription: 'Credential',
                },
                plural: {
                    typeDescription: 'Credentials'
                }
            },
            did: {
                single: {
                    typeDescription: 'ID'
                },
                plural: {
                    typeDescription: 'IDs'
                }
            },
            didService: {
                single: {
                    typeDescription: 'Service',
                    serviceId: 'ID',
                    serviceType: 'Type',
                    serviceEndpoint: 'Endpoint',
                },
                plural: {
                    typeDescription: 'Services'
                }
            },
            trustedIssuerList: {
                single: {
                    typeDescription: 'Trusted issuer',
                    credentialType: 'Credential type',
                    displayName: 'Name',
                },
                plural: {
                    typeDescription: 'Trusted issuers',
                }
            },
            trustedIssuerListDid: {
                single: {
                    typeDescription: 'Trusted issuer Identifier',
                    did: 'Identifier',
                    displayName: 'Name',
                },
                plural: {
                    typeDescription: 'Trusted issuer Identifiers',
                }
            }
        },
        screens: {
            header: {
                welcomeTitle: 'Hi {{firstName}}',
                welcomeSubTitle: 'Welcome to the organization wallet of {{organizationName}}.',
            },
            apiKey: {
                title: 'API Key',
                description: 'The API key is used to authenticate your requests to the API.',
                currentApiKey: 'Current API key',
                showApiDocumentation: 'Show API documentation',
                copiedToClipboard: 'ApiKey copied to clipboard',
            },
            credentialCard: {
                requestCredential: 'Request credential'
            },
            credentialTypeCard: {
                createNewTemplate: 'Create new template'
            },
            credentialAttributeInput: {
                placeHolder: 'Enter the name of the attribute'
            },
            credentialList: {
                title: 'Wallet overview',
                emptyListMessage: 'Get started filling your wallet by requesting some credentials.',
                searchNoCredentialsFound: 'No credentials found',
                requestNewCredential: 'Request new credential',
                searchTitle: 'Search credentials',
                myCredentials: 'My credentials',
                issuanceDate: 'Issuance date',
                issuer: 'Issuer',
                credentialType: 'Type'
            },
            credentialDetail: {
                title: 'Credential details',
                issuanceDate: 'Issuance date',
                issuer: 'Issuer',
                credentialType: 'Type',
                credentialTypeDescription: 'Description',
                termsOfUse: {
                    title: 'Terms of use',
                    id: 'ID',
                    type: 'Type'
                },
            },
            credentialTypeList: {
                title: 'Request new credential',
                intro: 'Choose one of credentials from the pre-configured issuers',
                searchTitle: 'Search credentials of your preferred issuers',
                requestCredential: 'Request credentials'
            },
            credentialDetails: {
                deleteConfirmTitle: 'Delete credential?',
                deleteConfirmMessage: 'Are you sure you want to delete this credential? This action cannot be undone.'
            },
            issueCredentials: {
                title: 'Receive credential',
                intro: 'The following credential(s) will be issued by {{issuer}}.',
                issueTo: 'Issue credential to'
            },
            verifyCredentials: {
                title: 'Present credential(s)',
                intro: 'The following credentials are requested by {{verifierHost}} for the following purpose:',
                noMatchingCredentials: 'You don\'t have any credentials matching this presentation definition in your wallet.',
                successSummary: 'Successfully shared',
                successDetail: 'You successfully shared the requested credential(s).'
            },
            issuedCredentialList: {
                title: 'Issued Credentials',
                emptyListMessage: 'No Credentials issued yet.',
                searchNoCredentialsFound: 'No credentials found',
                requestNewCredential: 'Request new credential',
                searchTitle: 'Search issued credentials',
                issuanceDate: 'Issuance date',
                issuer: 'Issuer',
                credentialType: 'Type',
                status: 'Status'
            },
            issuedCredentialDetail: {
                title: 'Issued Credential details',
                issuanceDate: 'Issuance date',
                issuer: 'Issuer',
                credentialType: 'Type',
                credentialTypeDescription: 'Description',
                termsOfUse: {
                    title: 'Terms of use',
                    id: 'ID',
                    type: 'Type'
                },
                revokeConfirmTitle: 'Revoke credential?',
                revokeConfirmMessage: 'Are you sure you want to revoke this credential? This action cannot be undone.'
            },
            presentationDefinitionList: {
                title: 'Receive credential configurations'
            },
            presentationDefinitionFormSelectCredentials: {
                intro: {
                    title: {
                        add: 'Set up new presentation definition',
                        update: 'Update presentation definition'
                    },
                    description: 'As a verifier you can set up a preconfigured request with credentials and specific details you would like to receive.'
                },
                searchTitle: 'Search credentials',
                searchPlaceHolder: 'What would you like to receive?',
                selectedCredentialTypes: 'Requested credential types',
                noCredentialTypesSelected: 'No credentials selected',
                buttonStartConfiguration: 'Start configuration'
            },
            presentationDefinitionFormConfigureCredentials: {
                intro: {
                    title: 'Configure the credentials you would like to receive',
                    description: 'Configure here additional configuration / restrictions per credential.'
                },
                saveConfigurationTitle: 'How would you like to save this configuration?',
                technicalConfigurationTitle: 'Technical configuration',
                attributes: {
                    externalKey: {
                        label: 'Verifier template key',
                        placeHolder: 'The name of the configuration as used in the API called from your system'
                    },
                    name: {
                        label: 'Name',
                        placeHolder: 'A logical name of the configuration'
                    },
                    description: {
                        label: 'Description',
                        placeHolder: 'Describe your configuration in a few words so you can easily find it back later'
                    },
                    purpose: {
                        label: 'Purpose',
                        placeHolder: 'The text show to the user with the reason why your are asking for the credential(s)'
                    },
                    successRedirectUrl: {
                        label: 'Succes url',
                        placeHolder: 'The URL the user is navigated to after the credential(s) is/are successfully received'
                    },
                    errorRedirectUrl: {
                        label: 'Error url',
                        placeHolder: 'The URL the user is navigated to when something went wrong receiving the credential(s)'
                    },
                    clientUrl: {
                        label: 'Client url',
                        placeHolder: 'The URL of the relying party that will use the credential(s)'
                    }
                },
                titleAttribute: 'Title',
                titleAttributePlaceHolder: '',
                descriptionAttribute: 'Description',
                descriptionAttributePlaceHolder: '',
                buttonSaveConfiguration: 'Save configuration'
            },
            presentationDefinitionFormTestConfig: {
                intro: {
                    title: 'You configuration is ready to be tested.',
                    description: 'You can now test your configuration by using the links below.'
                },
                testWithWallet: {
                    title: 'Test with your wallet',
                    description: 'Test the configuration by using your own wallet. The wallet should contain the credentials that are requested in the configuration.',
                    walletUrl: {
                        label: 'Your wallet URL',
                        placeHolder: 'Provide the URL of your wallet to test the configuration.'
                    },
                    buttonTest: 'Test configuration',
                    successfullyReceived: 'Credential(s) successfully received'
                }
            },
            credentialIssuerDefinitionList: {
                intro: {
                    title: 'Credential Issuer definitions',
                    description: 'Credential templates are grouped together into a Issuer template. Configure common attributes and add Credential templates to the Issuer template.'
                }
            },
            credentialIssuerDefinitionForm: {
                intro: {
                    new: {
                        title: 'Create a new Credential Issuer definition',
                        description: 'Credential templates are grouped together into a Issuer template. Configure common attributes and add Credential templates to the Issuer template.'
                    },
                    edit: {
                        title: 'Edit the Credential Issuer definition',
                        description: 'Credential templates are grouped together into a Issuer template. Configure common attributes and add Credential templates to the Issuer template.'
                    }
                },
                buttonSaveConfiguration: 'Save template',
                saveConfigurationTitle: 'Credential issuer configuration',
                saveChangesDialog: {
                    title: 'Save changes',
                    message: 'Do you want to save the changes to the issuer template?'
                },
                languageTitle: 'Select the languages for which you want to provide specific translations',
                languagePlaceHolder: 'Select languages',
                attributes: {
                    externalKey: {
                        label: 'Technical configuration name',
                        placeHolder: 'The name of the configuration as used in the public configuration url'
                    },
                    name: {
                        label: 'Name',
                        placeHolder: 'A logical name of the configuration'
                    },
                    description: {
                        label: 'Description',
                        placeHolder: 'Describe your configuration in a few words so you can easily find it back later'
                    },
                    displayName: {
                        label: 'Display name',
                        placeHolder: 'The name as shown in on the cards of all credentials issued by this issuer template'
                    },
                    logo: {
                        label: 'Logo',
                        placeHolder: 'The logo of the issuer. This logo is shown on the cards of all credentials issued by this issuer template'
                    },
                    logoAlt: {
                        label: 'Logo alt text',
                        placeHolder: 'The alt text of the logo. This text is shown when the logo is not available and is used for visually disabled people to understand the logo.'
                    },
                    issuerUrl: {
                        label: 'The public url of this configuration'
                    }
                },
                template: {
                    title: 'Credential template',
                    selectDid: 'Select the issuer identifier'
                },
                trust: {
                    title: 'Trusted issuer',
                    registerIssuer: 'Register issuer',
                },
                status: {
                    title: 'Credential Status List',
                    selectStatusList: 'Select the credential status list'
                },
                issue: {
                    title: 'Issue a credential',
                    placeHolder: 'Enter attribute value',
                    walletUrl: {
                        label: 'Wallet endpoint',
                        placeHolder: 'The URL of wallet to which the credential must be issued'
                    }
                },
                qrcode: {
                    title: 'Scan the QR code below or click link to receive the credential'
                },
            },
            credentialIssuerCredentialDefinitionForm: {
                intro: {
                    new: {
                        title: 'Create a new Credential definition',
                        description: 'Configure the detail of the credential you want to issue.'
                    },
                    edit: {
                        title: 'Edit the Credential definition',
                        description: 'Configure the detail of the credential you want to issue.'
                    }
                },
                attributes: {
                    credentialConfigurationId: {
                        label: 'Credential Issue template key',
                        placeHolder: 'The name of the configuration as used in the API called from your system'
                    }
                },
                buttonSaveConfiguration: 'Save template',
                saveConfigurationTitle: 'Credential configuration',
                saveChangesDialog: {
                    title: 'Save changes',
                    message: 'Do you want to save the changes to the credential template?'
                },
                languageTitle: 'Select the languages for which you want to provide specific translations',
                languagePlaceHolder: 'Select languages',
            },
            credentialIssuerDisplay: {
                title: 'Attributes shown on the credentials card',
                description: 'The issuer attributes as shown on the credential card. Multiple languages can be configured. The language of the user is used to determine which translation is shown. If no translation is available the default language is shown.',
                defaultLanguageLabel: 'Default language',
                attributes: {
                    name: {
                        label: 'Name credential issuer',
                        placeHolder: 'The name of the issuer as shown on the credential card'
                    },
                    logoUri: {
                        label: 'Logo URL',
                        placeHolder: 'The URL of the logo of the issuer. This logo is shown on the cards of all credentials issued by this issuer template',
                    },
                    logoImage: {
                        label: 'Logo',
                        placeHolder: 'The logo of the issuer. This logo is shown on the cards of all credentials issued by this issuer template',
                    },
                    logoAlt: {
                        label: 'Logo alt text',
                        placeHolder: 'The alt text of the logo. This text is shown when the logo is not available and is used for visually disabled people to understand the logo.'
                    }
                }
            },
            credentialDisplay: {
                title: 'Attributes shown on the credentials card',
                description: 'The credential attributes as shown on the credential card. Attributes defined on credential level override attributes defined on issuer level. Multiple languages can be configured. The language of the user is used to determine which translation is shown. If no translation is available the default language is shown.',
                defaultLanguageLabel: 'Default language',
                attributes: {
                    name: {
                        label: 'Type of credential',
                        placeHolder: 'The description of the credential type as shown on the credential card'
                    },
                    backgroundImageUri: {
                        label: 'Background image URL',
                        placeHolder: 'The URL of the background as shown on the credential card.',
                    },
                    backgroundImageAltText: {
                        label: 'Background image alt text',
                        placeHolder: 'The alt text of the background image. This text is shown when the logo is not available and is used for visually disabled people to understand the logo.'
                    },
                    backgroundColor: {
                        label: 'Background color',
                    },
                    textColor: {
                        label: 'Text color',
                    }
                }
            },
            credentialIssuerCredentialDefinitionDisplay: {
                title: 'Credential type display attributes',
                defaultLanguageDescription: 'Default language',
                attributes: {
                    name: {
                        label: 'Name credential type',
                        placeHolder: 'The name of the credential type'
                    },
                    backgroundUrl: {
                        label: 'Background image uri',
                        placeHolder: 'The background image uri of the credential'
                    },
                    backgroundAltText: {
                        label: 'Background image alternative text',
                        placeHolder: 'The alternative text of the background image'
                    }
                }
            },
            historyList: {
                intro: {
                    title: 'History',
                    description: 'All historical events related to this wallet.'
                },
                date: 'Date',
                time: 'Time',
                event: 'Event',
                action: 'Action',
                user: 'User',
                party: 'Party',
                noHistory: 'No history available yet',
                eventType: {
                    CREDENTIAL: 'Credential',
                    PRESENTATION: 'Presentation'
                },
                actionType: {
                    OFFER_RECEIVED: 'Offer received',
                    OFFER_ACCEPTED: 'Offer accepted',
                    DELETED: 'Deleted'
                }
            },
            settings: {
                dids: {
                    title: 'Identifiers',
                    description: 'The list below contains all identifiers that are available in this wallet.',
                    didType: 'Type',
                    didId: 'ID',
                    createDid: 'Create new identifier'
                },
                did: {
                    create: {
                        title: 'Create a new identifier',
                        description: 'First choose the type of identifier',
                        header: 'Specify the identifier options'
                    },
                    setDefault: {
                        buttonLabel: 'Make default identifier',
                        confirmDialog: {
                            title: 'Set to default identifier',
                            body: 'Make this Identifier the default identifier for this wallet.<br/><br/>The public profile will be shown with this identifier. You might have to add the credentials to your public profile again.<br><br>Are you sure you want to set this identifier as default?'
                        },
                        updatedMessage: 'Identifier set to default'
                    },
                    attributes: {
                        didId: {
                            label: 'ID'
                        },
                        type: {
                            label: 'Type',
                            placeHolder: 'Select the type of identifier'
                        },
                        displayName: {
                            label: 'Display name',
                            placeHolder: 'The display name that is shown for this identifier'
                        },
                        ebsiEnvironment: {
                            label: 'Environment',
                            placeHolder: 'Select the EBSI environment'
                        },
                        keyId: {
                            label: 'ID',
                        },
                        keyAlias: {
                            label: 'Key alias',
                            placeHolder: 'The alias of this key'
                        },
                        keyPurposes: {
                            label: 'Purposes',
                            placeHolder: 'The purposes for this key'
                        },
                        serviceId: {
                            placeHolder: 'Enter the unique id of the service'
                        },
                        serviceType: {
                            placeHolder: 'The type of service'
                        },
                        serviceEndpoint: {
                            placeHolder: 'De URL to the service endpoint'
                        },
                        taoWalletAddress: {
                            label: 'TAO wallet address',
                            placeHolder: 'The URL of the TAO wallet'
                        },
                        domain: {
                            label: 'Domain',
                            placeHolder: 'The domain name for this web address, defaults to current address'
                        },
                        path: {
                            label: 'Path',
                            placeHolder: 'An optional path for this web address'
                        }
                    },
                    title: 'Identifier',
                    description: 'This identifier contains the following information',
                    keys: 'Keys',
                    services: 'Services'
                }
            },
            credentialStatusList: {
                title: 'Credential Status Lists',
                description: 'Configure the credential status frameworks',
                createCredentialStatusList: 'Create status configuration',
                noCredentialStatusList: 'No credential status list configured',
                attributes: {
                    name: {
                        label: 'Name',
                        placeHolder: ''
                    },
                    type: {
                        label: 'Type',
                    },
                    purpose: {
                        label: 'Purpose',
                    },
                    externalUrl: {
                        label: 'External URL',
                        placeHolder: ''
                    },
                }
            },
            pleaseCreateADidFirstView: {
                title: 'Please create an Identifier first',
                description: 'To be able to create credentials you need to create an Identifier first.',
                createLink: 'Create an Identifier'
            },
            publicProfileDetails: {
                title: 'Public profile',
                emptyCredentialListMessage: 'Add credentials to you public profile so other companies can find you',
                emptyServiceListMessage: 'Add services to you public profile so other companies can find your services',
                editContactInformation: 'Edit information',
                addNewCredential: 'Add credential',
                addNewService: 'Add service',
                description1: 'The credentials below are publicly visible.',
                description2: 'You can update and manage what information is shared at any time.',
                deleteCredentialConfirm: {
                    title: 'Delete credential from public profile?',
                    message: 'Are you sure you want to delete this credential from your public profile?<br/><br/>The Credential will not be deleted from your wallet but will no longer be publicly visible.'
                }
            },
            selectCredentialToAdd: {
                title: 'Add credential',
                description1: 'Select the credentials you want to add to your public profile.',
                description2: 'Once selected you can decide which specific details you want to share.'
            },
            walletProfile: {
                organizationName: {
                    label: 'Organization name',
                    placeHolder: 'Organization name'
                },
                apiKey: {
                    label: 'API key',
                    placeHolder: 'API key'
                },
                base64EncodedLogo: {
                    label: 'Logo',
                    placeHolder: 'Logo'
                },
                website: {
                    label: 'Website',
                    placeHolder: 'Website'
                },
                phoneNumber: {
                    label: 'Phone number',
                    placeHolder: 'Phone number'
                },
                email: {
                    label: 'Email',
                    placeHolder: 'Email'
                },
                addressStreet: {
                    label: 'Street',
                    placeHolder: 'Street'
                },
                addressPostalCode: {
                    label: 'Postal code',
                    placeHolder: 'Postal code'
                },
                addressCity: {
                    label: 'City',
                    placeHolder: 'City'
                },
                addressCountry: {
                    label: 'Country',
                    placeHolder: 'Country'
                },
                geoLocationLatitude: {
                    label: 'Latitude',
                    placeHolder: 'Latitude'
                },
                geoLocationLongitude: {
                    label: 'Longitude',
                    placeHolder: 'Longitude'
                },
            },
            trustedContactList: {
                title: 'Trusted contacts',
                intro: 'The list below shows the trusted contacts'
            },
            trustedContact: {
                title: 'Trusted contact',
                description: 'Add a new trusted contact',
                attributes: {
                    did: {
                        label: 'DID',
                        placeHolder: 'Enter the DID of the new contact'
                    },
                    name: {
                        label: 'Name',
                        placeHolder: 'Enter the name of the new contact'
                    }
                },
                create: 'Add trusted contact'
            },
            trustedContactDetails: {
                description: 'The information below is publicly visible for this contact.',
                emptyCredentialListMessage: 'This organization doesn\'t have a public profile',
                emptyServiceListMessage: 'This organization has no service endpoints configured',
                deleteContactConfirm: {
                    title: 'Delete trusted contact?',
                    message: 'Are you sure you want to delete this contact?'
                },
                delete: 'Delete contact'
            },
            inbox: {
                title: 'Inbox',
                intro: 'This contains all received requests and credentials'
            },
            invoice: {
                title: 'Create an invoice',
                description: 'Send an invoice to one of your trusted contacts',
                create: 'Send invoice',
                attributes: {
                    sender: {
                        label: 'Sender',
                        placeholder: 'Select a trusted contact'
                    },
                    receiver: {
                        label: 'Contact',
                        placeholder: 'Select a trusted contact'
                    },
                    transport: {
                        label: 'Transport',
                        placeholder: 'Select the transport type'
                    },
                    invoiceId: {
                        label: 'Invoice number'
                    },
                    invoiceDate: {
                        label: 'Invoice date',
                    },
                    dueDate: {
                        label: 'Due date',
                    },
                    currencyCode: {
                        label: 'EUR',
                    },
                    taxExclusiveAmount: {
                        label: 'Total amount excl. VAT'
                    },
                    taxAmount: {
                        label: 'VAT Amount'
                    },
                    taxInclusiveAmount: {
                        label: 'Total amount incl. VAT'
                    },
                    invoiceDocument: {
                        label: 'Upload invoice document'
                    }
                }
            },
            invoiceDetails: {
                title: 'Invoice details',
                description: 'See the details of the received invoice below'
            },
            searchOrganization: {
                title: 'Search organizations',
                intro: 'Search the FIDES BluePages for organizations to add as trusted contact',
                search: {
                    title: 'Search',
                    placeholder: 'Search on organization name, Chamber of Commerce, VAT or IBAN'
                },
                organization: 'Organization',
                action: 'Action',
                add: 'Add to wallet',
                create: 'Create contact manually'
            },
            trustedIssuerListList: {
                title: 'Trusted issuers',
                intro: 'The list of trusted issuers defines the issuers that are trusted by this wallet. The credentials in your wallet are checked against this list. <br/><br/>When the issuer is not in this trustlist the credential will be marked as untrusted.',
            },
            trustedIssuerListForm: {
                title: 'Trusted issuer',
                intro: 'Add or edit a trusted issuer. The identifiers you add hare the issuer identifiers that are trusted by this wallet. The credentials in your wallet are checked against this list. <br/><br/>When the issuer is not in this trustlist the credential will be marked as untrusted.',
                didListIntro: 'The Identifiers below are marked as trusted issuers for the credential type.',
                addDidButtonLabel: 'Add identifier',
                fields: {
                    credentialType: {
                        placeHolder: 'Select the credential type you want to trust'
                    },
                    displayName: {
                        placeHolder: 'Free format name for yourselves'
                    },
                    did: {
                        did: {
                            placeHolder: 'The Identifier (DID) of the issuer you wan to trust'
                        },
                        displayName: {
                            placeHolder: 'Free format name for yourselves'
                        },
                        deleteIcon: {
                            tooltip: 'Delete identifier'
                        }
                    }
                }
            },
            signupStep1: {
                title: 'Set up your organisation wallet',
                intro: 'You can setup your organisation in just a few steps. ',
                connectExisting: {
                    title: 'Connect an existing organisation',
                    intro: 'Connect to an existing organisation using your personal wallet, or by providing identification. ',
                    actionButtonLabel: 'Connect organisation'
                },
                connectNewCompany: {
                    title: 'Establish a new company',
                    intro: 'Establish a new company within a day. Verified by the notary, KvK, Tax administration and set up a bank account, so you can start doing business asap',
                    actionButtonLabel: 'Start up company'
                }
            },
            signupExistingCompany: {
                title: 'Connect an existing organisation',
                intro: 'Connect your wallet to an existing organisation. ',
                step1: {
                    title: 'Retrieve your Legal Person ID from the Chamber of Commerce',
                    intro: 'Be be able to use the Organizational Wallet as a company we need to determine the identity of your company. You can do this by providing your Legal Person ID (LPID) of the Chamber of Commerce.',
                    actionButtonLabel: 'Retrieve LPID from Chamber of Commerce'
                },
                companyProfile: {
                    title: 'Complete your company profile',
                    intro: 'Add the details of your company.',
                    body: '',
                    actionButtonLabel: 'Next'
                },
                completeSignup: {
                    title: 'Finish the wallet setup',
                    intro: 'Complete the wallet setup and start using the Organizational Wallet.',
                    body: '',
                    actionButtonLabel: 'Complete setup'
                }
            },
            dashboard: {
                title: 'Welcome to your organisation wallet',
                subtitle: 'An organization wallet provides a secure and decentralized way to manage and verify digital credentials. \n' +
                    'This system allows organizations and individuals to store, share, and validate credentials in a reliable and privacy-friendly manner.',
                issuer: {
                    title: 'Issuer',
                    description: 'Create digital credentials, like certificates or identity proofs, for other persons, organizations or products.'
                },
                holder: {
                    title: 'Holder',
                    description: 'Store all your organizations credentials in one place. Keep them safe, organized, and ready to share when needed.'
                },
                verifier: {
                    title: 'Verifier',
                    description: 'Securely receive and validate credentials of persons, organizations and products, ensuring authenticity and trust.'
                },
                messages: {
                    title: 'Messages',
                    description: 'Here you can find all received and sent items. Use your inbox to accept issued credentials, or receive and view credentials, like invoices.'
                },
                completeSignup: {
                    title: 'Complete profile to start using the wallet',
                    description: 'To use all the functionality of this wallet your organisation needs to be verified by the Chamber of Commerce.',
                    actionLinkLabel: 'Verify with the Chamber of Commerce'
                }
            }
        }
    } as AppTexts;
};

const getAppTextsNl = (): AppTexts => {
    return {
        menu: {
            dashboard: 'Overzicht',
            wallet: 'Wallet',
            walletOverview: 'Wallet',
            settings: 'Instellingen',
            newCredential: "Catalogus",
            receive: "Verifier templates",
            history: 'Audit trail',
            advanced: 'Instellingen',
            holder: 'Houder',
            logoff: 'Uitloggen',
            issuer: 'Uitgever',
            issuerTemplates: 'Uitgifte templates',
            issuerTrust: 'Trust anchor',
            identifiers: 'Identificaties',
            generic: 'Algemeen',
            verifier: 'Verifieerder',
            verifierTemplate: 'Templates',
            trustAchor: 'Trust achor',
            catalogConfig: 'Catalogus',
            apiDocs: 'API documentatie',
            apiKey: 'API key',
            publicProfile: 'Publiek profiel',
            contacts: 'Contacten',
            trustedContacts: 'Contacten',
            applications: 'Toepassingen',
            inbox: 'Inbox',
            trustedIssuerList: 'Vertrouwde uitgevers',
            miscellaneous: 'Gemengd'
        },
        generic: {
            yes: 'Ja',
            no: 'Nee',
            ok: 'OK',
            cancel: 'Annuleren',
            add: 'Toevoegen',
            save: 'Opslaan',
            edit: 'Wijzigen',
            delete: 'Verwijderen',
            back: 'Terug',
            loading: 'Laden...',
            accept: 'Accepteren',
            reject: 'Afwijzen',
            search: 'Zoeken',
            startSearching: 'Start met zoeken...',
            removeCredential: 'Credential verwijderen',
            revokeCredential: 'Credential intrekken',
            copiedToClipboard: 'Gekopieerd naar klembord',
        },
        error: {
            retrievingData: "Er is is mis gegaan bij het ophalen van de data. Probeer het later nog een keer.",
            errorCodes: {
                "ERR-1": 'E-mail adres is niet toegestaan. Gebruik uw zakelijke e-mail adres.'
            },
            inputErrors: {
                requiredField: 'Dit veld is verplicht.',
                noSpacesAllowed: 'Geen spaties toegestaan',
                invalidEmail: 'Ongeldige e-mail adres',
                invalidUrl: 'Ongeldige URL'
            },
        },
        fields: {
            credentialStatus: {
                VALID: 'Geldig',
                INVALID: 'Ongeldig',
                EXPIRED: 'Verlopen',
                UNCHECKED: 'Ongecontroleerd',
            },
            credential: {
                single: {
                    typeDescription: 'Credential',
                },
                plural: {
                    typeDescription: 'Credentials'
                }
            },
            did: {
                single: {
                    typeDescription: 'ID'
                },
                plural: {
                    typeDescription: 'ID\'s'
                }
            },
            didService: {
                single: {
                    typeDescription: 'Service',
                    serviceId: 'ID',
                    serviceType: 'Service type',
                    serviceEndpoint: 'Service endpoint'
                },
                plural: {
                    typeDescription: 'Services'
                }
            },
            trustedIssuerList: {
                single: {
                    typeDescription: 'Vertrouwde uitgever',
                    credentialType: 'Credential type',
                    displayName: 'Naam',
                },
                plural: {
                    typeDescription: 'Vertrouwde uitgevers',
                }
            },
            trustedIssuerListDid: {
                single: {
                    typeDescription: 'Vertrouwde uitgever Identificatie',
                    did: 'Identificatie',
                    displayName: 'Naam',
                },
                plural: {
                    typeDescription: 'Vertrouwde uitgever Identificaties',
                }
            }
        },

        screens: {
            header: {
                welcomeTitle: 'Hoi {{firstName}}',
                welcomeSubTitle: 'Welkom bij de organisatie wallet van {{organizationName}}.',
            },
            apiKey: {
                title: 'API Key',
                description: 'De API Key is vereist voor het authenticeren bij het aanroepen van de API.',
                currentApiKey: 'Huidige API key',
                showApiDocumentation: 'Toon API documentatie',
                copiedToClipboard: 'API key is gekopieerd naar klembord'
            },
            credentialCard: {
                requestCredential: 'Credential aanvragen'
            },
            credentialTypeCard: {
                createNewTemplate: 'Nieuw Credential template'
            },
            credentialList: {
                title: 'Wallet overzicht',
                emptyListMessage: 'Begin met het vullen van uw wallet door enkele credentials aan te vragen.',
                searchNoCredentialsFound: 'Geen credentials gevonden',
                requestNewCredential: 'Nieuwe credential aanvragen',
                searchTitle: 'Zoeken naar credentials',
                myCredentials: 'Mijn credentials',
                issuanceDate: 'Uitgiftedatum',
                issuer: 'Uitgever',
                credentialType: 'Type'
            },
            credentialDetail: {
                title: 'Credential details',
                issuanceDate: 'Uitgiftedatum',
                issuer: 'Uitgever',
                credentialType: 'Type',
                credentialTypeDescription: 'Omschrijving',
                termsOfUse: {
                    title: 'Gebruiksvoorwaarden',
                    id: 'ID',
                    type: 'Type'
                }
            },
            credentialTypeList: {
                title: 'Nieuwe credential aanvragen',
                intro: 'Kies een van de credentials van de vooraf geconfigureerde uitgevers',
                searchTitle: 'Zoek credentials bij uw voorkeursuitgevers',
                requestCredential: 'Vraag een credential aan'
            },
            credentialDetails: {
                deleteConfirmTitle: 'Credential verwijderen?',
                deleteConfirmMessage: 'Weet u zeker dat u deze credential wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.'
            },
            issueCredentials: {
                title: 'Credential ontvangen',
                intro: 'De volgende credentials zullen worden uitgegeven door {{issuer}}.',
                issueTo: 'Credential uitgeven aan'
            },
            verifyCredentials: {
                title: 'Deel credential(s)',
                intro: 'De volgende credentials worden gevraagd door {{verifierHost}} met het volgende doel:',
                noMatchingCredentials: 'Je hebt geen credentials in je wallet die voldoen aan het presentatie verzoek.',
                successSummary: 'Succesvol gedeeld',
                successDetail: 'Je hebt met succes de gevraagde credentials gedeeld.'

            },
            issuedCredentialList: {
                title: 'Uitgegeven Credentials',
                emptyListMessage: 'Er zijn nog geen Credentials uitgegeven.',
                searchNoCredentialsFound: 'Geen uitgegeven credentials gevonden',
                requestNewCredential: 'Nieuwe credential aanvragen',
                searchTitle: 'Zoeken naar uitgegeven credentials',
                issuanceDate: 'Uitgiftedatum',
                issuer: 'Uitgever',
                credentialType: 'Type',
                status: 'Status'
            },
            issuedCredentialDetail: {
                title: 'Uitgegeven Credential details',
                issuanceDate: 'Uitgiftedatum',
                issuer: 'Uitgever',
                credentialType: 'Type',
                credentialTypeDescription: 'Omschrijving',
                termsOfUse: {
                    title: 'Gebruiksvoorwaarden',
                    id: 'ID',
                    type: 'Type'
                },
                revokeConfirmTitle: 'Credential intrekken?',
                revokeConfirmMessage: 'Weet u zeker dat u deze credential wilt intrekken? Deze actie kan niet ongedaan worden gemaakt.'
            },
            presentationDefinitionList: {
                title: 'Credential ontvangst configuraties'
            },
            presentationDefinitionFormSelectCredentials: {
                intro: {
                    title: {
                        add: 'Maak een nieuwe presentatie definitie aan',
                        update: 'Wijzig presentatie definitie',
                    },
                    description: 'Configureer hier welke specifieke credentials u wilt ontvangen.'
                },
                searchTitle: 'Zoek credentials die u wilt ontvangen',
                searchPlaceHolder: 'Wat wil je ontvangen?',
                selectedCredentialTypes: 'Gevraagde credential types',
                noCredentialTypesSelected: 'Nog geen credentials geselecteerd',
                buttonStartConfiguration: 'Start configuratie'
            },
            presentationDefinitionFormConfigureCredentials: {
                intro: {
                    title: 'Configureer de credential die je wilt ontvangen',
                    description: 'Configureer hier aanvullende configuratie / restricties per credential.'
                },
                saveConfigurationTitle: 'Hoe wil je deze configuratie opslaan?',
                technicalConfigurationTitle: 'Technische configuratie',
                attributes: {
                    externalKey: {
                        label: 'Verifier template key',
                        placeHolder: 'De naam van de configuratie zoals gebruikt in de Organization Wallet API aangeroepen vanuit jouw systeem'
                    },
                    name: {
                        label: 'Naam',
                        placeHolder: 'Een logische naam voor de configuratie'
                    },
                    description: {
                        label: 'Beschrijving',
                        placeHolder: 'Beschrijf je configuratie in een paar woorden zodat je deze later gemakkelijk kunt terugvinden'
                    },
                    purpose: {
                        label: 'Doel',
                        placeHolder: 'De tekst die aan de gebruiker wordt getoond met de reden waarom je om de credential(s) vraagt'
                    },
                    successRedirectUrl: {
                        label: 'Succes url',
                        placeHolder: 'De URL waar de gebruiker naar toe gestuurd wordt nadat de credentials succesvol zijn ontvangen'
                    },
                    errorRedirectUrl: {
                        label: 'Error url',
                        placeHolder: 'De URL waar de gebruiker naar toe gestuurd wordt als er iets fout is gegaan bij het ontvangen van de credentials'
                    },
                    clientUrl: {
                        label: 'Client url',
                        placeHolder: 'De URL van de verifierende website die de credential(s) wil ontvangen'
                    }

                },
                titleAttribute: 'Titel',
                titleAttributePlaceHolder: '',
                descriptionAttribute: 'Beschrijving',
                descriptionAttributePlaceHolder: '',
                buttonSaveConfiguration: 'Configuratie opslaan'
            },
            presentationDefinitionFormTestConfig: {
                intro: {
                    title: 'De configuratie is klaar om getest te worden.',
                    description: 'De configuratie kan nu getest worden door gebruik te maken van de onderstaande links.'
                },
                testWithWallet: {
                    title: 'Test met wallet',
                    description: 'Test de configuration met je eigen wallet. De wallet moet de credentials bevatten die in de configuratie worden gevraagd.',
                    walletUrl: {
                        label: 'Wallet URL',
                        placeHolder: 'Geef de URL van je wallet op om de configuratie te testen.'
                    },
                    buttonTest: 'Test configuratie',
                    successfullyReceived: 'De credential(s) zijn succesvol ontvangen'
                }
            },
            credentialIssuerDefinitionList: {
                intro: {
                    title: 'Credential uitgever definities',
                    description: 'Credential templates zijn gegroepeerd binnen een Uitgever definitie. Configureer de gemeenschappelijke attributen en voeg Credential templates toe.'
                }

            },
            credentialIssuerDefinitionForm: {
                intro: {
                    new: {
                        title: 'Maak een nieuwe Credential Issuer configuratie',
                        description: 'Credential templates zijn gegroepeerd binnen een Uitgever configuratie. Configureer de gemeenschappelijke attributen en voeg Credential templates toe.'
                    },
                    edit: {
                        title: 'Wijzig de Credential Issuer template',
                        description: 'Credential templates zijn gegroepeerd binnen een Uitgever configuratie. Configureer de gemeenschappelijke attributen en voeg Credential templates toe.'
                    }
                },
                buttonSaveConfiguration: 'Configuratie opslaan',
                saveConfigurationTitle: 'Credential uitgever configuratie',
                saveChangesDialog: {
                    title: 'Wijzigingen opslaan',
                    message: 'Wil je de wijzigingen in de Uitgever configuratie opslaan?'
                },
                languageTitle: 'Selecteer de talen waarvoor je specifieke vertalingen wilt opgeven',
                languagePlaceHolder: 'Talen selecteren',
                attributes: {
                    externalKey: {
                        label: 'Technische configuratienaam',
                        placeHolder: 'De naam van de configuratie zoals gebruikt in de openbare configuratie-URL'
                    },
                    name: {
                        label: 'Naam',
                        placeHolder: 'Een logische naam van de configuratie'
                    },
                    description: {
                        label: 'Beschrijving',
                        placeHolder: 'Beschrijf uw configuratie in enkele woorden, zodat u deze later gemakkelijk kunt terugvinden'
                    },
                    displayName: {
                        label: 'Weergavenaam',
                        placeHolder: 'De naam zoals weergegeven op de kaarten van alle Credentials die door met deze uitgever configuratie zijn uitgegeven'
                    },
                    logo: {
                        label: 'Logo',
                        placeHolder: 'Het logo van de uitgever. Dit logo wordt getoond op de kaarten van alle Credentials die door met deze uitgever configuratie zijn uitgegeven'
                    },
                    logoAlt: {
                        label: 'Logo alt tekst',
                        placeHolder: 'De alt-tekst van het logo. Deze tekst wordt getoond wanneer het logo niet beschikbaar is en wordt gebruikt voor visueel gehandicapte mensen om het logo te begrijpen.'
                    },
                    issuerUrl: {
                        label: 'De openbare URL van deze configuratie'
                    }
                },
                template: {
                    title: 'Credential template',
                    selectDid: 'Selecteer de issuer identificatie'
                },
                trust: {
                    title: 'Vertrouwde uitgever',
                    registerIssuer: 'Registreer uitgever',
                },
                issue: {
                    title: 'Geef dit credential uit',
                    placeHolder: 'Vul een attribuut waarde in',
                    walletUrl: {
                        label: 'Wallet endpoint',
                        placeHolder: 'De URL van de wallet waar naar uitgegeven moet worden'
                    }
                },
                qrcode: {
                    title: 'Scan de onderstaande QR code of klik link om credential te ontvangen'
                },
            },
            credentialIssuerDisplay: {
                title: 'Attributen weergegeven op de Credential kaart',
                description: 'De issuer attributen zoals weergegeven op de credential kaart. Er kunnen meerdere talen worden geconfigureerd. De taal van de gebruiker wordt gebruikt om te bepalen welke vertaling wordt getoond. Als er geen vertaling beschikbaar is, wordt de standaardtaal getoond.',
                defaultLanguageLabel: 'Standaard taal',
                attributes: {
                    name: {
                        label: 'Naam credential uitgever',
                        placeHolder: 'De naam van de uitgever zoals weergegeven op de credential kaart'
                    },
                    logoUri: {
                        label: 'Logo URL',
                        placeHolder: 'De URL van het logo van de uitgever. Dit logo wordt getoond op de kaarten van alle credentials uitgegeven door deze uitgever configuratie',
                    },
                    logoImage: {
                        label: 'Logo',
                        placeHolder: 'Het logo van de uitgever. Dit logo wordt getoond op de kaarten van alle credentials uitgegeven door deze uitgever configuratie',
                    },
                    logoAlt: {
                        label: 'Logo alt tekst',
                        placeHolder: 'De alt tekst van het logo. Deze tekst wordt weergegeven wanneer het logo niet beschikbaar is en wordt gebruikt voor visueel gehandicapten om het logo te begrijpen.'
                    }
                }
            },
            credentialIssuerCredentialDefinitionDisplay: {
                title: 'Credential type weergave attributen voor {{language}} taal',
                defaultLanguageDescription: 'Default taal',
                attributes: {
                    name: {
                        label: 'Naam credential type',
                        placeHolder: 'De naam van het credential type'
                    },
                    backgroundUrl: {
                        label: 'Achtergrond plaatje uri',
                        placeHolder: 'De uri van het achtergrond plaatje van het credential'
                    },
                    backgroundAltText: {
                        label: 'Achtergrond plaatje alternatieve text',
                        placeHolder: 'De alternatieve text voor het achtergrond plaatje'
                    }
                }
            },
            historyList: {
                intro: {
                    title: 'Historie',
                    description: 'Alle historische gebeurtenissen gerelateerd aan de wallet.'
                },
                date: 'Datum',
                time: 'Tijd',
                event: 'Gebeurtenis',
                action: 'Actie',
                user: 'Gebruiker',
                party: 'Partij',
                noHistory: 'Nog geen historie beschikbaar',
                eventType: {
                    CREDENTIAL: 'Credential',
                    PRESENTATION: 'Presentatie'
                },
                actionType: {
                    OFFER_RECEIVED: 'Verzoek ontvangen',
                    OFFER_ACCEPTED: 'Verzoek geaccepteerd',
                    DELETED: 'Verwijderd'
                }
            },
            settings: {
                dids: {
                    title: 'Identificaties',
                    description: 'De onderstaande lijst bevat alle identificaties die beschikbaar zijn in deze wallet.',
                    didType: 'Type',
                    didId: 'ID',
                    createDid: 'Nieuwe identificatie aanmaken'
                },
                did: {
                    create: {
                        title: 'Een nieuwe identificatie aanmaken',
                        description: 'Kies eerst het type identificatie',
                        header: 'Vul de identificatie opties'
                    },
                    setDefault: {
                        buttonLabel: 'Maak standaard identificatie',
                        confirmDialog: {
                            title: 'Stel in als standaard identificatie',
                            body: 'Maak deze identificatie de standaard identificatie voor deze wallet. Het publieke profiel wordt weergegeven met deze identificatie. Mogelijk moet je de credentials opnieuw aan je publieke profiel toevoegen.'
                        },
                        updatedMessage: 'De identificatie is nu de standaard identificatie.'
                    },
                    attributes: {
                        didId: {
                            label: 'ID'
                        },
                        type: {
                            label: 'Type',
                            placeHolder: 'Selecteer het type identificatie'
                        },
                        displayName: {
                            label: 'Scherm naam',
                            placeHolder: 'De naam die op het scherm getoond wordt voor deze identificatie'
                        },
                        ebsiEnvironment: {
                            label: 'Omgeving',
                            placeHolder: 'Selecteer de EBSI omgeving'
                        },
                        keyId: {
                            label: 'ID',
                        },
                        keyAlias: {
                            label: 'Sleutel alias',
                            placeHolder: 'De alias van deze sleutel'
                        },
                        keyPurposes: {
                            label: 'Purposes',
                            placeHolder: 'De doelen voor deze sleutel'
                        },
                        serviceId: {
                            placeHolder: 'Vul de ID van de service in'
                        },
                        serviceType: {
                            placeHolder: 'Het soort service endpoint'
                        },
                        serviceEndpoint: {
                            placeHolder: 'De URL naar de service'
                        },
                        taoWalletAddress: {
                            label: 'TAO wallet adres',
                            placeHolder: 'De URL van de TAO'
                        },
                        domain: {
                            label: 'Domein',
                            placeHolder: 'De domein naam voor dit web adres, standaard is dit het huidige adres'
                        },
                        path: {
                            label: 'Pad',
                            placeHolder: 'Een optioneel pad voor dit web adres'
                        }
                    },
                    title: 'Identificatie',
                    description: 'Deze identificatie bevat de volgende informatie',
                    keys: 'Sleutels',
                    services: 'Services'
                }
            },
            credentialStatusList: {
                title: 'Credential Status List',
                description: 'Configureer de credential status list',
                createCredentialStatusList: 'Aanmaken status configuration',
                noCredentialStatusList: 'Geen credential status geconfigureerd',
                attributes: {
                    name: {
                        label: 'Naam',
                        placeHolder: ''
                    },
                    type: {
                        label: 'Type',
                    },
                    purpose: {
                        label: 'Doel',
                    },
                    externalUrl: {
                        label: 'Externe URL',
                        placeHolder: ''
                    },
                }
            },
            pleaseCreateADidFirstView: {
                title: 'Nog geen identificatie sleutel aangemaakt',
                description: 'Om credentials aan te kunnen maken is een identificatiesleutel nodig. Deze sleutel dient eerst aangemaakt te worden voordat je een Uitgifte template kan maken.',
                createLink: 'Maak een identificatie sleutel'
            },
            publicProfileDetails: {
                title: 'Publiek profiel',
                emptyCredentialListMessage: 'Voeg credentials aan je publieke profiel toe zodat andere bedrijven je kunnen vinden',
                emptyServiceListMessage: 'Voeg services aan je publieke profiel toe zodat andere bedrijven je kunnen services kunnen vinden',
                editContactInformation: 'Wijzig informatie',
                addNewCredential: 'Voeg credential toe',
                addNewService: 'Voeg service toe',
                description1: 'De credentials hieronder zijn publiek zichtbaar.',
                description2: 'Je kunt ze op ieder moment wijzigen en beheren welke informatie gedeeld wordt.',
                deleteCredentialConfirm: {
                    title: 'Verwijder credential van publiek profiel?',
                    message: 'Weet je zeker dat je dit credential van je publieke profiel wilt verwijderen?<br/><br/>Het credential wordt niet uit je wallet verwijderd, maar zal niet langer publiek zichtbaar zijn.'
                }
            },
            selectCredentialToAdd: {
                title: 'Voeg credential toe',
                description1: 'Selecteer het credential dat je wilt toevoegen aan je publieke profiel.',
                description2: 'Zodra je het geselecteerd hebt, kun je beslissen welke specifieke details je wilt delen.'
            },
            walletProfile: {
                organizationName: {placeHolder: 'Organisatie naam', label: 'Organisatie naam'},
                apiKey: {placeHolder: 'API key', label: 'API key'},
                base64EncodedLogo: {placeHolder: 'Logo', label: 'Logo'},
                website: {placeHolder: 'Website', label: 'Website'},
                phoneNumber: {placeHolder: 'Telefoonnummer', label: 'Telefoonnummer'},
                email: {placeHolder: 'Email', label: 'Email'},
                addressStreet: {placeHolder: 'Straat', label: 'Straat'},
                addressPostalCode: {placeHolder: 'Postcode', label: 'Postcode'},
                addressCity: {placeHolder: 'Plaats', label: 'Plaats'},
                addressCountry: {placeHolder: 'Land', label: 'Land'},
                geoLocationLatitude: {placeHolder: 'Latitude', label: 'Latitude'},
                geoLocationLongitude: {placeHolder: 'Longitude', label: 'Longitude'},
            },
            trustedContactList: {
                title: 'Vertrouwde contacten',
                intro: 'Hier worden de vertrouwde contacten getoond'
            },
            trustedContact: {
                title: 'Vertrouwd contact',
                description: 'Voeg een nieuw vertrouwd contact toe',
                attributes: {
                    did: {
                        label: 'DID',
                        placeHolder: 'De DID van het nieuwe contact'
                    },
                    name: {
                        label: 'Naam',
                        placeHolder: 'De naam van het nieuwe contact'
                    }
                },
                create: 'Voeg vertrouwd contact toe'
            },
            trustedContactDetails: {
                description: 'De informatie hieronder is publiek zichtbaar voor dit contact.',
                emptyCredentialListMessage: 'Deze organizatie heeft geen publiek profiel',
                emptyServiceListMessage: 'Deze organizatie heeft geen service endpoints geconfigureerd',
                deleteContactConfirm: {
                    title: 'Verwijder dit vertrouwde contact?',
                    message: 'Weet je zeker dat je dit contact wilt verwijderen?'
                },
                delete: 'Verwijder contact'
            },
            inbox: {
                title: 'Inbox',
                intro: 'Dit bevat alle ontvangen verzoeken en credentials'
            },
            invoice: {
                title: 'Maak een factuur aan',
                description: 'Stuur een factuur naar een van je vertrouwde contacten',
                create: 'Stuur factuur',
                attributes: {
                    sender: {
                        label: 'Afzender',
                        placeholder: 'Selecteer een afzender'
                    },
                    receiver: {
                        label: 'Contact',
                        placeholder: 'Selecteen een vertrouwd contact'
                    },
                    transport: {
                        label: 'Transport',
                        placeholder: 'Selecteer het transport type'
                    },
                    invoiceId: {
                        label: 'Factuurnummer'
                    },
                    invoiceDate: {
                        label: 'Factuur datum',
                    },
                    dueDate: {
                        label: 'Betaal datum',
                    },
                    currencyCode: {
                        label: 'EUR',
                    },
                    taxExclusiveAmount: {
                        label: 'Totaal bedrag excl. BTW'
                    },
                    taxAmount: {
                        label: 'BTW'
                    },
                    taxInclusiveAmount: {
                        label: 'Total bedrag incl. BTW'
                    },
                    invoiceDocument: {
                        label: 'Factuur document'
                    }
                }
            },
            invoiceDetails: {
                title: 'Factuur details',
                description: 'De details van de ontvangen factuur staan hieronder'
            },
            searchOrganization: {
                title: 'Zoek organisaties',
                intro: 'Zoek de FIDES Blue Pages voor organisaties om toe te voegen als vertrouwd contact',
                search: {
                    title: 'Zoek',
                    placeholder: 'Zoek op organisatie naam, KVK nummer, BTW of IBAN'
                },
                organization: 'Organization',
                action: 'Action',
                add: 'Add to wallet',
                create: 'Create new trusted contact'
            },
            trustedIssuerListList: {
                title: 'Vertrouwde uitgevers',
                intro: 'De lijst met vertrouwde uitgevers definieert de uitgevers die door deze wallet worden vertrouwd. De credentials in de wallet worden gecontroleerd aan de hand van deze lijst. <br/><br/>Wanneer de uitgever niet in deze vertrouwenslijst staat, wordt het credential gemarkeerd als niet-vertrouwd.',
            },
            trustedIssuerListForm: {
                title: 'Vertrouwde uitgever',
                intro: 'Voeg een vertrouwde uitgever toe of bewerk een bestaande. De identifiers die je hier toevoegt zijn de identifiers van uitgevers die door deze wallet worden vertrouwd. De credentials in je wallet worden gecontroleerd aan de hand van deze lijst. <br/><br/>Wanneer de uitgever niet in deze vertrouwenslijst staat, wordt het credential gemarkeerd als niet-vertrouwd.',
                didListIntro: 'De onderstaande identificaties (DIDS) zijn gemarkeerd als vertrouwde uitgevers voor het credential type.',
                addDidButtonLabel: 'Voeg identifier toe',
                fields: {
                    credentialType: {
                        placeHolder: 'Selecteer het credential type dat je wilt vertrouwen'
                    },
                    displayName: {
                        placeHolder: 'Vrije tekstnaam voor eigen gebruik'
                    },
                    did: {
                        did: {
                            placeHolder: 'De Identifier (DID) van de uitgever die je wilt vertrouwen'
                        },
                        displayName: {
                            placeHolder: 'Vrije tekstnaam voor eigen gebruik'
                        }
                    }
                }
            },
            dashboard: {
                title: 'Organisatie Wallet',
                subtitle: 'Een veilige en eenvoudige manier om digitale credentials te beheren. Gebruik het om belangrijke digitale documenten op een fraudebestendige en privacy vriendelijke manier uit te geven, te beheren en te verifieren.',
                issuer: {
                    title: 'Uitgever',
                    description: 'Maak digital credentials aan, zoals certificaten of identiteitsbewijzen, voor personen, organisaties of producten.'
                },
                holder: {
                    title: 'Houder',
                    description: 'Bewaar al je organisatie credentials op een centrale plek. Houd deze veilig, georganiseerd en klaar om te delen zodra dit nodig is.'
                },
                verifier: {
                    title: 'Verifieerder',
                    description: 'Ontvang en valideer op een veilige manier de credentials van personen, organisaties en producten, zodat authenticiteit en vertrouwen gewaarborgd zijn.'
                },
                messages: {
                    title: 'Berichten',
                    description: 'Hier vindt je alle ontvangen en verzonden items. Gebruik de inbox om uitgegeven credentials te accepteren of credentials, zoals facturen, te ontvangen en te bekijken.'
                },
                completeSignup: {
                    title: 'Voltooi profiel om de wallet te gebruiken',
                    description: 'Om alle functionaliteiten van deze wallet te gebruiken, moet je organisatie worden geverifieerd door de Kamer van Koophandel.',
                    actionLinkLabel: 'Verifieer met de Kamer van Koophandel'
                }

            }
        }
    } as AppTexts;
};

const flatten: (object: any, prefix?: string) => any = (object, prefix = '') =>
    Object.keys(object).reduce(
        (prev, element) =>
            object[element] &&
            typeof object[element] === 'object' &&
            !Array.isArray(object[element])
                ? {...prev, ...flatten(object[element], `${prefix}${element}.`)}
                : {...prev, ...{[`${prefix}${element}`]: object[element]}},
        {},
    );

export const getTranslations = (language: string): {} => {
    const appTexts = language.startsWith('nl') ? getAppTextsNl() : getAppTextsEn();
    return flatten(appTexts, '');
}
