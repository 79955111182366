import React from 'react';
import { IconProps } from './IconProps';


export const Bank: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
          <path d="M3 13H6V21H4C3.73478 21 3.48043 21.1054 3.29289 21.2929C3.10536 21.4804 3 21.7348 3 22C3 22.2652 3.10536 22.5196 3.29289 22.7071C3.48043 22.8946 3.73478 23 4 23H28C28.2652 23 28.5196 22.8946 28.7071 22.7071C28.8946 22.5196 29 22.2652 29 22C29 21.7348 28.8946 21.4804 28.7071 21.2929C28.5196 21.1054 28.2652 21 28 21H26V13H29C29.2176 12.9998 29.4292 12.9286 29.6026 12.7973C29.7761 12.6659 29.902 12.4816 29.9612 12.2722C30.0205 12.0629 30.0098 11.8399 29.9308 11.6372C29.8519 11.4344 29.709 11.2629 29.5238 11.1488L16.5238 3.14875C16.3662 3.0519 16.1849 3.00063 16 3.00063C15.8151 3.00063 15.6338 3.0519 15.4762 3.14875L2.47625 11.1488C2.29103 11.2629 2.14811 11.4344 2.06916 11.6372C1.99021 11.8399 1.97955 12.0629 2.03878 12.2722C2.09801 12.4816 2.22391 12.6659 2.39738 12.7973C2.57085 12.9286 2.78242 12.9998 3 13ZM8 13H12V21H8V13ZM18 13V21H14V13H18ZM24 21H20V13H24V21ZM16 5.17375L25.4675 11H6.5325L16 5.17375ZM31 26C31 26.2652 30.8946 26.5196 30.7071 26.7071C30.5196 26.8946 30.2652 27 30 27H2C1.73478 27 1.48043 26.8946 1.29289 26.7071C1.10536 26.5196 1 26.2652 1 26C1 25.7348 1.10536 25.4804 1.29289 25.2929C1.48043 25.1054 1.73478 25 2 25H30C30.2652 25 30.5196 25.1054 30.7071 25.2929C30.8946 25.4804 31 25.7348 31 26Z" fill="#343330"/>
        </svg>
    )
};
