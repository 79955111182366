import React, { HTMLInputTypeAttribute } from 'react';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types';
import { FormTextInput } from './FormTextInput';


export interface FormTextInputWithLabelProps {
    label: string;
    value?: string | undefined;
    onChangeValue?: (value: string | undefined) => void;
    placeHolder?: string | undefined;
    className?: string | undefined;
    multiline?: boolean | undefined;
    inputType?: HTMLInputTypeAttribute | undefined;
    footer?: React.ReactNode | undefined;
    postElement?: React.ReactNode | undefined;
    formHook: UseFormReturn<any, any, FieldValues | undefined>;
    controlName?: string | undefined;
    rules?: Omit<RegisterOptions<FieldValues, FieldPath<FieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

export const FormTextInputWithLabel: React.FC<FormTextInputWithLabelProps> = (props) => {

    const {multiline = false} = props;
    const {inputType = 'text'} = props;

    return (
        <div className={props.className} style={{border: 'none', borderRadius: 16, paddingLeft: 20, paddingRight: 20, paddingTop: 18, paddingBottom: 14, backgroundColor: '#ffffff'}}>
            <div className="text-xs pb-1 pl-2" style={{color: 'rgba(28, 28, 28, 0.40)'}}>{props.label}</div>
            <div className="flex">
                <FormTextInput className="w-full" value={props.value} style={{border: 'none'}}
                               onChangeValue={(value) => props.onChangeValue?.(value)}
                               inputType={inputType}
                               multiline={multiline}
                               placeHolder={props.placeHolder}
                               formHook={props.formHook}
                               controlName={props.controlName}
                               rules={props.rules}
                />
                {props.postElement && (
                    <>{props.postElement}</>
                )}
            </div>
            {props.footer && (
                <>{props.footer}</>
            )}
        </div>
    );
};

