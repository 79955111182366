import React, { useState } from 'react';
import styled from 'styled-components';
import { Bank, Grid, Inbox, InvoiceIcon, Key, Logo, PublicProfile, Settings, SlidersHorizontal, Users, Vault, Wallet } from '../atoms';

import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../state';
import { useTranslation } from 'react-i18next';
import { Receive } from '../atoms/icons/Receive';
import { IdentificationCard } from '../atoms/icons/IdentificationCard';
import { BookOpen } from '../atoms/icons/BookOpen';
import { Image } from 'primereact/image';
import { PanelMenu } from 'primereact/panelmenu';
import { HandWithdraw } from '../atoms/icons/HandWithdraw';

interface Props {
}


export const SideMenu: React.FC<Props> = (props) => {

    const {t} = useTranslation();
    let user = useSelector(userSelector).singleItem;
    const [expandedKeys, setExpandedKeys] = useState({});
    const [showSettings, setShowSettings] = useState(false);

    let navigation = useNavigate();

    function toggleShowSettings() {
        setShowSettings(!showSettings);
    }

    const itemRenderer = (item: any) => (

        <Menu model={items2} className="mt-6" style={{border: 'none'}}/>
    );
    let items = [
        {
            items: [
                {
                    label: t('menu.dashboard'),
                    icon: <Grid className="mr-2 flex"/>,
                    command: () => {
                        navigation('/');
                    },
                    className: 'mb-3'
                }
            ],
            className: 'text-gray-900'
        },
        {
            label: t('menu.issuer'),
            items: [
                {
                    label: t('menu.issuedCredentials'),
                    icon: <Bank className="mr-2 flex"/>,
                    command: () => {
                        navigation('/issuedCredential');
                    },
                    className: 'mb-3'
                }
            ],
            className: 'text-gray-900'
        },
        {
            label: t('menu.holder'),
            items: [
                {
                    label: t('menu.walletOverview'),
                    icon: <Wallet className="mr-2 flex" />,
                    command: () => {
                        navigation('/credentials');
                    }
                },
                {
                    label: t('menu.newCredential'),
                    icon: <Key className="mr-2 flex" />,
                    command: () => {
                        navigation('/credential/new');
                    }
                },
                {
                    label: t('menu.publicProfile'),
                    icon: <PublicProfile className="mr-2 flex" />,
                    command: () => {
                        navigation('/profile');
                    }
                }
            ],
            className: 'mt-3 text-gray-900'
        },
        {
            label: t('menu.verifier'),
            items: [
                {
                    label: t('menu.receivedCredentials'),
                    icon: <Vault className="mr-2 flex"/>,
                    command: () => {
                        navigation('/receivedCredential');
                    },
                    className: 'mb-3'
                }
            ],
            className: 'mt-3 text-gray-900'
        },
        {
            label: t('menu.miscellaneous'),
            items: [
                {
                    label: t('menu.inbox'),
                    icon: <Inbox className="mr-2 flex" />,
                    command: () => {
                        navigation('/inbox');
                    }
                },
                {
                    label: t('menu.trustedContacts'),
                    icon: <Users className="mr-2 flex"/>,
                    command: () => {
                        navigation('/trustedContacts');
                    },
                    className: 'mb-3'
                }
            ],
            className: 'mt-3 text-gray-900'
        },
        {
            label: t('menu.applications'),
            items: [
                {
                    label: 'Send invoice',
                    icon: <InvoiceIcon className="mr-2 flex"/>,
                    command: () => {
                        navigation('/invoice/send');
                    },
                    className: 'mb-3'
                }
            ],
            className: 'mt-3 text-gray-900'
        },
    ];

    let advancedItems = [
        {
            key: '0',
            label: t('menu.generic'),
            items: [
                {
                    key: '0-1',
                    label: t('menu.identifiers'),
                    icon: <IdentificationCard className="mr-2 flex"/>,
                    command: () => {
                        navigation('/did');
                    }
                },
                {
                    key: '0-2',
                    label: t('menu.history'),
                    icon: <BookOpen width="20" height="20" className="mr-2 flex"/>,
                    command: () => {
                        navigation('/history');
                    }
                },
                {
                    key: '0-3',
                    label: t('menu.trustedIssuerList'),
                    icon: <span className="mr-2 flex pi pi-verified" style={{fontSize: '20px'}}/>,
                    command: () => {
                        navigation('/trustedIssuerList');
                    },
                    className: 'mb-3'
                }
            ]
        },
        {
            key: '1',
            label: t('menu.issuer'),
            items: [{
                key: '1-1',
                label: t('menu.issuerTemplates'),
                icon: <SlidersHorizontal className="mr-2 flex"/>,
                command: () => {
                    navigation('/credentialIssuerDefinition');
                }
                // }, {
                //     key: '1-2',
                //     label: t('menu.issuerTrust'),
                //     icon: <ShieldCheck className="mr-2 flex"/>,
                //     command: () => {
                //         navigation('/trustedIssuer');
                //     }
            }, {
                label: 'Credential status',
                icon: <HandWithdraw className="mr-2 flex"/>,
                command: () => {
                    navigation('/credentialStatus');
                }
            }
            ]
        },
        {
            key: '2',
            label: t('menu.verifier'),
            items: [{
                key: '2-1',
                label: t('menu.receive'),
                icon: <Receive className="mr-2 flex" />,
                command: () => {
                    navigation('/presentationDefinition');
                },
                className: 'mb-3'
            }]
        }
    ]

    const items2 = [
        {
            label: 'New', icon: 'pi pi-fw pi-plus', command: () => {
                navigation('/did');
            }
        },
        {
            label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => {
                navigation('/did');
            }
        }
    ]

    return (
        <Root>
            <div className="flex flex-row justify-content-center cursor-pointer" onClick={event => navigation('/')}>
                {(user?.base64EncodedOrganizationLogo === undefined) && (
                    <Logo className="flex align-self-start"/>
                )}
                {(user?.base64EncodedOrganizationLogo !== undefined) && (
                    <Image src={user?.base64EncodedOrganizationLogo} alt="Logo" width="120"/>
                )}
            </div>
            <Menu model={items} className="mt-3 mr-0" style={{border: 'none'}}/>

            <div className="mt-3 mr-0 p-menu p-component p-ripple-disabled border-0" >
                <ul className="p-menu-list p-reset">
                    <li className="mb-0 p-menuitem">
                        <div className="p-menuitem-content">
                            <a href="#" className="p-menuitem-link" onClick={event => toggleShowSettings()}>
                                <Settings className="mr-2 flex"/>
                                <span className="p-menuitem-text" data-pc-section="label">{t('menu.advanced')}</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>

            {showSettings ? <PanelMenu model={advancedItems}
                       expandedKeys={expandedKeys}
                       onExpandedKeysChange={setExpandedKeys} className="w-full mt-0" multiple />: <></>}
            <div className="flex flex-column justify-content-end align-items-center flex-grow-1 pb-2" style={{height: '100%'}}>
                <div className="text-sm mb-2" style={{color: 'var(--black-200)'}}>Powered by:</div>
                <Logo/>
            </div>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 16px 16px;
    height: 100%;
`;

const MenuItem = styled.div`
    padding-top: 20px;
`;

