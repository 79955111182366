import React from 'react';
import { IconProps } from './IconProps';


export const IconConnectToExistingOrganisation: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
        <svg viewBox="0 0 160 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_802_92333)">
                <path
                    d="M69.524 42.7519L57.1134 35.5868C55.1638 34.4612 52.8701 34.1513 50.6575 34.7442C48.4449 35.337 46.6134 36.7522 45.4879 38.7017L44.7192 40.0331L49.284 42.6686L50.0527 41.3372C50.492 40.5764 51.1991 40.0654 52.0241 39.8443C52.8492 39.6233 53.7271 39.7498 54.4779 40.1515L66.8885 47.3167C67.6494 47.7559 68.1604 48.463 68.3815 49.2881C68.6025 50.1131 68.476 50.991 68.0743 51.7418L66.2075 54.9751C65.7682 55.7359 65.0611 56.2469 64.236 56.468C63.411 56.6891 62.533 56.5626 61.7823 56.1608L49.3716 48.9957C48.6108 48.5564 48.0998 47.8493 47.8787 47.0243C47.6576 46.1992 47.7841 45.3213 48.2234 44.5605L48.9921 43.2291L44.4272 40.5937L43.6586 41.925C41.325 45.9667 42.7218 51.1794 46.7736 53.5504L59.1843 60.7155C61.1338 61.8411 63.4276 62.1509 65.6402 61.5581C67.8528 60.9652 69.6842 59.5501 70.8098 57.6005L72.6766 54.3672C74.9726 50.3355 73.5759 45.1229 69.524 42.7519Z"
                    fill="#1B364C"/>
                <path
                    d="M41.1401 40.0274L41.3872 39.5995L36.8224 36.964L36.0537 38.2954C35.6144 39.0562 34.9073 39.5672 34.0822 39.7883C33.2572 40.0093 32.3792 39.8828 31.6285 39.4811L19.2279 32.3534C18.4671 31.9142 17.956 31.207 17.735 30.382C17.5139 29.557 17.6404 28.6791 18.0421 27.9283L19.909 24.695C20.3482 23.9342 21.0554 23.4231 21.8804 23.2021C22.7054 22.981 23.5834 23.1075 24.3342 23.5093L36.7448 30.6744C37.5056 31.1137 38.0166 31.8208 38.2377 32.6458C38.4588 33.4708 38.3323 34.3488 37.893 35.1096L37.1243 36.441L41.6892 39.0764L42.4579 37.745C44.7914 33.7033 43.3946 28.4907 39.3428 26.1197L26.9322 18.9546C24.9826 17.829 22.6889 17.5192 20.4763 18.112C18.2637 18.7048 16.4322 20.12 15.3066 22.0695L13.4398 25.3029C11.1063 29.3446 12.5031 34.5572 16.5549 36.9282L28.9655 44.0934C30.9151 45.2189 33.2088 45.5288 35.4214 44.9359C37.634 44.3431 39.4655 42.9279 40.5911 40.9784L41.1401 40.0274Z"
                    fill="#64798A"/>
                <path
                    d="M52.5389 41.9169L35.8012 32.2536C35.183 31.8967 34.4751 31.805 33.8001 31.9859C33.1251 32.1667 32.5579 32.6001 32.201 33.2182C31.8441 33.8364 31.7524 34.5442 31.9333 35.2192C32.1142 35.8942 32.5475 36.4614 33.1657 36.8183L49.9034 46.4817C50.5216 46.8385 51.2294 46.9302 51.9044 46.7494C52.5795 46.5685 53.1467 46.1352 53.5036 45.517C53.8605 44.8989 53.9521 44.191 53.7713 43.516C53.5904 42.841 53.157 42.2738 52.5389 41.9169Z"
                    fill="#0B2C48"/>
            </g>
            <path
                d="M91.0605 16.3653C91.0605 14.8045 91.6766 13.3077 92.7733 12.204C93.8699 11.1004 95.3573 10.4804 96.9082 10.4804H153.046C153.666 10.4823 154.26 10.7309 154.698 11.1719C155.136 11.613 155.383 12.2106 155.385 12.8343V23.4271H91.0605V16.3653Z"
                fill="#A8B9C7"/>
            <path
                d="M156.554 24.6041V66.9751C156.553 67.5988 156.306 68.1964 155.867 68.6375C155.429 69.0785 154.835 69.3271 154.215 69.329H96.9082C96.1403 69.329 95.3799 69.1768 94.6704 68.881C93.9609 68.5853 93.3163 68.1518 92.7733 67.6054C91.6766 66.5017 91.0605 65.0049 91.0605 63.4441V16.3652C91.0623 16.9601 91.1529 17.5513 91.3295 18.1189C91.7674 19.3221 92.5595 20.3622 93.6002 21.1004C94.6409 21.8385 95.8806 22.2396 97.1538 22.2501H154.215C154.836 22.2501 155.431 22.4981 155.869 22.9396C156.308 23.381 156.554 23.9797 156.554 24.6041Z"
                fill="#CED6DD"/>
            <path
                d="M149.537 39.9047H156.554C157.175 39.9047 157.77 40.1527 158.208 40.5941C158.647 41.0356 158.893 41.6343 158.893 42.2586V49.3205C158.893 49.9448 158.647 50.5435 158.208 50.985C157.77 51.4264 157.175 51.6744 156.554 51.6744H149.537C147.986 51.6744 146.499 51.0544 145.402 49.9508C144.306 48.8472 143.689 47.3503 143.689 45.7896C143.689 44.2288 144.306 42.732 145.402 41.6283C146.499 40.5247 147.986 39.9047 149.537 39.9047Z"
                fill="#A8B9C7"/>
            <path opacity="0.8" d="M91.0605 44.6126H115.621V46.9665H91.0605V44.6126Z" fill="#19339E"/>
            <path opacity="0.8"
                  d="M99.9373 39.9047H91.0605V37.5507H99.9373C100.282 37.5504 100.622 37.4741 100.935 37.3271C101.247 37.1801 101.523 36.9659 101.744 36.6998L106.529 30.9126L108.326 32.4192L103.538 38.2099C103.098 38.7406 102.547 39.1675 101.925 39.4604C101.303 39.7532 100.624 39.9049 99.9373 39.9047Z"
                  fill="#19339E"/>
            <path opacity="0.8"
                  d="M106.529 60.6665L101.741 54.8758C101.52 54.6106 101.244 54.3972 100.932 54.2508C100.621 54.1044 100.281 54.0285 99.9373 54.0284H91.0605V51.6744H99.9373C100.624 51.6737 101.302 51.8249 101.923 52.1174C102.545 52.4099 103.095 52.8365 103.535 53.3669L108.33 59.16L106.529 60.6665Z"
                  fill="#19339E"/>
            <path d="M116.79 49.3205C118.728 49.3205 120.298 47.7396 120.298 45.7896C120.298 43.8395 118.728 42.2586 116.79 42.2586C114.852 42.2586 113.281 43.8395 113.281 45.7896C113.281 47.7396 114.852 49.3205 116.79 49.3205Z" fill="#19339E"/>
            <path d="M107.432 35.1968C109.37 35.1968 110.941 33.616 110.941 31.6659C110.941 29.7158 109.37 28.135 107.432 28.135C105.495 28.135 103.924 29.7158 103.924 31.6659C103.924 33.616 105.495 35.1968 107.432 35.1968Z" fill="#19339E"/>
            <path d="M107.432 63.4441C109.37 63.4441 110.941 61.8633 110.941 59.9132C110.941 57.9632 109.37 56.3823 107.432 56.3823C105.495 56.3823 103.924 57.9632 103.924 59.9132C103.924 61.8633 105.495 63.4441 107.432 63.4441Z" fill="#19339E"/>
            <defs>
                <clipPath id="clip0_802_92333">
                    <rect width="71.6992" height="60" fill="white" transform="matrix(0.965927 -0.258813 0.258825 0.965924 0.607422 20.3006)"/>
                </clipPath>
            </defs>
        </svg>

    )
};
