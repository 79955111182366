import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import { DataViewPageEvent } from 'primereact/dataview';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { formatDateForDisplay, formatDateTimeForDisplay, getInbox, HistoryEvent, inboxSelector, useAppDispatch } from '../../../state';
import { DataTable, DataTableSelectEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TableHeader } from '../../organisms';
import { useTranslation } from 'react-i18next';
import { InfoCard } from '../../molecules';
import { CalendarBlank, Inbox } from '../../atoms';

export const InboxList: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>();
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    const {t} = useTranslation();

    let inbox = useSelector(inboxSelector);

    const initialParams: DataViewPageEvent = {
        first: 0,
        rows: 12,
        page: 0,
        pageCount: 1
    };

    const [tableParams, setTableParams] = useState<DataViewPageEvent>(initialParams);

    useEffect(() => {
        dispatch(getInbox({jwtToken: keycloak.token!, page: tableParams.page, pageSize: (tableParams.rows)}));
    }, [keycloak.token, tableParams.page, tableParams.rows, searchText]);

    useEffect(() => {
        setTableParams({...tableParams, pageCount: inbox.totalPages});
    }, [inbox.totalPages]);

    function onPageChange(paginatorPageChangeEvent: PaginatorPageChangeEvent) {
        setTableParams({...tableParams, page: paginatorPageChangeEvent.page, rows: paginatorPageChangeEvent.rows});
    }

    function onRowSelect(event: DataTableSelectEvent) {
        navigate('/invoice/details/' + event.data.id);
    }

    function onSearch(searchText: string | undefined) {
        setSearchText(searchText);
    }

    const dateTemplate = (history: HistoryEvent) => {
        return <div className="flex flex-row align-items-center">
            <CalendarBlank /><span className="ml-1">{ formatDateForDisplay(history.eventDate) }</span>
        </div>
    }

    function inboxTemplate(inbox: any, value: any) {
        return inbox.status === 'NEW' ? <b>{value}</b>: <>{value}</>
    }

    return (
        <>
            <div>
                <InfoCard className="mb-4" icon={<Inbox height="30" width="30"/>}
                          title={t('screens.inbox.title')}
                          description={t('screens.inbox.intro')}/>

                <DataTable header={<TableHeader  onSearch={onSearch}
                />}
                           value={inbox.list}
                           rows={10}
                           rowsPerPageOptions={[12, 24, 36]}
                           tableStyle={{minWidth: '50rem'}}
                           selectionMode="single"

                           onRowSelect={onRowSelect}
                           rowHover={true}
                           onSelectionChange={(e) => setSelectedItem(e.value)}>
                    <Column body={(inbox) => inboxTemplate(inbox, formatDateTimeForDisplay(inbox.received)) } header="Received" style={{width: '20%'}}></Column>
                    <Column body={(inbox) => inboxTemplate(inbox, inbox.credential.displayProperties.issuerDisplay.displayName) } header="Issuer" style={{width: '30%'}}></Column>
                    <Column body={(inbox) => inboxTemplate(inbox, inbox.credential.credentialSubject.vct) } header="Type" style={{width: '25%'}}></Column>
                    <Column body={(inbox) => inboxTemplate(inbox, inbox.credential.status.credentialStatus) } header="Status" style={{width: '15%'}}></Column>
                </DataTable>
                {((inbox.list.length > 0) && (inbox.totalPages > 1)) && (
                    <div className="flex justify-content-end">
                        <Paginator first={inbox.currentPage * tableParams.rows} rows={tableParams.rows} totalRecords={inbox.totalElements} rowsPerPageOptions={[12, 24, 36]} onPageChange={onPageChange}/>
                    </div>
                )}
            </div>
        </>
    );
}
