import React, { useRef, useState } from 'react';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { Logo } from '../atoms';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

interface FileUploadWithLabelProps {
    label: string;
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    title?: string | undefined;
    value: string;
    onUpload: (base64EncodedContent: string) => void;
    footer?: React.ReactNode | undefined;
    postElement?: React.ReactNode | undefined;
}

export const FileUploadWithLabel: React.FC<FileUploadWithLabelProps> = (props) => {
    const [uploadedFile, setUploadedFile] = useState<string>();
    const fileUploadRef = useRef<FileUpload>(null);

    const uploadFile = async (event: FileUploadHandlerEvent) => {
        const file = event.files[0];
        setUploadedFile(file.name);
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

        reader.readAsDataURL(blob);

        reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data !== undefined) {
                props.onUpload(base64data!.toString());
            }
        };
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };


    return (
        <div className={props.className}
             style={{border: 'none', borderRadius: 16, paddingLeft: 20, paddingRight: 20, paddingTop: 18, paddingBottom: 14, backgroundColor: '#ffffff'}}>
            <div className="text-xs pb-1 pl-2" style={{color: 'rgba(28, 28, 28, 0.40)'}}>{props.label}</div>
            <div className="flex">
                <InputText value={uploadedFile} style={{border: 'none'}}
                           readOnly={true}
                           placeholder='Select file'/>
                <FileUpload ref={fileUploadRef} mode="basic" name="demo[]" accept="application/xml" maxFileSize={1000000} customUpload
                            uploadHandler={uploadFile} auto/>
                {props.postElement && (
                    <>{props.postElement}</>
                )}
            </div>
            {props.footer && (
                <>{props.footer}</>
            )}
        </div>
)
    ;
};


