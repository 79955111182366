import * as React from 'react';
import { FC, ReactNode, useEffect, useState } from 'react';

import { DataViewPageEvent } from 'primereact/dataview';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { getTrustedContacts, trustedContactSelector, useAppDispatch, TrustedContact } from '../../../state';
import { DataTable, DataTableSelectEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TableHeader } from '../../organisms';
import { useTranslation } from 'react-i18next';
import { InfoCard } from '../../molecules';
import { ShieldWarning, ShieldCheck, Users } from '../../atoms';


export const TrustedContactList: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak, initialized} = useKeycloak();
    let navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>();
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    const {t} = useTranslation();

    let trustedContacts = useSelector(trustedContactSelector);

    const initialParams: DataViewPageEvent = {
        first: 0,
        rows: 12,
        page: 0,
        pageCount: 1
    };

    const [tableParams, setTableParams] = useState<DataViewPageEvent>(initialParams);

    useEffect(() => {
        dispatch(getTrustedContacts({jwtToken: keycloak.token!, page: tableParams.page, pageSize: (tableParams.rows), searchText: searchText}));
    }, [keycloak.token, tableParams.page, tableParams.rows, searchText]);

    useEffect(() => {
        setTableParams({...tableParams, pageCount: trustedContacts.totalPages});
    }, [trustedContacts.totalPages]);

    function onPageChange(paginatorPageChangeEvent: PaginatorPageChangeEvent) {
        setTableParams({...tableParams, page: paginatorPageChangeEvent.page, rows: paginatorPageChangeEvent.rows});
    }

    function onRowSelect(event: DataTableSelectEvent) {
        navigate('/trustedContacts/details/' + event.data.id);
    }

    function onSearch(searchText: string | undefined) {
        setSearchText(searchText);
    }

    function createNew() {
        navigate('/trustedContacts/search');
    }

    function showTrusted(contact: TrustedContact): ReactNode {
        if (contact.trusted) {
            return <ShieldCheck color="#579F6E"/>
        }
        return <ShieldWarning color="#FF0000"/>
    }

    return (
        <>
            <div>
                <InfoCard className="mb-4" icon={<Users height="30" width="30"/>}
                          title={t('screens.trustedContactList.title')}
                          description={t('screens.trustedContactList.intro')}/>

                <DataTable header={<TableHeader
                    onAddClicked={createNew}
                    onSearch={onSearch}
                />}
                           value={trustedContacts.list}
                           rows={10}
                           rowsPerPageOptions={[12, 24, 36]}
                           tableStyle={{minWidth: '50rem'}}
                           selectionMode="single"

                           onRowSelect={onRowSelect}
                           rowHover={true}
                           onSelectionChange={(e) => setSelectedItem(e.value)}>
                    <Column body={(contact) => showTrusted(contact)} style={{width: '5%'}}></Column>
                    <Column field="name" header="Name" style={{width: '35%'}}></Column>
                    <Column field="did" header="Did" style={{width: '60%'}}></Column>
                </DataTable>
                {((trustedContacts.list.length > 0) && (trustedContacts.totalPages > 1)) && (
                    <div className="flex justify-content-end">
                        <Paginator first={trustedContacts.currentPage * tableParams.rows} rows={tableParams.rows} totalRecords={trustedContacts.totalElements} rowsPerPageOptions={[12, 24, 36]} onPageChange={onPageChange}/>
                    </div>
                )}
            </div>
        </>
    );
}
