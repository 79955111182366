import React, { CSSProperties, HTMLInputTypeAttribute, useEffect, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import { Controller, FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { FieldPath } from 'react-hook-form/dist/types';
import { v4 as uuidv4 } from 'uuid';
import { InputErrorLabel } from '../atoms';
import { InputTextarea } from 'primereact/inputtextarea';


export interface FormTextInputProps {
    value?: string | undefined;
    multiline?: boolean | undefined;
    onChangeValue?: (value: string | undefined) => void;
    inputType?: HTMLInputTypeAttribute | undefined;
    placeHolder?: string | undefined;
    className?: string | undefined;
    style?: CSSProperties | undefined;
    formHook: UseFormReturn<any, any, FieldValues | undefined>;
    controlName?: string | undefined;
    rules?: Omit<RegisterOptions<FieldValues, FieldPath<FieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

export const FormTextInput: React.FC<FormTextInputProps> = (props) => {

    const controlName = useMemo(() => (props.controlName === undefined ? uuidv4() : props.controlName), [props.controlName]);
    const currentValue: string = useWatch({
        control: props.formHook.control,
        name: controlName,
        defaultValue: props.value ?? '',
    });

    const {multiline = false, inputType = 'text'} = props;

    useEffect(() => {
        props.formHook?.setValue(controlName, props.value ?? '');
    }, [props.value]);

    useEffect(() => {
        if (props.onChangeValue !== undefined) {
            props.onChangeValue(currentValue);
        }
    }, [currentValue]);

    function getBorderColor(hasErrors: boolean): CSSProperties {
        if (hasErrors) {
            return {border: 'var(--red-500) solid 1px', borderLeftWidth: 10};
        } else {
            return {};
        }

    }

    return (
        <Controller name={controlName} control={props.formHook?.control}
                    rules={props.rules}
                    render={({field, fieldState}) => {
                        let value = (field.value === undefined) ? '' : field.value;
                        return (
                            <div className={props.className}>
                                {multiline && (
                                    <InputTextarea id={field.name} {...field} className={props.className} value={value} style={Object.assign({}, props.style, getBorderColor(fieldState.error !== undefined))}
                                               onChange={(event) => field.onChange(event.target.value)}
                                               placeholder={props.placeHolder}/>

                                )}
                                {!multiline && (
                                    <InputText id={field.name} {...field} className={props.className} value={value} style={Object.assign({}, props.style, getBorderColor(fieldState.error !== undefined))}
                                               onKeyDown={(event) => {
                                                   if (event.key === 'Enter') {
                                                       event.preventDefault();
                                                   }
                                               }}
                                               onChange={(event) => field.onChange(event.target.value)}
                                               type={inputType}
                                               placeholder={props.placeHolder}/>
                                )}
                                <div>{(fieldState.error) && (
                                    <InputErrorLabel className="ml-1">{fieldState.error?.message}</InputErrorLabel>
                                )}
                                </div>

                            </div>
                        );
                    }}/>
    );
};

