import * as React from 'react';
import { FC, useEffect } from 'react';
import { OH1 } from '../../atoms/texts/OH1';
import { DashboardCard } from '../../molecules/DashboardCard';
import { useTranslation } from 'react-i18next';
import { getWallet, useAppDispatch, walletSelector } from '../../../state';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { AlertMessage } from '../../molecules/AlertMessage';

export const Dashboard: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();

    const {t} = useTranslation();
    let walletState = useSelector(walletSelector);
    useEffect(() => {
        dispatch(getWallet({jwtToken: keycloak.token!}));
    }, [keycloak.token]);


    return <main>
        <OH1 className="mb-4">{t('screens.dashboard.title')}</OH1>
        <p className="mb-4 text-400 text-sm">{t('screens.dashboard.subtitle')}</p>
        {(walletState.singleItem?.walletState === 'INITIAL') && (
            <AlertMessage className="flex flex-row align-items-center mb-8"
                          title={t('screens.dashboard.completeSignup.title')}
                          type="info">
                <p>
                    {t('screens.dashboard.completeSignup.description')}<br/>
                    <a className="underline" href={'/signup'}>{t('screens.dashboard.completeSignup.actionLinkLabel')}</a>
                </p>
            </AlertMessage>
        )}
        <div className="flex flex-row align-items-center gap-4 mb-4">
            <DashboardCard title={t('screens.dashboard.issuer.title')}
                           description={t('screens.dashboard.issuer.description')}
                           linkText={t('menu.issuedCredentials')}
                           linkNavigation="/issuedCredential"
                           color="#06AED5"/>
            <DashboardCard title={t('screens.dashboard.holder.title')}
                           description={t('screens.dashboard.holder.description')}
                           linkText={t('menu.walletOverview')}
                           linkNavigation="/credentials"
                           color="#F0C808"/>
            <DashboardCard title={t('screens.dashboard.verifier.title')}
                           description={t('screens.dashboard.verifier.description')}
                           linkText={t('menu.receivedCredentials')}
                           linkNavigation="/receivedCredential"
                           color="#840032"/>
        </div>
        <div className="flex flex-row align-items-center">
            <DashboardCard title={t('screens.dashboard.messages.title')}
                           description={t('screens.dashboard.messages.description')}
                           linkText={t('menu.inbox')}
                           linkNavigation="/inbox"
                           color="#1B998B"/>
        </div>
    </main>
}
