import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState, Wallet } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { getWallet } from './WalletApi';


export interface WalletState extends GenericState<Wallet> {
}

export const walletSlice = createSlice({
    name: 'Wallet',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, getWallet);
    },
});

