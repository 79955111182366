import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SignupExistingCompanyStepper } from '../../organisms/SignupExistingCompanyStepper';
import { Button } from 'primereact/button';
import { OCard } from '../../molecules';
import { IconConnectToExistingOrganisation } from '../../atoms';
import { completeSignup } from '../../../state/slices/signup';
import { useKeycloak } from '@react-keycloak/web';
import { getWallet, useAppDispatch } from '../../../state';
import { useNavigate } from 'react-router-dom';

export const SignupComplete: FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();

    function handleCompleteSignup() {
        dispatch(completeSignup({jwtToken: keycloak.token!})).then((response) => {
                if (response.type.includes('fulfilled')) {
                    dispatch(getWallet({jwtToken: keycloak.token!})).then((response) => {
                        setTimeout(() => { // Use timeout the give time to update the redux store.
                            navigate("/")
                        }, 250);
                    });
                }
            }
        );
    }

    return (
        <main className="w-full">
            <h1 className="text-3xl font-bold mt-8">{t('screens.signupExistingCompany.title')}</h1>
            <p className="mb-8">{t('screens.signupExistingCompany.intro')}</p>
            <SignupExistingCompanyStepper selectedIndex={3} className="mb-6"/>

            <OCard className="flex flex-column" style={{minHeight: '360px'}}>
                <div className="flex justify-content-start">
                    <IconConnectToExistingOrganisation className="h-5rem w-auto"/>
                </div>
                <h2 className="text-xl font-bold">{t('screens.signupExistingCompany.completeSignup.title')}</h2>
                <p>{t('screens.signupExistingCompany.completeSignup.intro')}</p>
                <div className="flex-grow-1"></div>
                <Button className="w-full mt-6" label={t('screens.signupExistingCompany.completeSignup.actionButtonLabel')} onClick={handleCompleteSignup}/>
            </OCard>

        </main>
    );
}
