import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { getTransportTypes, getTrustedContacts, invoiceSelector, prepareInvoice, trustedContactSelector, useAppDispatch } from '../../../state';
import { Button } from 'primereact/button';
import { OCard, TextInputWithLabel } from '../../molecules';
import { IdentificationCard, InvoiceIcon } from '../../atoms';
import { InfoCard } from '../../molecules/InfoCard';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';
import { sendInvoice } from '../../../state/slices/invoice';
import { Invoice } from '../../../state/slices/model/Invoice';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { TrustedContact } from '../../../state/slices/model/TrustedContact';
import { NumberInputWithLabel } from '../../molecules/NumberInputWithLabel';
import { FileUploadWithLabel } from '../../molecules/FileUploadWithLabel';
import { Message } from 'primereact/message';

export const InvoiceCreate: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [selectedRelation, setSelectedRelation] = useState<TrustedContact>();
    const [selectedTransportType, setSelectedTransportType] = useState<string>();
    const [isErrorContact, setIsErrorContact] = useState(false)

    const addOneMonth = (date: Date): Date => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
    };
    const formatDate = (date: Date): string => {
        return date.toISOString().split('T')[0];
    };
    let trustedRelations = useSelector(trustedContactSelector);
    let transportTypes = useSelector(invoiceSelector);

    useEffect(() => {
        dispatch(getTrustedContacts({jwtToken: keycloak.token!, page: 0, pageSize: 100, searchText: ''}));
    }, [keycloak.token]);

    useEffect(() => {
        dispatch(getTransportTypes({jwtToken: keycloak.token!, trustedRelationId: selectedRelation?.id!}));
    }, [selectedRelation]);

    const [invoice, setInvoice] = useState<Invoice>({
        invoiceId: '', invoiceDate: formatDate(new Date()), dueDate: formatDate(addOneMonth(new Date())), currencyCode: 'EUR', invoiceDocument: ''});
    let navigate = useNavigate();

    function handleFormUpdate(attributeName: string, value: string | undefined) {
        setInvoice(Object.assign({}, invoice, {[attributeName]: value}));
    }

    function handlePrepareInvoice(invoiceDocument: string) {
        setIsLoading(true)
        invoice.invoiceDocument = invoiceDocument;
        dispatch(prepareInvoice({jwtToken: keycloak.token!, invoice: invoice!})).then((response) => {
            setInvoice(response.payload)
            if (response.payload.receiver) {
                let receiver = response.payload.receiver;
                dispatch(getTrustedContacts({jwtToken: keycloak.token!, page: 0, pageSize: 100, searchText: ''})).then((response)  => {
                    setIsErrorContact(false);
                    setSelectedRelation(response.payload.content.find((value: TrustedContact) => value.id === receiver));
                });
            } else {
                setIsErrorContact(true);
                setSelectedRelation(undefined);
            }
            setIsLoading(false);
        });
    }

    function handleSendInvoice() {
        setIsLoading(true)
        dispatch(sendInvoice({jwtToken: keycloak.token!, invoice: invoice!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                navigate(`/trustedRelations`)
            }
        });
    }

    function onSelectTrustedRelation(trustedRelation: TrustedContact) {
        setSelectedRelation(trustedRelation);
        setInvoice(Object.assign({}, invoice, {receiver: trustedRelation.id}));
    }

    function onSelectTransportType(transportType: string) {
        setSelectedTransportType(transportType);
        setInvoice(Object.assign({}, invoice, {transportType: transportType}));
    }

    const isValidForm = useMemo(() => {
        if (invoice.receiver === undefined) {
            return false;
        }
        return true;
    }, [invoice]);

    return (
        <>
            {isLoading ? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar> : <></>}
            <div>
                <InfoCard className="mb-4"
                          title={t('screens.invoice.title')}
                          description={t('screens.invoice.description')}
                          icon={<InvoiceIcon width="40" height="40"/>} />

                <OCard className="mt-5 mb-4">
                    <FileUploadWithLabel className="mb-3"
                                         label={t('screens.invoice.attributes.invoiceDocument.label')}
                                         value={invoice.invoiceDocument.toString()}
                                         onUpload={(value) => handlePrepareInvoice(value)}/>
                    {(trustedRelations && trustedRelations.list && trustedRelations.list.length > 1) && (
                        <div className="mb-3" style={{
                            border: 'none',
                            borderRadius: 16,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 18,
                            paddingBottom: 14,
                            backgroundColor: '#ffffff'
                        }}>
                            <div className="text-xs pb-1 pl-2"
                                 style={{color: 'rgba(28, 28, 28, 0.40)'}}>{t('screens.invoice.attributes.receiver.label')} </div>
                            <Dropdown value={selectedRelation} onChange={(e) => onSelectTrustedRelation(e.value)}
                                      options={trustedRelations.list} optionLabel="name"
                                      placeholder={t('screens.invoice.attributes.receiver.placeholder')}
                                      className="w-full md:w-14rem"
                                      checkmark={true}
                                      invalid={isErrorContact}
                                      highlightOnSelect={false} />&nbsp;
                            {isErrorContact ? <Message severity="error" text="Contact not found in Blue Pages" /> : <></>}
                        </div>
                    )}
                    {(transportTypes && transportTypes.list && transportTypes.list.length > 1) && (
                        <div className="mb-3" style={{
                            border: 'none',
                            borderRadius: 16,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 18,
                            paddingBottom: 14,
                            backgroundColor: '#ffffff'
                        }}>
                            <div className="text-xs pb-1 pl-2"
                                 style={{color: 'rgba(28, 28, 28, 0.40)'}}>{t('screens.invoice.attributes.transport.label')} </div>
                            <Dropdown value={selectedTransportType} onChange={(e) => onSelectTransportType(e.value)}
                                      options={transportTypes.list} optionLabel="name"
                                      placeholder={t('screens.invoice.attributes.transport.placeholder')}
                                      className="w-full md:w-14rem"
                                      checkmark={true}
                                      highlightOnSelect={false} />
                        </div>
                    )}
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.invoice.attributes.invoiceId.label')}
                                        value={invoice.invoiceId}
                                        onChangeValue={(value) => handleFormUpdate('invoiceId', value)}/>
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.invoice.attributes.invoiceDate.label')}
                                        value={invoice.invoiceDate}
                                        inputType='date'
                                        onChangeValue={(value) => handleFormUpdate('invoiceDate', value)}/>
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.invoice.attributes.dueDate.label')}
                                        value={invoice.dueDate}
                                        inputType='date'
                                        onChangeValue={(value) => handleFormUpdate('dueDate', value)}/>
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.invoice.attributes.currencyCode.label')}
                                        value={invoice.currencyCode}
                                        onChangeValue={(value) => handleFormUpdate('currencyCode', value)}/>
                    <NumberInputWithLabel className="mb-3"
                                        label={t('screens.invoice.attributes.taxExclusiveAmount.label')}
                                        value={invoice.taxExclusiveAmount}
                                        onChangeValue={(value) => handleFormUpdate('taxExclusiveAmount', value?.toString())}/>
                    <NumberInputWithLabel className="mb-3"
                                        label={t('screens.invoice.attributes.taxAmount.label')}
                                        value={invoice.taxAmount}
                                        inputType='number'
                                        onChangeValue={(value) => handleFormUpdate('taxAmount', value?.toString())}/>
                    <NumberInputWithLabel className="mb-3"
                                        label={t('screens.invoice.attributes.taxInclusiveAmount.label')}
                                        value={invoice.taxInclusiveAmount}
                                        onChangeValue={(value) => handleFormUpdate('taxInclusiveAmount', value?.toString())}/>
                </OCard>
            </div>
            <Button className="p-2 m-2" onClick={handleSendInvoice} disabled={!isValidForm}>{t('screens.invoice.create')}</Button>
        </>
    );
};
