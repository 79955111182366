import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { addQueryParam } from '../slice';
import { TrustedContact } from '../model/TrustedContact';


export const getTrustedContacts = createAsyncThunk(
    'trustedContact/getTrustedContacts', ({jwtToken, page, pageSize, searchText}: { jwtToken: string | undefined, page: number, pageSize: number, searchText?: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/trustedcontact';
        url = addQueryParam(url, 'page', page);
        url = addQueryParam(url, 'size', pageSize);
        url = addQueryParam(url, 'q', searchText);

        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getTrustedContact = createAsyncThunk(
    'trustedContact/getTrustedContact', ({jwtToken, trustedContactId}: { jwtToken: string | undefined, trustedContactId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };


        var url = '/trustedcontact/' + trustedContactId;
        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveTrustedContact = createAsyncThunk(
    'trustedContact/saveTrustedContact', ({jwtToken, trustedContact}: { jwtToken: string | undefined, trustedContact: TrustedContact }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/trustedcontact';
        if (trustedContact.id !== undefined) {
            url = url + '/' + trustedContact.id;
        }
        return axios.post(url, trustedContact, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const deleteTrustedContact = createAsyncThunk(
    'trustedContact/deleteTrustedContact', ({jwtToken, trustedContactId}: { jwtToken: string | undefined, trustedContactId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/trustedcontact/' + trustedContactId;
        return axios.delete(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const searchOrganizations = createAsyncThunk(
    'trustedContact/searchOrganizations', ({jwtToken, searchText}: { jwtToken: string | undefined, searchText?: string }, thunkAPI) => {
        if (searchText === undefined || searchText.length === 0) {
            return [];
        }
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/trustedcontact/search';
        url = addQueryParam(url, 'q', searchText);

        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
