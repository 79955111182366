import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { setToastMessage, useAppDispatch } from '../../state';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { addKey, addService, deleteService, getDid, revokeKey, setDefaultDid, updateDid } from '../../state/slices/did/DidApi';
import { didSelector } from '../../state/slices/did/DidSelectors';
import { ProgressBar } from 'primereact/progressbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { OCard, TextInputWithLabel, TextToHtml, TextWithExternalLink } from '../molecules';
import { Key, OFieldLabel, OFieldValue } from '../atoms';
import { InfoCard } from '../molecules/InfoCard';
import { DropdownWithLabel } from '../molecules/DropdownWithLabel';
import { useTranslation } from 'react-i18next';
import { ClipboardCopyElement } from '../molecules/ClipboardCopyElement';
import { confirmDialog } from 'primereact/confirmdialog';
import JsonView from '@uiw/react-json-view';

export const DidDetails: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    const {didId} = useParams();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isVisible, setIsVisible] = useState<boolean>();
    const [isEditing, setIsEditing] = useState<boolean>();
    let navigate = useNavigate();
    const serviceTypes = [
        { label: 'eInvoice', type: 'eInvoice'},
        { label: 'Linked Domains', type: 'LinkedDomains' },
        { label: 'Linked Verifiable Presentation', type: 'LinkedVerifiablePresentation' },
        { label: 'OpenID4 Verifiable Credential Issuance', type: 'OID4VCI' },
        { label: 'OpenID4 Verifiable Presentation', type: 'OID4VP' },
    ];
    const ebsiEnvironments = [
        { label: 'Conformance', optionValue: 'conformance' },
        { label: 'Pilot', optionValue: 'pilot' },
    ];
    const [selectedServiceType, setSelectedServiceType] = useState<any>(serviceTypes[0]);
    const [serviceEndpoint, setServiceEndpoint] = useState<any>('');
    const [serviceId, setServiceid] = useState<any>('');

    let did = useSelector(didSelector);
    useEffect(() => {
        if (didId) {
            dispatch(getDid({jwtToken: keycloak.token!, didId: didId}));
        }
    }, [keycloak.token, didId]);

    function onSelectItem(serviceType: any) {
        setSelectedServiceType(serviceType);
    }

    function handleFormUpdate(attributeName: string, value: string | undefined) {
        //setDidForm(Object.assign({}, didForm, {[attributeName]: value}));
    }

    function handleSaveServiceEndpoint() {
        setIsLoading(true)
        dispatch(addService({jwtToken: keycloak.token!, didId: didId!, serviceId: serviceId, serviceType: selectedServiceType.type, serviceEndpoint: serviceEndpoint})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                setIsEditing(false);
                dispatch(getDid({jwtToken: keycloak.token!, didId: didId!}));
            }
        });
    }

    function handleDeleteServiceEndpoint(serviceId: string) {
        setIsLoading(true)
        dispatch(deleteService({jwtToken: keycloak.token!, didId: didId!, serviceId: serviceId})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                setIsEditing(false);
                dispatch(getDid({jwtToken: keycloak.token!, didId: didId!}));
            }
        });
    }

    function handleAddKey() {
        setIsLoading(true)
        dispatch(addKey({jwtToken: keycloak.token!, didId: didId!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                dispatch(getDid({jwtToken: keycloak.token!, didId: didId!}));
            }
        });
    }

    function handleRevokeKey(kid: string) {
        setIsLoading(true)
        dispatch(revokeKey({jwtToken: keycloak.token!, didId: didId!, kid: kid})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                dispatch(getDid({jwtToken: keycloak.token!, didId: didId!}));
            }
        });
    }

    function handleUpdate() {
        setIsLoading(true)
        dispatch(updateDid({jwtToken: keycloak.token!, didId: didId!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                navigate(`/did`)
            }
        });
    }

    const setDefaultDidConfirmDialog = () => {
        confirmDialog({
            header: t('screens.settings.did.setDefault.confirmDialog.title'),
            message: <TextToHtml value={t('screens.settings.did.setDefault.confirmDialog.body')}/>,
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: handleDefaultDidUpdate,
            reject: () => {},
            style: { width: '50vw' },
            breakpoints: { '1100px': '75vw', '960px': '100vw' }
        });
    };

    function handleDefaultDidUpdate() {
        setIsLoading(true)
        dispatch(setDefaultDid({jwtToken: keycloak.token!, didId: didId!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                dispatch(setToastMessage({message: t('screens.settings.did.setDefault.updatedMessage')}));
                navigate(`/did`)
            }
        });
    }

    function isJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <>
            {isLoading ? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar> : <></>}
            <div>
                <InfoCard title={t('screens.settings.did.title')} description={t('screens.settings.did.description')} icon={<Key />} className={"mt-5"} />

                <OCard className="mt-5 mb-4">
                    <div className="field grid">
                        <OFieldLabel className="col-2">{t('screens.settings.did.attributes.didId.label')}</OFieldLabel>
                        <OFieldValue className="col-10"><ClipboardCopyElement copyValue={did.singleItem?.did}>{did.singleItem?.did}</ClipboardCopyElement></OFieldValue>
                    </div>
                    <div className="field grid">
                        <OFieldLabel className="col-2">{t('screens.settings.did.attributes.type.label')}</OFieldLabel>
                        <OFieldValue className="col-10">{did.singleItem?.type}</OFieldValue>
                    </div>
                    <div className="field grid">
                        <OFieldLabel className="col-2">{t('screens.settings.did.attributes.displayName.label')}</OFieldLabel>
                        <OFieldValue className="col-10">{did.singleItem?.displayName}</OFieldValue>
                    </div>
                </OCard>

                <OCard title={t('screens.settings.did.keys')} className="mt-5 mb-4">
                    {did.singleItem?.keys?.map((item, index) => <div key={index}>
                        <div className="field grid">
                            <OFieldLabel className="col-2">{t('screens.settings.did.attributes.keyId.label')}</OFieldLabel>
                            <OFieldValue className="col-10 flex justify-content-between">
                                <div style={{wordBreak: 'break-all'}}>{item.keyId}</div>
                                {did.singleItem?.type === 'ebsi' && item.purposes.indexOf('assertionMethod') >= 0 ?<Button className="min-w-min" label="Delete" outlined={true} onClick={() => handleRevokeKey(item.keyId)}/>: <></> }
                            </OFieldValue>

                        </div>
                        <div className="field grid">
                            <OFieldLabel className="col-2">{t('screens.settings.did.attributes.keyAlias.label')}</OFieldLabel>
                            <OFieldValue className="col-10">{item.alias}</OFieldValue>
                        </div>
                        <div className="field grid">
                            <OFieldLabel className="col-2">{t('screens.settings.did.attributes.keyPurposes.label')}</OFieldLabel>
                            <OFieldValue className="col-10">{item.purposes.join(", ")}</OFieldValue>
                        </div>
                        <hr />
                    </div>)}
                    {did.singleItem?.type === 'ebsi' ? <Button className="m-1" label="Add key" disabled={isLoading} onClick={() => handleAddKey()} raised/>: <></> }
                </OCard>

                <OCard title={t('screens.settings.did.services')} className="mt-5 mb-4">
                    {did.singleItem?.services?.map((item, index) => <div key={index}>
                        <div className="field grid" >
                            <OFieldLabel className="col-2">{t('fields.didService.single.serviceId')}</OFieldLabel>
                            <OFieldValue className="col-9">{item.serviceId}</OFieldValue>
                            {(did.singleItem?.type === 'ebsi' || did.singleItem?.type === 'web') ? <Button label="Delete" outlined={true} onClick={() => handleDeleteServiceEndpoint(item.serviceId)}/>: <></> }
                        </div>
                        <div className="field grid">
                            <OFieldLabel className="col-2">{t('fields.didService.single.serviceType')}</OFieldLabel>
                            <OFieldValue className="col-10">{item.serviceType}</OFieldValue>
                        </div>
                        <div className="field grid">
                            <OFieldLabel className="col-2">{t('fields.didService.single.serviceEndpoint')}</OFieldLabel>
                            <OFieldValue className="col-10">{isJsonString(item.serviceEndpoint) ? <JsonView value={JSON.parse(item.serviceEndpoint)} /> : <TextWithExternalLink link={item.serviceEndpoint} /> }</OFieldValue>
                        </div>
                        <hr />
                    </div>)}
                    {(did.singleItem?.type === 'ebsi' || did.singleItem?.type === 'web') && !isEditing ? <Button className="m-1" label="Add service" onClick={() => setIsEditing(true)} raised/>: <></> }
                </OCard>

                {isEditing ? <OCard className="mt-5 mb-4">
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.settings.did.attributes.serviceId.label')}
                                        placeHolder={t('screens.settings.did.attributes.serviceId.placeHolder')}
                                        value={serviceId}
                                        onChangeValue={(value) => setServiceid(value)}/>
                    <DropdownWithLabel label={t('screens.settings.did.attributes.serviceType.label')} className="mb-3"
                                       value={selectedServiceType} onChangeValue={(e) => onSelectItem(e)}
                                       options={serviceTypes} optionLabel='label'/>
                    <TextInputWithLabel className="mb-3"
                                        label={t('screens.settings.did.attributes.serviceEndpoint.label')}
                                        placeHolder={t('screens.settings.did.attributes.serviceEndpoint.placeHolder')}
                                        value={serviceEndpoint}
                                        onChangeValue={(value) => setServiceEndpoint(value)}/>
                    <Button className="m-1" label="Cancel" onClick={() => setIsEditing(false)}/>
                    <Button className="m-1" label="Save" onClick={() => handleSaveServiceEndpoint()}/>
                </OCard> : <></> }


                <Dialog header="Document" visible={isVisible} style={{width: '50vw'}} onHide={() => setIsVisible(false)}>
                    <p className="m-0">
                        <pre>{did.singleItem ? JSON.stringify(did.singleItem?.document, null, 2) : "No DID"}</pre>
                    </p>
                </Dialog>
                <div className="grid pt-4">
                    <Button className="m-1" label="Show" severity="secondary" icon="pi pi-external-link" onClick={() => setIsVisible(true)}/>
                    <Button className="m-1" label={t('screens.settings.did.setDefault.buttonLabel')} severity="secondary" onClick={setDefaultDidConfirmDialog}/>

                </div>
            </div>
        </>
    );
};
