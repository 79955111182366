import React from 'react';
import { OCard } from './OCard';
import { Logo, OFieldLabel, OFieldValue, OH3 } from '../atoms';
import { useTranslation } from 'react-i18next';
import { TextToHtml } from './TextToHtml';
import { OrganizationInfo } from '../../state/slices/model/OrganizationInfo';
import { Image } from 'primereact/image';
import { ClipboardCopyElement } from './ClipboardCopyElement';
import { TextWithExternalLink } from './TextWithExternalLink';
import { Button } from 'primereact/button';

interface OrganizationInformationProps {
    className?: string | undefined;
    organizationInfo?: OrganizationInfo;
    nameFallback?: string;
    did?: string;
    onEditClicked?: () => void;
}

export const OrganizationInformation: React.FC<OrganizationInformationProps> = (props) => {
    const {t} = useTranslation();

    return (
        <OCard className="mb-4">
            <div className="grid">
                <div className="col-12 md:col-3">
                    <div className="flex align-items-center justify-content-center mr-2 p-8"
                         style={{borderColor: "#CBD5E0", maxHeight: "161px", maxWidth: "193px", backgroundColor: "#FFFFFF", borderWidth: 1, borderStyle: "solid"}}>
                        {(props.organizationInfo?.base64EncodedLogo === undefined) && (
                            <Logo className="flex align-self-start"/>
                        )}
                        {(props.organizationInfo?.base64EncodedLogo !== undefined) && (
                            <Image src={props.organizationInfo?.base64EncodedLogo} alt="Logo" width="120"/>
                        )}
                    </div>
                </div>
                <div className="col-12 md:col-9">
                    <div className="font-semibold text-2xl pt-2">{props.organizationInfo?.organizationName ? props.organizationInfo?.organizationName : props.nameFallback}</div>
                    <div className="text-sm pt-2 flex align-items-center" style={{color: "#757575"}}>
                        <div className="mr-1">{t('fields.did.single.typeDescription')}:</div>
                        <ClipboardCopyElement copyValue={props.did}><TextToHtml value={props.did}/></ClipboardCopyElement>
                    </div>
                    <div className="pt-2">{props.organizationInfo?.addressStreet}</div>
                    <div className="pt-2">{props.organizationInfo?.addressPostalCode} {props.organizationInfo?.addressCity} </div>
                    <div className="pt-2">{props.organizationInfo?.addressCountry}</div>
                    <div className="pt-2">{props.organizationInfo?.phoneNumber}</div>
                    <div className="pt-2">{props.organizationInfo?.email}</div>
                    <div className="pt-2"><TextWithExternalLink link={props.organizationInfo?.website}/></div>
                    {props.onEditClicked ? <Button className="mt-4" onClick={props.onEditClicked}>{t('screens.publicProfileDetails.editContactInformation')}</Button> : <></>}
                </div>
            </div>
        </OCard>
    );
};

