import React from 'react';
import { IconProps } from './IconProps';


export const Grid: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M8.33333 2.51172H2.5V8.34505H8.33333V2.51172Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 2.51172H11.6667V8.34505H17.5V2.51172Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 11.6784H11.6667V17.5117H17.5V11.6784Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.33333 11.6784H2.5V17.5117H8.33333V11.6784Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};
