import React, { FC, useEffect } from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AuthenticationStateHandler from './components/organisms/AuthenticationStateHandler';
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { Login } from './components/pages/Login';
import {
    ApiKey,
    CredentialDetails,
    CredentialIssuerCredentialDefinitionForm,
    CredentialIssuerDefinitionForm,
    CredentialIssuerDefinitionList,
    CredentialsList,
    CredentialTypeList,
    DidDetails,
    DidList, InvoiceDetails,
    IssueCredential,
    Loading,
    MainMenuLayout,
    PresentationDefinitionFormConfigureCredentials,
    PresentationDefinitionFormSelectCredentials,
    PresentationDefinitionFormTestConfig,
    PresentationDefinitionList,
    SearchOrganizations,
    Signup,
    SignupExistingCompanyRetrieveLpid,
    SignupProfile,
    TrustedContactCreate,
    TrustedContactDetails,
    TrustedContactList,
    TrustedIssuerListList
} from './components';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './theme/themes/mytheme/theme.scss';
import 'primeflex/primeflex.scss';
import { OidcUrlHandler } from './components/pages/OidcUrlHandler';
import { VerifyCredential } from './components/pages/VerifyCredential';
import { DidCreate } from './components/pages/DidCreate';
import { HistoryList } from "./components/pages/HistoryList";
import { IssuerTrust } from './components/pages/IssuerTrust';
import { IssuedCredentialsList } from './components/pages/IssuedCredentialsList';
import { IssuedCredentialDetails } from './components/pages/IssuedCredentialDetails';
import { CredentialStatusListList } from './components/pages/CredentialStatusListList';
import { CredentialStatusListDetails } from './components/pages/CredentialStatusListDetails';
import { PublicProfileDetails } from './components/pages/profile/PublicProfileDetails';
import { PublicProfileDetailsEditPage, SelectCredentialToAdd } from './components/pages/profile';
import { InvoiceCreate } from './components/pages/application/InvoiceCreate';
import { InboxList } from './components/pages/inbox/InboxList';
import { TrustedIssuerListForm } from './components/pages/trustedissuerlist/TrustedIssuerListForm';
import { useAppDispatch } from './state';
import { useKeycloak } from '@react-keycloak/web';
import { getWallet } from './state/slices/wallet';
import { SignupExistingCompanyAcceptLpid } from './components/pages/signup/SignupExistingCompanyAcceptLpid';
import { Dashboard } from './components/pages/dashboard/Dashboard';
import { SignupComplete } from './components/pages/signup/SignupComplete';

export const AppRoutes: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();

    useEffect(() => {
        dispatch(getWallet({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    const router = createBrowserRouter([
        {
            path: "*", element: <Root/>, handle: {
                crumb: () => "Home"
            }
        },
    ]);

    function Root() {
        return (
            <AuthenticationStateHandler>
                <AuthenticationStateHandler.Loading>
                    <Loading/>
                </AuthenticationStateHandler.Loading>
                <AuthenticationStateHandler.AuthenticationExpired>
                    <div>Expired token</div>
                </AuthenticationStateHandler.AuthenticationExpired>
                <AuthenticationStateHandler.UnAuthenticated>
                    <Login/>
                </AuthenticationStateHandler.UnAuthenticated>
                <AuthenticationStateHandler.Authenticated>
                    <Routes>
                        <Route path="/" element={<MainMenuLayout/>}>
                            <Route path="*" element={<OidcUrlHandler child={<Dashboard/>}/>}/>
                            <Route path="/" element={<OidcUrlHandler child={<Dashboard/>}/>}/>
                            <Route path="/credentials" element={<CredentialsList/>}/>
                            <Route path="/did" element={<DidList/>}/>
                            <Route path="/did/:didId" element={<DidDetails/>}/>
                            <Route path="/did/new" element={<DidCreate/>}/>
                            <Route path="/credential/details/:credentialId" element={<CredentialDetails/>} handle={{crumb: (data: any) => <span>{data.threadName}</span>}}/>
                            <Route path="/credential/new" element={<CredentialTypeList/>}/>
                            <Route path="/credential/issue" element={<IssueCredential/>}/>
                            <Route path="/credential/verify" element={<VerifyCredential/>}/>
                            <Route path="/issuedCredential" element={<IssuedCredentialsList/>}/>
                            <Route path="/issuedCredential/details/:issuedCredentialId" element={<IssuedCredentialDetails/>} handle={{crumb: (data: any) => <span>{data.threadName}</span>}}/>
                            <Route path="/presentationDefinition" element={<PresentationDefinitionList/>}/>
                            <Route path="/presentationDefinition/new" element={<PresentationDefinitionFormSelectCredentials/>}/>
                            <Route path="/presentationDefinition/edit/:presentationDefinitionId" element={<PresentationDefinitionFormSelectCredentials/>}/>
                            <Route path="/presentationDefinition/configure" element={<PresentationDefinitionFormConfigureCredentials/>}/>
                            <Route path="/presentationDefinition/test/:presentationDefinitionId" element={<PresentationDefinitionFormTestConfig/>}/>
                            <Route path="/credentialIssuerDefinition" element={<CredentialIssuerDefinitionList/>}/>
                            <Route path="/credentialIssuerDefinition/new" element={<CredentialIssuerDefinitionForm/>}/>
                            <Route path="/credentialIssuerDefinition/edit/:credentialIssuerDefinitionId" element={<CredentialIssuerDefinitionForm/>}/>
                            <Route path="/credentialIssuerDefinition/:credentialIssuerDefinitionId/credentialIssuerCredential/new" element={<CredentialIssuerCredentialDefinitionForm/>}/>
                            <Route path="/credentialIssuerDefinition/:credentialIssuerDefinitionId/credentialIssuerCredential/edit/:credentialIssuerCredentialDefinitionId" element={<CredentialIssuerCredentialDefinitionForm/>}/>
                            <Route path="/trustedIssuer" element={<IssuerTrust/>}/>
                            <Route path="/history" element={<HistoryList/>}/>
                            <Route path="/credentialStatus" element={<CredentialStatusListList/>}/>
                            <Route path="/credentialStatus/:didId" element={<DidDetails/>}/>
                            <Route path="/credentialStatus/new" element={<CredentialStatusListDetails/>}/>
                            <Route path="/apiKey" element={<ApiKey/>}/>
                            <Route path="/profile" element={<PublicProfileDetails/>}/>
                            <Route path="/profile/edit" element={<PublicProfileDetailsEditPage/>}/>
                            <Route path="/profile/selectCredentialToAdd" element={<SelectCredentialToAdd/>}/>
                            <Route path="/invoice/send" element={<InvoiceCreate/>}/>
                            <Route path="/invoice/details/:inboxId" element={<InvoiceDetails/>}/>
                            <Route path="/inbox" element={<InboxList/>}/>
                            <Route path="/trustedContacts" element={<TrustedContactList/>}/>
                            <Route path="/trustedContacts/new" element={<TrustedContactCreate/>}/>
                            <Route path="/trustedContacts/search" element={<SearchOrganizations/>}/>
                            <Route path="/trustedContacts/details/:trustedContactId" element={<TrustedContactDetails/>}/>
                            <Route path="/trustedIssuerList" element={<TrustedIssuerListList/>}/>
                            <Route path="/trustedIssuerList/new" element={<TrustedIssuerListForm/>}/>
                            <Route path="/trustedIssuerList/edit/:trustedIssuerListId" element={<TrustedIssuerListForm/>}/>

                            <Route path="/signup" element={<Signup/>}/>
                            <Route path="/signup/existingCompany/retrieveLpid" element={<SignupExistingCompanyRetrieveLpid/>}/>
                            <Route path="/signup/existingCompany/acceptLpid" element={<SignupExistingCompanyAcceptLpid/>}/>
                            <Route path="/signup/existingCompany/complete" element={<SignupComplete/>}/>
                            <Route path="/signup/existingCompany/profile" element={<SignupProfile/>}/>
                        </Route>
                    </Routes>
                </AuthenticationStateHandler.Authenticated>
            </AuthenticationStateHandler>
        );
    }

    return <RouterProvider router={router}/>;
}
