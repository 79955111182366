import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DidCredentialView, DidServiceView, OCard } from '../../molecules';
import { useTranslation } from 'react-i18next';
import {
    Credential, deleteServiceFromWalletProfile, deleteTrustedContact,
    DidService, getTrustedContact, setToastMessage,
    trustedContactSelector,
    useAppDispatch
} from '../../../state';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { OrganizationInformation } from '../../molecules/OrganizationInformation';
import { Button } from 'primereact/button';

interface ServiceCredential {
    service: DidService,
    credential: Credential
}

export const TrustedContactDetails: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();
    const {trustedContactId} = useParams();

    let trustedContacts = useSelector(trustedContactSelector);

    useEffect(() => {
        dispatch(getTrustedContact({jwtToken: keycloak.token!, trustedContactId: trustedContactId!}));
    }, [keycloak.token]);

    function hasCredentials() : boolean{
        let findIndex = trustedContacts.singleItem?.services?.findIndex(value => value.credentials !== undefined && value.credentials.length > 0);
        if (findIndex === undefined) {
            return false;
        }
        return (findIndex > -1);
    }

    function hasServices() {
        const numberOfServices = (trustedContacts.singleItem?.services === undefined) ? 0 : (trustedContacts.singleItem?.services?.filter(value => value.credentials === undefined || value.credentials.length === 0)).length;
        return numberOfServices > 0;

    }

    function confirmDeleteContact() {
        confirmDialog({
            header: t('screens.trustedContactDetails.deleteContactConfirm.title'),
            message: <div         dangerouslySetInnerHTML={{
                __html: t('screens.trustedContactDetails.deleteContactConfirm.message'),
            }}></div>,
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleDeleteContact(),
            reject: () => {
            }
        });
    }

    function handleDeleteContact() {
        dispatch(deleteTrustedContact({jwtToken: keycloak.token!, trustedContactId: trustedContactId!})).then((response) => {
            if (response.type.includes('fulfilled')) {
                dispatch(setToastMessage({message: "Contact removed"}));
                navigate(`/trustedContacts`)
            }
        });
    }

    return (
        <>
            <OrganizationInformation organizationInfo={trustedContacts.singleItem?.organizationInfo}
                                     nameFallback={trustedContacts.singleItem?.name}
                                     did={trustedContacts.singleItem?.did} />

            <div className="m-4 text-center" style={{color: "#757575"}}>
                {t('screens.trustedContactDetails.description')}
            </div>
            <div className="font-semibold text-xl mb-2">{t('fields.credential.plural.typeDescription')}</div>
            {(!hasCredentials()) && (
                <OCard className="mb-4">
                    <div className="flex align-items-center flex-wrap flex-column">
                        <div className="m-4 text-center max-w-25rem">{t('screens.trustedContactDetails.emptyCredentialListMessage')}</div>
                    </div>
                </OCard>
            )}
            {(hasCredentials()) && (
                <>

                    {trustedContacts.singleItem?.services?.filter(service => service.credentials !== undefined && service.credentials.length > 0)
                        .flatMap((service: DidService) => service.credentials.map(credential => {
                            return {service, credential} as ServiceCredential;
                        }))
                        .map((serviceCredential: ServiceCredential, index: number) => (
                            <DidCredentialView key={index} className="mb-4" credential={serviceCredential.credential} />
                        ))
                    }
                </>
            )}


            <div className="font-semibold text-xl mb-2">{t('fields.didService.plural.typeDescription')}</div>
            {(!hasServices()) && (
                <OCard className="mb-4">
                    <div className="flex align-items-center flex-wrap flex-column">
                        <div className="m-4 text-center max-w-25rem">{t('screens.trustedContactDetails.emptyServiceListMessage')}</div>
                    </div>
                </OCard>
            )}

            {(hasServices()) && (
                <>
                    {trustedContacts.singleItem?.services?.filter(service => service.credentials === undefined || service.credentials.length === 0)
                        .map((service, index) => (
                            <DidServiceView key={index} className="mb-4" didService={service}/>
                        ))
                    }
                </>
            )}
            <Button className="p-2 m-2" onClick={() => confirmDeleteContact()} >{t('screens.trustedContactDetails.delete')}</Button>
        </>
    );
};
