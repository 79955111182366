import React, { PropsWithChildren } from 'react';

interface AlterMessageProps {
    className?: string | undefined;
    type: 'info' | 'error'
    title: string;
    allowHtml?: boolean;
}

export const AlertMessage: React.FC<AlterMessageProps & PropsWithChildren> = (props) => {
    const backgroundColor = props.type === 'info' ? '#EAF6FC' : '#f18e8e';
    return (
        <div className={props.className} style={{backgroundColor: backgroundColor, padding: '1rem', borderRadius: '16px', border: '1px solid #1875A9'}}>
            <div className="flex flex-column justify-content-center">
                <div className="flex flew-row align-items-baseline"><span className="pi pi-info-circle mr-2"/><h2 className="text-xl font-semibold" style={{color: '#1C1C1C', marginBlockStart: '1px'}}>{props.title}</h2></div>
                <p style={{color: 'rgba(28, 28, 28, 0.80)'}}>{props.children}</p>
            </div>
        </div>
    );
};

