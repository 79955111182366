import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OStepper } from '../molecules/OStepper';
import { MenuItem } from 'primereact/menuitem';


export interface SignupExistingCompanyStepperProps {
    className?: string;
    selectedIndex: number;
}

export const SignupExistingCompanyStepper: FC<SignupExistingCompanyStepperProps> = (props) => {
    const {t} = useTranslation();

    const items: MenuItem[] = [
        {
            label: 'Step 1',
        },
        {
            label: 'Step 2',
        },
        {
            label: 'Step 3',
        },
        {
            label: 'Step 4',
        }
    ];


    return (
        <OStepper items={items} selectedIndex={props.selectedIndex} className={props.className}/>
    )
}

