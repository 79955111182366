import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IssueCredentialForm } from '../organisms';


export const IssueCredential: FC = () => {
    const {t} = useTranslation();

    return (
        <main>
            <h1 className="text-3xl font-bold">{t('screens.issueCredentials.title')}</h1>
            <IssueCredentialForm onAcceptRoute="/credentials" onRejectRoute="/"/>
        </main>
    );
}
