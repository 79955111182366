import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconConnectToExistingOrganisation } from '../../atoms';
import { OCard } from '../../molecules';
import { Button } from 'primereact/button';
import { getSignupMetaData } from '../../../state/slices/signup';
import { useAppDispatch } from '../../../state';
import { useKeycloak } from '@react-keycloak/web';
import { issueCredentialType } from '../../../state/slices/credentialtype/CredentialTypeApi';
import { SignupExistingCompanyStepper } from '../../organisms/SignupExistingCompanyStepper';

export const SignupExistingCompanyRetrieveLpid: FC = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();

    function handleExistingOrganization() {
        dispatch(getSignupMetaData({jwtToken: keycloak.token!})).then((response) => {
            if (response.type.includes('fulfilled')) {
                const lpidTypeId = response.payload.lpidCredentialType.id;
                const redirectUrl = window.location.origin + "/signup/existingCompany/acceptLpid"
                dispatch(issueCredentialType({jwtToken: keycloak.token!, credentialTypeId: lpidTypeId, redirectUrl}))
                    .then((data) => {
                            if (data.type.includes('fulfilled')) {
                                window.location.replace(data.payload.issueUrl);
                            }
                        }
                    );

            }
        });
    }

    return <main className="w-full">
        <h1 className="text-3xl font-bold mt-8">{t('screens.signupExistingCompany.title')}</h1>
        <p className="mb-8">{t('screens.signupExistingCompany.intro')}</p>
        <SignupExistingCompanyStepper selectedIndex={1} className="mb-4"/>
        <OCard className="flex flex-column" style={{minHeight: '360px'}}>
            <div className="flex justify-content-start">
                <IconConnectToExistingOrganisation className="h-5rem w-auto"/>
            </div>
            <h2 className="text-xl font-bold">{t('screens.signupExistingCompany.step1.title')}</h2>
            <p>{t('screens.signupExistingCompany.step1.intro')}</p>
            <div className="flex-grow-1"></div>
            <Button className="w-full mt-6" label={t('screens.signupExistingCompany.step1.actionButtonLabel')} onClick={handleExistingOrganization}/>
        </OCard>
    </main>;


}
