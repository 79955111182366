import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SignupExistingCompanyStepper } from '../../organisms/SignupExistingCompanyStepper';
import { OCard } from '../../molecules';
import { useKeycloak } from '@react-keycloak/web';
import { useAppDispatch } from '../../../state';
import { useNavigate } from 'react-router-dom';
import { PublicProfileDetailsForm } from '../../organisms/PublicProfileDetailsForm';

export const SignupProfile: FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();

    return (
        <main className="w-full">
            <h1 className="text-3xl font-bold mt-8">{t('screens.signupExistingCompany.title')}</h1>
            <p className="mb-8">{t('screens.signupExistingCompany.intro')}</p>
            <SignupExistingCompanyStepper selectedIndex={0} className="mb-6"/>

            <OCard className="flex flex-column" style={{minHeight: '360px'}}>
                <h2 className="text-xl font-bold">{t('screens.signupExistingCompany.companyProfile.title')}</h2>
                <p>{t('screens.signupExistingCompany.companyProfile.intro')}</p>
                <PublicProfileDetailsForm
                    onSaved={() => navigate('/signup/existingCompany/retrieveLpid')}
                    onSaveButtonLabel={t('screens.signupExistingCompany.companyProfile.actionButtonLabel')}
                />
            </OCard>

        </main>
    );
}
