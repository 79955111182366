import * as React from 'react';
import { FC } from 'react';
import { OCard } from '../../molecules';
import { PublicProfileDetailsForm } from '../../organisms/PublicProfileDetailsForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const PublicProfileDetailsEditPage: FC = () => {
    const {t} = useTranslation();
    let navigate = useNavigate();
    return (
        <>
            <OCard className="">
                <PublicProfileDetailsForm
                    onSaved={() => navigate('/profile')}
                    onSaveButtonLabel={t('generic.save')}
                />
            </OCard>
        </>
    );
};
