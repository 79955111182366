import React from 'react';
import { IconProps } from './IconProps';

export const ArrowRight: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.3335 7.99992H12.6668M12.6668 7.99992L8.00016 3.33325M12.6668 7.99992L8.00016 12.6666" stroke="#1E1E1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};
