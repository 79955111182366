import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { CredentialType } from '../credentialtype';
import { getSignupMetaData } from './SignupApi';

export interface Signup {
    lpidCredentialType: CredentialType;
}

export interface SignupState extends GenericState<Signup> {
}

export const signupSlice = createSlice({
    name: 'Signup',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, getSignupMetaData);
    },
});

