import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '../atoms';

export const Loading: FC = () => {

    const {t} = useTranslation();

    return <main className="w-full min-h-screen flex flex-column align-items-center">
        <div></div>
        <div className="flex-grow-1 flex align-items-center justify-content-center">
            <i className="pi pi-spin pi-spinner mr-1" style={{ fontSize: '1rem' }}></i><h1>Loading</h1>
        </div>
        <div className="mb-4"><Logo/></div>
    </main>
}
