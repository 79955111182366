import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import { DataViewPageEvent } from 'primereact/dataview';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { saveTrustedContact, searchOrganizations, trustedContactSelector, useAppDispatch } from '../../../state';
import { DataTable, DataTableSelectEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { InfoCard, SearchEntry } from '../../molecules';
import { Pencil } from '../../atoms';
import { setPresentationDefinitionForm } from '../../../state/slices/presentationdefinitionform';
import { TrustedContact } from '../../../state/slices/model/TrustedContact';
import { Button } from 'primereact/button';

export const SearchOrganizations: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>();
    const [trustedContact, setTrustedContact] = useState<TrustedContact>();
    const [isLoading, setIsLoading] = useState<boolean>();

    const {t} = useTranslation();

    let trustedContacts = useSelector(trustedContactSelector);

    const initialParams: DataViewPageEvent = {
        first: 0,
        rows: 12,
        page: 0,
        pageCount: 1
    };

    const [tableParams, setTableParams] = useState<DataViewPageEvent>(initialParams);

    useEffect(() => {
        dispatch(searchOrganizations({jwtToken: keycloak.token!, searchText: searchText}));
    }, [keycloak.token, searchText]);

    function onPageChange(paginatorPageChangeEvent: PaginatorPageChangeEvent) {
        setTableParams({...tableParams, page: paginatorPageChangeEvent.page, rows: paginatorPageChangeEvent.rows});
    }

    const organizationBodyTemplate = (trustedRelation: TrustedContact) => {
        return <div>
            <div className='font-semibold'>{trustedRelation.name}</div>
            <div className='text-gray-300'>{trustedRelation.did}</div>
        </div>;
    };

    const actionBodyTemplate = (trustedContact: TrustedContact) => {
        return <Button className="p-2 m-2" onClick={event => createTrustedContact(trustedContact)}>{t('screens.searchOrganization.add')}</Button>;
    };

    function createTrustedContact(trustedContact: TrustedContact) {
        setIsLoading(true)
        dispatch(saveTrustedContact({jwtToken: keycloak.token!, trustedContact: trustedContact!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                navigate(`/trustedContacts`)
            }
        });
    }

    function onRowSelect(event: DataTableSelectEvent) {
        setIsLoading(true)
        dispatch(saveTrustedContact({jwtToken: keycloak.token!, trustedContact: trustedContact!})).then((response) => {
            setIsLoading(false);
            if (response.type.includes('fulfilled')) {
                navigate(`/trustedContacts`)
            }
        });
    }

    function onSearch(searchText: string | undefined) {
        setSearchText(searchText);
    }

    function createNew() {
        dispatch(setPresentationDefinitionForm({}));
        navigate('/trustedContacts/new');
    }

    return (
        <>
            <div>
                <InfoCard className="mb-4" icon={<Pencil height="30" width="30"/>}
                          title={t('screens.searchOrganization.title')}
                          description={t('screens.searchOrganization.intro')}/>

                <SearchEntry title={t('screens.searchOrganization.search.title')}
                             placeHolder={t('screens.searchOrganization.search.placeholder')}
                             onSearch={onSearch} className="mb-4"/>
                <DataTable value={trustedContacts.list}
                           rows={10}
                           rowsPerPageOptions={[12, 24, 36]}
                           tableStyle={{minWidth: '50rem'}}
                           selectionMode="single"
                           rowHover={true}>
                    <Column field="name" header={t('screens.searchOrganization.organization')} style={{width: '80%'}} body={organizationBodyTemplate}></Column>
                    <Column header={t('screens.searchOrganization.action')} style={{width: '20%'}} body={actionBodyTemplate}></Column>
                </DataTable>
            </div>
            <Button className="p-2 m-2" onClick={event => createNew()}>{t('screens.searchOrganization.create')}</Button>
        </>
    );
}
