import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState, Inbox } from '../model';
import { addGenericPageableStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getInbox, getInboxItem } from './InboxApi';
import { getDid } from '../did';


export interface InboxState extends GenericPageableState<Inbox> {
}

export const inboxSlice = createSlice({
    name: 'inbox',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getInbox);
        addGenericStateSingleBuilders(builder, getInboxItem);
    }
});
