import React, { useMemo } from 'react';
import { OCard } from './OCard';
import { Avatar } from 'primereact/avatar';
import { TextToHtml } from './TextToHtml';

interface InfoCardProps {
    className?: string | undefined;
    icon: React.ReactNode;
    title: string;
    description: string;
    allowHtml?: boolean;
}

export const InfoCard: React.FC<InfoCardProps> = (props) => {
    const description = useMemo(() => {
        return props.allowHtml ? <TextToHtml value={props.description}/> : props.description;
    }, [props.description]);
    return (
        <OCard className={props.className}>
            <div className="flex">
                <Avatar shape="circle" size="xlarge" style={{backgroundColor: '#FFFFFF', minWidth: '4rem', maxWidth: '4rem'}}>
                    {props.icon}
                </Avatar>
                <div className="flex flex-column justify-content-center" style={{marginLeft: 24}}>
                    <h1 className="text-xl font-semibold pb-2" style={{color: '#1C1C1C'}}>{props.title}</h1>
                    <p style={{color: 'rgba(28, 28, 28, 0.80)'}}>{description}</p>
                </div>
            </div>
        </OCard>
    );
};

