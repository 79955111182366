import React, { useMemo } from 'react';
import { OCard } from './OCard';
import { TextToHtml } from './TextToHtml';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { useNavigate } from 'react-router-dom';

interface DashboardCardProps {
    className?: string | undefined;
    title: string;
    description: string;
    allowHtml?: boolean;
    linkText: string;
    linkNavigation: string;
    color: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
    let navigate = useNavigate();
    const description = useMemo(() => {
        return props.allowHtml ? <TextToHtml value={props.description}/> : props.description;
    }, [props.description]);
    return (
        <OCard className={"flex-grow-1 " + props.className} style={{alignSelf: "stretch", padding: 0, border: 'solid 1px #E1E1E1'}}>
            <div className="flex-grow-1" style={{height: "16.592px", borderRadius: "16px 16px 0 0", background: props.color}}></div>
            <div className="align-self-stretch flex-grow-1" style={{padding: 24}}>
                <div className="flex flex-grow-1">
                    <div className="flex flex-column justify-content-center gap-2 flex-grow-1">
                        <div>
                            <h2 className="text-xl font-semibold pb-2" style={{color: '#1C1C1C'}}>{props.title}</h2>
                            <p style={{color: 'rgba(28, 28, 28, 0.80)'}}>{description}</p>
                        </div>
                        <div className="flex align-self-end align-items-center gap-2 cursor-pointer"
                             onClick={(event) => navigate(props.linkNavigation)}>
                            {props.linkText}
                            <ArrowRight width="16" height="16"/>
                        </div>
                    </div>
                </div>
            </div>
        </OCard>
    );
};

