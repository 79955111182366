import React from 'react';
import { IconProps } from './IconProps';


export const Vault: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none" className={className}>
                <path d="M27 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V24C3 24.5304 3.21071 25.0391 3.58579 25.4142C3.96086 25.7893 4.46957 26 5 26H7V28C7 28.2652 7.10536 28.5196 7.29289 28.7071C7.48043 28.8946 7.73478 29 8 29C8.26522 29 8.51957 28.8946 8.70711 28.7071C8.89464 28.5196 9 28.2652 9 28V26H23V28C23 28.2652 23.1054 28.5196 23.2929 28.7071C23.4804 28.8946 23.7348 29 24 29C24.2652 29 24.5196 28.8946 24.7071 28.7071C24.8946 28.5196 25 28.2652 25 28V26H27C27.5304 26 28.0391 25.7893 28.4142 25.4142C28.7893 25.0391 29 24.5304 29 24V7C29 6.46957 28.7893 5.96086 28.4142 5.58579C28.0391 5.21071 27.5304 5 27 5ZM27 24H5V7H27V15H24.915C24.6643 13.5166 23.8654 12.1815 22.6767 11.2594C21.4881 10.3372 19.9963 9.89521 18.4972 10.021C16.998 10.1468 15.6008 10.8312 14.5824 11.9386C13.5641 13.046 12.9989 14.4956 12.9989 16C12.9989 17.5044 13.5641 18.954 14.5824 20.0614C15.6008 21.1688 16.998 21.8532 18.4972 21.979C19.9963 22.1048 21.4881 21.6628 22.6767 20.7406C23.8654 19.8185 24.6643 18.4834 24.915 17H27V24ZM20.73 15C20.5099 14.6187 20.1701 14.3207 19.7633 14.1522C19.3566 13.9838 18.9056 13.9542 18.4803 14.0681C18.055 14.1821 17.6793 14.4332 17.4112 14.7825C17.1432 15.1318 16.9979 15.5597 16.9979 16C16.9979 16.4403 17.1432 16.8682 17.4112 17.2175C17.6793 17.5668 18.055 17.8179 18.4803 17.9319C18.9056 18.0458 19.3566 18.0162 19.7633 17.8478C20.1701 17.6793 20.5099 17.3813 20.73 17H22.875C22.6316 17.9427 22.0528 18.7642 21.247 19.3106C20.4412 19.857 19.4638 20.0908 18.498 19.9681C17.5322 19.8454 16.6443 19.3747 16.0007 18.6442C15.3571 17.9137 15.002 16.9736 15.002 16C15.002 15.0264 15.3571 14.0863 16.0007 13.3558C16.6443 12.6253 17.5322 12.1546 18.498 12.0319C19.4638 11.9092 20.4412 12.143 21.247 12.6894C22.0528 13.2358 22.6316 14.0573 22.875 15H20.73Z" fill="#343330"/>
            </svg>
    )
};
