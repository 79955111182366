import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconConnectToExistingOrganisation, IconConnectToNewOrganisation } from '../../atoms';
import { OCard } from '../../molecules';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const Signup: FC = () => {
    const {t} = useTranslation()
    let navigate = useNavigate();

    function handleExistingOrganization() {
        navigate(`/signup/existingCompany/profile`)
    }

    return <main className="w-full">
        <h1 className="text-3xl font-bold mt-8">{t('screens.signupStep1.title')}</h1>
        <p>{t('screens.signupStep1.intro')}</p>

        <div className="grid mt-3">
            <div className="col-12 md:col-6 ">
                <OCard className="flex flex-column" style={{minHeight: '360px'}}>
                    <div className="flex align-content-center justify-content-center">
                        <IconConnectToExistingOrganisation className="h-5rem w-auto"/>
                    </div>
                    <h2 className="text-xl font-bold">{t('screens.signupStep1.connectExisting.title')}</h2>
                    <p>{t('screens.signupStep1.connectExisting.intro')}</p>
                    <div className="flex-grow-1"></div>
                    <Button className="w-full mt-6" label={t('screens.signupStep1.connectExisting.actionButtonLabel')} onClick={handleExistingOrganization}/>
                </OCard>
            </div>
            <div className="col-12 md:col-6 " style={{minHeight: '425px'}}>
                <OCard className="flex flex-column" style={{minHeight: '360px'}}>
                    <div className="flex align-content-center justify-content-center">
                        <IconConnectToNewOrganisation className="h-5rem w-auto"/>
                    </div>
                    <h2 className="text-xl font-bold">{t('screens.signupStep1.connectNewCompany.title')}</h2>
                    <p>{t('screens.signupStep1.connectNewCompany.intro')}</p>
                    <div className="flex-grow-1"></div>
                    <Button className="w-full mt-6" label={t('screens.signupStep1.connectNewCompany.actionButtonLabel')}/>
                </OCard>
            </div>

        </div>
    </main>;


}
