import React from 'react';
import { IconProps } from './IconProps';


export const Inbox: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
    <svg width={width} height={height} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path
            d="M14.6667 7.00008H10.6667L9.33337 9.00008H6.66671L5.33337 7.00008H1.33337M14.6667 7.00008V11.0001C14.6667 11.3537 14.5262 11.6928 14.2762 11.9429C14.0261 12.1929 13.687 12.3334 13.3334 12.3334H2.66671C2.31309 12.3334 1.97395 12.1929 1.7239 11.9429C1.47385 11.6928 1.33337 11.3537 1.33337 11.0001V7.00008M14.6667 7.00008L12.3667 2.40675C12.2563 2.18461 12.0862 1.99766 11.8753 1.86693C11.6645 1.73621 11.4214 1.66688 11.1734 1.66675H4.82671C4.57865 1.66688 4.33555 1.73621 4.12474 1.86693C3.91392 1.99766 3.74376 2.18461 3.63337 2.40675L1.33337 7.00008"
            stroke="#003273" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

)
};
