import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { addQueryParam } from '../slice';
import { Inbox } from '../model/Inbox';


export const getInbox = createAsyncThunk(
    'inbox/getInbox', ({jwtToken, page, pageSize}: { jwtToken: string | undefined, page: number, pageSize: number }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/inbox';
        url = addQueryParam(url, 'page', page);
        url = addQueryParam(url, 'size', pageSize);

        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getInboxItem = createAsyncThunk(
    'inbox/getInboxItem', ({jwtToken, inboxId}: { jwtToken: string | undefined, inboxId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get(`/inbox/${inboxId}`, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
