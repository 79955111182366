import React, { useMemo } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import './OStepper.css';

interface OStepperProps {
    className?: string | undefined;
    items: MenuItem[];
    selectedIndex: number;
}

export const OStepper: React.FC<OStepperProps> = (props) => {

    const items = useMemo(() => {
        return props.items.map((item , index)=> ({
            ...item,
            template: (menuItem: MenuItem, options: any) => itemRenderer(menuItem, index),
        }));
    }, [props.items]);

    const itemRenderer = (item: MenuItem, itemIndex: number) => {
        const isActiveItem = props.selectedIndex === itemIndex;
        const textColor = (itemIndex <= props.selectedIndex) ? 'var(--surface-900)' : 'var(--surface-200)';
        const borderStyle = (itemIndex <= props.selectedIndex) ? 'solid 2px ' + textColor : 'solid 1px ' + textColor;
        const backgroundColor = 'white'
        const icon = isActiveItem ? 'pi-circle-fill' : (itemIndex < props.selectedIndex ? 'pi-check' : '');
        const lineClass = (itemIndex <= props.selectedIndex) ? 'o-stepper-completed' : '';
        return (
            <span
                className={"inline-flex align-items-center justify-content-center align-items-center border-circle h-2rem w-2rem z-1 cursor-pointer " + lineClass}
                style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '-2rem', border: borderStyle }}
                // onClick={() => setActiveIndex(itemIndex)}
            >
                <i className={`pi ${icon} text-xs `} />
            </span>
        );
    };


    return (
        <Steps model={items} className={"o-stepper " +props.className} activeIndex={props.selectedIndex} readOnly={true}/>
    );
};

