import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { FormTextInput, FormTextInputWithLabel, InfoCard, OCard } from '../../molecules';
import { useTranslation } from 'react-i18next';
import { getTrustedIssuerList, getUser, saveTrustedIssuerList, trustedIssuerListSelector, useAppDispatch } from '../../../state';
import { useFieldArray, useForm } from 'react-hook-form';
import { Pencil } from '../../atoms';
import { TrustedIssuerList } from '../../../state/slices/model/TrustedIssuerList';

export const TrustedIssuerListForm: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();
    const {trustedIssuerListId} = useParams();

    let trustedIssuerList = useSelector(trustedIssuerListSelector);


    const formHook = useForm<TrustedIssuerList>({
        defaultValues: {}
    });
    const {handleSubmit, reset, control, formState: {errors}} = formHook;
    const {fields, append, remove} = useFieldArray({name: 'trustedIssuerListDids', control});

    useEffect(() => {
        if ((trustedIssuerListId !== undefined) && (trustedIssuerListId !== trustedIssuerList.singleItem?.id)) {
            dispatch(getTrustedIssuerList({jwtToken: keycloak.token!, trustedIssuerListId: trustedIssuerListId}));
        }
    }, [keycloak.token, trustedIssuerListId]);


    useEffect(() => {
        if ((trustedIssuerListId !== undefined) && (trustedIssuerList.singleItem !== undefined) && (trustedIssuerListId !== trustedIssuerList.singleItem?.id)) {
            reset(trustedIssuerList.singleItem);
        }
    }, [trustedIssuerListId, trustedIssuerList.singleItem]);

    const dids = fields;

    function addNewDid() {
        append({displayName: '', did: ''});
    }

    function removeDid(e: any, index: number) {
        remove(index);
        e.preventDefault();
    }

    return (
        <>
            <InfoCard className="mb-4" icon={<Pencil height="30" width="30"/>}
                      title={t('screens.trustedIssuerListForm.title')}
                      description={t('screens.trustedIssuerListForm.intro')}
                      allowHtml={true}/>

            <form onSubmit={handleSubmit((data) => {
                dispatch(saveTrustedIssuerList({jwtToken: keycloak.token!, trustedIssuerList: data})).then((response) => {
                    if (response.type.includes('fulfilled')) {
                        dispatch(getUser({jwtToken: keycloak.token!}));
                        setTimeout(() => { // Use timeout the give time to update the redux store.
                            navigate('/trustedIssuerList');
                        }, 250);
                    }
                });
            })}
            >
                <OCard className="">
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <FormTextInputWithLabel className="w-full mb-2"
                                                    label={t('fields.trustedIssuerList.single.displayName')}
                                                    placeHolder={t('screens.trustedIssuerListForm.fields.displayName.placeHolder')}
                                                    formHook={formHook}
                                                    controlName="displayName"
                                                    rules={{
                                                        required: {value: true, message: t('error.inputErrors.requiredField')}
                                                    }}
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                                    label={t('fields.trustedIssuerList.single.credentialType')}
                                                    placeHolder={t('screens.trustedIssuerListForm.fields.credentialType.placeHolder')}
                                                    formHook={formHook}
                                                    controlName="credentialType"
                                                    rules={{
                                                        required: {value: true, message: t('error.inputErrors.requiredField')},
                                                        pattern: {value: /^[^\s]+$/i, message: t('error.inputErrors.noSpacesAllowed')}
                                                    }}
                            />
                            <h2 className="mt-6 font-medium">{t('screens.trustedIssuerListForm.didListIntro')} </h2>
                            <div className="grid mt-2">
                                <div className="col-12 md:col-4">{t('fields.trustedIssuerListDid.single.displayName')}</div>
                                <div className="col-12 md:col-8">{t('fields.trustedIssuerListDid.single.did')}</div>
                                {dids !== undefined && dids.map((did, index) => {

                                    return (
                                        <div className="grid col-12 align-items-start" key={did.id}>
                                            <div className="col-12 md:col-4" key={"name" + did.id}>
                                                <FormTextInput className="w-full"
                                                               placeHolder={t('screens.trustedIssuerListForm.fields.did.displayName.placeHolder')}
                                                               value={dids[index].displayName}
                                                               formHook={formHook}
                                                               controlName={"trustedIssuerListDids[" + index + "].displayName"}
                                                               rules={{
                                                                   required: {value: true, message: t('error.inputErrors.requiredField')}
                                                               }}
                                                />
                                            </div>
                                            <div className="col-12 md:col-8 mb-6 md:mb-0 flex align-items-center" key={"did" + did.id}>
                                                <FormTextInput className="w-full"
                                                               placeHolder={t('screens.trustedIssuerListForm.fields.did.did.placeHolder')}
                                                               value={dids[index].did}
                                                               formHook={formHook}
                                                               controlName={"trustedIssuerListDids[" + index + "].did"}
                                                               rules={{
                                                                   required: {value: true, message: t('error.inputErrors.requiredField')}
                                                               }}
                                                />
                                                <Button icon="pi pi-times" className="ml-2"
                                                        data-pr-tooltip={t('screens.trustedIssuerListForm.fields.did.deleteIcon.tooltip')}
                                                        data-pr-position="left"
                                                        style={{color: '#777777'}}
                                                        rounded text aria-label={t('screens.trustedIssuerListForm.fields.did.deleteIcon.tooltip')}
                                                        onClick={(event) => removeDid(event, index)}></Button>

                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                            <Button className="mt-4" type="button" onClick={addNewDid}>{t('screens.trustedIssuerListForm.addDidButtonLabel')}</Button>
                        </div>
                    </div>
                </OCard>
                <Button className="mt-4 md:mt-6" type="submit">{t('generic.save')}</Button>
            </form>
        </>
    );
};
