import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../state';
import { FormTextInputWithLabel, InfoCard, OCard, OFabContainer } from '../../molecules';
import { useTranslation } from 'react-i18next';
import { Receive } from '../../atoms/icons/Receive';
import { presentationDefinitionFormSelector, setPresentationDefinitionForm } from '../../../state/slices/presentationdefinitionform';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { PresentationDefinition, savePresentationDefinition } from '../../../state/slices/presentationdefinition';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from 'react-hook-form';

export const PresentationDefinitionFormConfigureCredentials: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>();
    const {keycloak} = useKeycloak();
    let navigation = useNavigate();
    let presentationDefinitionForm = useSelector(presentationDefinitionFormSelector);
    const [pdContent, setPdContent] = useState<string>(presentationDefinitionForm.form?.pdDocument ? JSON.stringify(presentationDefinitionForm.form?.pdDocument, null, 2) : '');
    const [parseError, setParseError] = useState<string>();

    useEffect(() => {
        if (presentationDefinitionForm.form?.externalKey !== undefined) {
            handleFormUpdate("externalKey", presentationDefinitionForm.form?.externalKey.replace(" ", "_").replace(/[^a-zA-Z0-9_-]/g, ''));
        }
    }, [presentationDefinitionForm.form?.externalKey]);


    const formHook = useForm<PresentationDefinition>({
        defaultValues: {}
    });
    const {handleSubmit, setValue, getValues, reset, register, formState: {errors}} = formHook;

    useEffect(() => {
        if ((presentationDefinitionForm !== undefined) && (presentationDefinitionForm.form !== undefined)) {
            reset(presentationDefinitionForm.form);
        }
    }, [presentationDefinitionForm.form]);


    function handleFormUpdate(attributeName: string, value: string | undefined) {
        dispatch(setPresentationDefinitionForm(Object.assign({}, presentationDefinitionForm.form, {[attributeName]: value})));
    }

    function handleSetPdContent(value: string) {
        try {
            setParseError('');
            setPdContent(value);
            JSON.parse(value);
        } catch (error) {
            if (error instanceof Error) {
                setParseError(error.message);
            }
        }
    }

    function handleEditPdDocument() {
        const pdDoc = getValues("pdDocument");
        pdDoc.purpose = getValues("purpose");
        setPdContent(JSON.stringify(pdDoc, null, 2));
        setIsDialogVisible(true);
    }

    function handleUpdatePdDocument() {
        const jsonDoc = JSON.parse(pdContent)
        if (jsonDoc.purpose !== undefined) {
            setValue("purpose", jsonDoc.purpose);
        }
        setValue("pdDocument", jsonDoc);
        setValue("updatedContent", pdContent);
        setIsDialogVisible(false);
    }

    function onCancel() {
        navigation('/presentationDefinition');
    }

    return (
        <div className="pb-8 mb-8">
            <form onSubmit={handleSubmit((data) => {
                dispatch(savePresentationDefinition({jwtToken: keycloak.token!, presentationDefinition: data})).then((response) => {
                    if (response.type.includes('fulfilled')) {
                        setTimeout(() => { // Use timeout the give time to update the redux store.
                            navigation('/presentationDefinition/test/' + response.payload.id);
                        }, 250);
                    }
                });
            })}
            >
                <InfoCard className="mt-4" icon={<Receive height="30" width="30"/>}
                          title={t('screens.presentationDefinitionFormConfigureCredentials.intro.title')}
                          description={t('screens.presentationDefinitionFormConfigureCredentials.intro.description')}/>
                <OCard className="mt-4" title={t('screens.presentationDefinitionFormConfigureCredentials.saveConfigurationTitle')}>
                    <FormTextInputWithLabel className="mb-3"
                                        label={t('screens.presentationDefinitionFormConfigureCredentials.attributes.name.label')}
                                        placeHolder={t('screens.presentationDefinitionFormConfigureCredentials.attributes.name.placeHolder')}
                                        formHook={formHook}
                                        controlName="name"
                                        rules={{
                                            required: {value: true, message: t('error.inputErrors.requiredField')}
                                        }}/>
                    <FormTextInputWithLabel className="mb-3"
                                        multiline={true}
                                        label={t('screens.presentationDefinitionFormConfigureCredentials.attributes.description.label')}
                                        placeHolder={t('screens.presentationDefinitionFormConfigureCredentials.attributes.description.placeHolder')}
                                        formHook={formHook}
                                        controlName="description"
                                        rules={{
                                        }}/>
                    <FormTextInputWithLabel className="mb-3"
                                        label={t('screens.presentationDefinitionFormConfigureCredentials.attributes.purpose.label')}
                                        placeHolder={t('screens.presentationDefinitionFormConfigureCredentials.attributes.purpose.placeHolder')}
                                        formHook={formHook}
                                        controlName="purpose"
                                        rules={{
                                            required: {value: true, message: t('error.inputErrors.requiredField')}
                                        }}/>

                </OCard>
                <OCard className="mt-4" title={t('screens.presentationDefinitionFormConfigureCredentials.technicalConfigurationTitle')}>
                    <FormTextInputWithLabel className="mb-3"
                                        label={t('screens.presentationDefinitionFormConfigureCredentials.attributes.externalKey.label')}
                                        placeHolder={t('screens.presentationDefinitionFormConfigureCredentials.attributes.externalKey.placeHolder')}
                                        formHook={formHook}
                                        controlName="externalKey"
                                        rules={{
                                            required: {value: true, message: t('error.inputErrors.requiredField')},
                                            pattern: {value: /^\S*$/i, message: t('error.inputErrors.noSpacesAllowed')}
                                        }}/>
                    <FormTextInputWithLabel className="mb-3"
                                        label={t('screens.presentationDefinitionFormConfigureCredentials.attributes.successRedirectUrl.label')}
                                        placeHolder={t('screens.presentationDefinitionFormConfigureCredentials.attributes.successRedirectUrl.placeHolder')}
                                        inputType="url"
                                        formHook={formHook}
                                        controlName="successRedirectUrl"
                                        rules={{
                                        }}/>

                    <FormTextInputWithLabel className="mb-3"
                                        label={t('screens.presentationDefinitionFormConfigureCredentials.attributes.errorRedirectUrl.label')}
                                        placeHolder={t('screens.presentationDefinitionFormConfigureCredentials.attributes.errorRedirectUrl.placeHolder')}
                                        inputType="url"
                                        formHook={formHook}
                                        controlName="errorRedirectUrl"
                                        rules={{
                                        }}/>

                    <FormTextInputWithLabel label={t('screens.presentationDefinitionFormConfigureCredentials.attributes.clientUrl.label')}
                                        placeHolder={t('screens.presentationDefinitionFormConfigureCredentials.attributes.clientUrl.placeHolder')}
                                        inputType="url"
                                        formHook={formHook}
                                        controlName="clientUrl"
                                        rules={{
                                            required: {value: true, message: t('error.inputErrors.requiredField')}
                                        }}/>
                    <input type="hidden" {...register("pdDocument")} />
                    <input type="hidden" {...register("updatedContent")} />
                </OCard>
                <Dialog header="Presentation definition document" visible={isDialogVisible} style={{width: '1000px'}} onHide={() => setIsDialogVisible(false)}>
                    <p style={{color: '#ff0000'}}>{parseError}</p>
                    <p className="m-0">
                        <InputTextarea rows={25}
                                       cols={100}
                                       value={pdContent}
                                       onChange={event => handleSetPdContent(event.target.value)}
                        >
                        </InputTextarea>
                    </p>
                    <Button className="mt-4 mr-1" label="OK" disabled={parseError !== undefined && parseError?.length > 0} onClick={() => handleUpdatePdDocument()}/>
                    <Button className="mt-4" label="Cancel" severity="secondary" onClick={() => setIsDialogVisible(false)}/>
                </Dialog>
                {presentationDefinitionForm.form?.pdDocument ? <Button className="mt-4" label="Edit definition" icon="pi pi-external-link" onClick={(e) => {handleEditPdDocument(); e.preventDefault();}}/> : <></>}

                <OFabContainer className="w-full">
                    <Button label={t('generic.cancel')} severity="secondary" size="small" className="w-max" onClick={onCancel}/>
                    <Button label="Save configuration" size="small" className="w-max" type="submit"/>
                </OFabContainer>
            </form>
        </div>
    );
}
